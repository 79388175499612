.podium{
    display: flex;
    justify-content: space-around;
    align-items: center;    

    @include media-breakpoint-down(sm){
        flex-direction: column;
    }

    & > *{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
        position: relative;
        overflow: hidden;

        & > a{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            z-index: 20;
            width: 22rem;

            @include media-breakpoint-down(md){
                width: 20rem;
            }

            & button{
                width: 100%;
                padding: 1rem 0;
                border-radius: 0;
            }
        } 

        @include media-breakpoint-down(sm){            
            width: 70% !important;
            height: 40rem !important;
            margin-bottom: 3.5rem;
            padding: 3.5rem !important;
            padding-bottom: 1.6rem !important;
            
            &:nth-child(2){
                order: -1;
            }
        }

        @include media-breakpoint-down(xs){
            width: 70% !important;
            height: 36rem !important;
            margin-bottom: 3.5rem;
            padding: 2.5rem !important;
            padding-bottom: 1.3rem !important;

            &:nth-child(2){
                height: 45rem !important;
            }
        }

        @media(max-width: 470px){
            width: 100% !important;
            height: 40rem !important;
        }
    }

    & > :first-child,& > :last-child{
        width: 25.5rem;
        height: 46rem;
        padding: 1.7rem;
        padding-top: 3.5rem;            

        @include media-breakpoint-down(md){
            width: 20rem;
            padding: 1.1rem;  
            padding-top: 3.5rem;    
        }

        & > a{
            bottom: 1.7rem;

            @include media-breakpoint-down(md){
                width: 18rem;
            }
        }

        & img:first-child{
            width: 9rem;            

            @include media-breakpoint-down(md){
                width: 6rem;  
            }
        }
    }

    & > :nth-child(2){
        width: 29rem;
        height: 52rem;
        padding: 3rem 3.5rem 1.6rem 3.5rem;            

        @include media-breakpoint-down(md){
            width: 23rem;
            padding: 3rem 1.5rem 1.6rem 1.5rem;  
            z-index: 100; 
        }

        & > a{
            bottom: 1.6rem;
        }

        & img:first-child{
            width: 10rem;            

            @include media-breakpoint-down(md){
                width: 9rem;  
            }
        }
    }
}