.cars-list{
    &-container{
        margin-bottom: 10rem;
    }

    &-pagination{
        align-self: flex-end;
        width: 44.5rem;
        display: flex;
        justify-content: flex-end;

        @include media-breakpoint-down(sm){
            width: 100%;
            justify-content: space-between;
        }

        & .fake-element{
            width: 0;

            @include media-breakpoint-down(sm){
                width: 4rem;
            }

            @include media-breakpoint-down(xs){
                width: 3.2rem;
            }
        }

        &-container{
            width: 92rem;
            display: flex;
            justify-content: flex-end;

            @include media-breakpoint-down(lg){
                width: 80rem;
            }
            
            @include media-breakpoint-down(md){
                width: 72rem;
            }
            
            @include media-breakpoint-down(sm){
                width: 34.6rem;

                & > *{
                    width: 100%;
                }
            }

            @include media-breakpoint-down(xs){
                width: 90%;
                max-width: 34.6rem;
            }
        }

        & .active .page-link{
            background-color: $accent-blue;
        }

        &-previous{
            margin-right: 7rem;
            
            @include media-breakpoint-down(sm){
                margin-right: 0;
            }
        }

        &-next{
            margin-left: 7rem;

            @include media-breakpoint-down(sm){
                margin-left: 0;
            }
        }

        &-arrow, & .page-item{
            width: 4rem;
            height: 4rem;
            font-size: 1.2rem;
            font-weight: normal;

            @include media-breakpoint-down(xs){
                width: 3.2rem;
                height: 3.2rem;
            }
            
            & a, & span{
                color: $grey-dark;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;

                &:hover{
                    background-color: $accent-blue;
                    color: $white;

                    & path{
                        fill: $white;
                    }
                }
            }
        }
    }

    display: flex;
    flex-direction: column;
    width: 92rem;

    @include media-breakpoint-down(lg){
        width: 80rem;
    }

    @include media-breakpoint-down(md){
        width: 72rem;
    }

    @include media-breakpoint-down(sm){
        width: 34.6rem;
    }

    @include media-breakpoint-down(xs){
        width: 90%;
        max-width: 34.6rem;
    }

    &-item{
        height: 25rem;
        width: 100%;
        background-color: $white;
        box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.15);

        display: flex;
        margin-bottom: 4rem;

        @include media-breakpoint-down(md){
            height: 22rem;
            margin-bottom: 3rem;
        }

        @include media-breakpoint-down(sm){
            flex-direction: column;
            height: auto;
        }

        &-img{
            &-container{
                flex: 0 0 38rem;
                height: 100%;
                position: relative;

                @include media-breakpoint-down(md){
                    flex: 0 0 30rem;
                }

                @include media-breakpoint-down(sm){
                    flex: 0 0 27rem;
                }
            }

            width: 100%;
            height: 100%;
            object-fit: cover;

            @include media-breakpoint-down(sm){
                height: 27rem;
            }

            &-label{
                position: absolute;
                top: 1rem;
                left: 0;
                padding: .4rem 1.5rem .4rem 3rem;
                font-size: 1.2rem;
                font-weight: bold;
                text-transform: uppercase;

                &-blue{
                    background-color: $accent-blue;
                    color: $white;
                }

                &-red{
                    background-color: $accent-red;
                    color: $white;
                }

                &-white{
                    background-color: $white;
                    color: $accent-blue;
                }
            }
        }

        &-info{
            &-container{
                flex-grow: 1;
                height: 100%;
                display: flex;
                flex-direction: column;
            }
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;

            padding: 1.5rem 2.9rem;

            @include media-breakpoint-down(lg){
                padding: 1.5rem 2rem;
            }

            &-title{
                color: $accent-blue;
                font-size: 2.2rem;
                font-weight: bold;
                max-width: 40rem;

                &:hover{
                    text-decoration: underline;
                }
                    
                @include media-breakpoint-down(sm){
                    font-size: 1.6rem;
                    margin-bottom: 2rem;
                }
            }

            &-desc{
                font-weight: 600;
                &-year, &-mileage{
                    margin-right: 2rem;

                    @include media-breakpoint-down(lg){
                        margin-right: 1.5rem;
                    }
                }

                &-leasing{
                    color: $accent-blue;
                    font-weight: bold;
                    margin-top: 1.3rem;

                    @include media-breakpoint-down(lg){
                        margin-top: .9rem;
                    }
                }
            }

            &-actions{
                width: 100%;
                height: 7.5rem;

                @include media-breakpoint-down(lg){
                    height: 6.5rem;
                    font-size: 1.4rem;

                    & .svg-conv.svg-conv-blue{
                        width: 1.4rem;
                    }
                }

                display: flex;
                font-weight: bold;
                cursor: pointer;

                @include media-breakpoint-down(sm){
                    flex-direction: column;
                    height: auto;
                }

                &-1{
                    display: flex;
                    color: $accent-blue;
                    flex: 1 0 40%;

                    border-top: 1px solid rgba(0, 0, 0, 0.25);

                    & .svg-conv {
                        width: 2rem;
                        height: 2rem;
                        margin-right: .5rem;
                    }

                    & > *{
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 2.3rem .8rem;
                    
                        @include media-breakpoint-down(lg){
                            padding: 2.3rem 0rem;
                        }
                    
                        @include media-breakpoint-down(sm){
                            padding: 1.4rem;
                        }

                        &:not(:last-child){
                            border-right: 1px solid rgba(0, 0, 0, 0.25);
                            text-align: center;
                            line-height: 110%;

                            & svg{                    
                                @include media-breakpoint-up(md){
                                    position: relative;
                                    left: 1rem;
                                }
                            }
                        }
                    }
                }

                &-order{
                    background: $accent-blue;
                    color: $white;
                    flex: 1 0 25rem;
                    display: flex;
                    align-items: center;
                    padding: 0 2rem;
                    font-size: 2rem;
                    font-weight: bold;
                    border-radius: 0;
                    border: 2px solid $accent-blue;


                    @include media-breakpoint-down(lg){
                        flex: 1 0 20rem;
                        font-size: 1.8rem;
                    }

                    @include media-breakpoint-down(sm){
                        flex: 0 0 7.3rem;
                        padding: 0 6rem;
                    }

                    @media(max-width: 350px){
                        padding: 0 3rem;
                    }

                    & div{
                        flex: 1 0 auto;
                        text-align: right;
                    }

                    &:hover{
                        color: $white;
                    }

                    & .svg-conv:hover path{
                        fill: $white;
                    }
                }
            }
        }
    }

    &-grid{
        flex-direction: row;
        flex-wrap: wrap;
        width: 110rem;

        @include media-breakpoint-down(lg){
            width: 72rem;
        }

        @include media-breakpoint-down(sm){
            width: 34.6rem;
        }
    
        @include media-breakpoint-down(xs){
            width: 90%;
            max-width: 34.6rem;
        }
    
        & .cars-list-item{
            width: 26rem;
            height: auto;
            flex-direction: column;
            margin-bottom: 4.5rem;
            margin-right: 2rem;

            &:nth-child(4n){
                margin-right: 0;
            }

            @include media-breakpoint-down(lg){
                width: 23rem;
                margin-right: 1.5rem;
                margin-bottom: 3rem;

                &:nth-child(4n){
                    margin-right: 1.5rem;
                }

                &:nth-child(3n){
                    margin-right: 0;
                }
            }
    
            @include media-breakpoint-down(sm){
                width: 100%;
                //flex-direction: column;
                height: auto;
            }

            &-img{
                &-container{
                    width: 100%;
                    max-height: 20rem;
                    position: relative;
    
                    @include media-breakpoint-down(sm){
                        max-height: 27rem;
                        flex: 0 0 27rem;
                    }
                }
            }

            &-info{    
                padding: .9rem;
                padding-bottom: 2rem;
    
                &-title{
                    font-size: 1.6rem;
                    max-width: 100%;
                    margin-bottom: .5rem;
                }
    
                &-desc{
                    &-year{
                        margin-right: 1.3rem;
                    }
    
                    &-leasing{
                        display: none;
                    }
                }
    
                &-actions{
                    flex-direction: column;
                    height: auto;
    
                    &-1{    
                        & .svg-conv {
                            width: 1.4rem;
                            height: 1.4rem;
                            margin-right: .5rem;
                        }
    
                        & > *{
                            padding: 1rem;
                        }
                    }
    
                    &-order{
                        width: 100%;
                        flex: 0 0 5.5rem;
                        font-size: 1.6rem;
                        display: flex;
                        justify-content: center;

                        & div{
                            flex: 0 0 auto;
                            width: max-content;
                            text-align: center;
                            margin-left: 1.5rem;
                        }
                    }
                }
            }
        }
    }
}