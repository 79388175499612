.info-box{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 35rem;
    background-color: $accent-blue;
    padding: 2.8rem;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    line-height: 160%;
    color: $white;
    font-weight: 300;
    z-index: 1000;
    transition: opacity .25s, background .25s;
    opacity: 0;
    pointer-events: none;

    @include media-breakpoint-down(md){
        width: 40rem;
        left: initial;
        right: 0;
        min-height: 25rem;
        border-radius: 1.5rem;
    }

    @include media-breakpoint-down(xs){
        width: 90%;
        max-width: 30rem;
        left: 50%;
        transform: translateX(-50%);
    }

    &-icon{
        position: absolute;
        top: 1.2rem;
        left: 1.2rem;
        width: 3.8rem;

        @include media-breakpoint-down(xs){
            width: 2.7rem;
        }
    }

    &-close{
        position: absolute;
        top: 1.2rem;
        right: 1.2rem;
        width: 3rem;
        cursor: pointer;

        @include media-breakpoint-down(xs){
            width: 2.2rem;
        }
    }

    &-invalid{
        background-color: $accent-warning;
    }
}