/*CONTAINER*/
// @media (min-width: 1500px){
//     .container{max-width: 1440px;}
// }
// @media (min-width: 1800px){
//     .container{max-width: 1600px;}
// }

@include media-breakpoint-down(sm){
    .container{
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}

/*btn*/
.btn, .btn:focus{
    box-shadow: none;
    transition: all .25s;
}

.shadow{
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15) !important;
}