.pliki-umow {
    li {
        clear: left;
        margin-bottom: 20px;
        a {
            img {
                width: 30px;
                height: 30px;
            }
        }
    }
}