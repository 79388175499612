.page-404{
    &-container{
        margin-top: 5rem;
        padding: 10rem 0 6rem 0;
    
        & a{
            color: $white;
            background-color: $accent-blue;
            border-radius: 3rem;
            padding: 1rem 2rem;
            display: flex;
            align-items: center;
            transition: all .25s;
            border: 1px solid $accent-blue;
    
            &:hover{
                background-color: $white;
                color: $accent-blue;
    
                & path{
                    fill: $accent-blue;
                }
            }
        }

        & > img{
            @include media-breakpoint-down(sm){
                width: 95%;
                max-width: 54rem;
            }

            @include media-breakpoint-down(xs){
                width: 95%;
                max-width: 34.6rem;
                max-height: 7.3rem;
            }
        }
    }
}