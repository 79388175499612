.offers{
    
    &-left{
        height: 43rem;
        flex: 1 0 35rem;
        margin-right: 1.5rem;
        background: url('../img/offers-left.png');

        @include media-breakpoint-down(lg){
            height: 43rem;
            flex: 1 0 30%;
        }

        @include media-breakpoint-down(md){
            height: 33rem;
        }

        @include media-breakpoint-down(sm){
            width: 100%;
            flex: 1 0 15.5rem;
            margin-right: 0;
            margin-bottom: 1.5rem;
            background: url('../img/offers-left-mobile.png');
            background-size: 110% !important;
            background-position: 50% 80% !important;
        }

        @include media-breakpoint-down(xs){
            margin-bottom: 1rem;
            background-size: 125% !important;
        }

    }

    &-container{
        width: 110rem;
        margin-top: 10rem;

        @include media-breakpoint-down(lg){
            margin-top: 5rem;
            width: 96rem;
        }

        @include media-breakpoint-down(md){
            width: 72rem;
        }

        @include media-breakpoint-down(sm){
            width: 54rem;
            flex-direction: column;
        }

        @include media-breakpoint-down(md){
            width: 90%;
        }
    }

    &-right{
        flex-wrap: wrap;
        flex: 1 0 66%;
        align-content: space-between;

        &-parts{
            background: url('../img/offers-parts.png');
        }

        &-finance{
            background: url('../img/offers-finance.png');
        }

        &-sell{
            background: url('../img/offers-sell.png');
        }

        &-rent{
            background: url('../img/offers-rent.png');
        }

        & > *{
            flex: 1 0 35rem;
            height: 20.7rem;
            text-align: center;

            &:nth-child(2n+1){
                margin-right: 1.5rem;
            }

            @include media-breakpoint-down(lg){
                height: 48.3%;
                flex: 1 0 45%;
            }

            @include media-breakpoint-down(sm){
                margin-bottom: 1.5rem;
                height: 15.5rem;
            }

            @include media-breakpoint-down(xs){
                margin-bottom: 1rem;
            }
        }
    }

    &-left, &-right > *{
        color: #fff;
        transition: all .25s ease-out;
        background-size: 104%;
        background-position: 50% 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        font-size: 2.4rem;
        text-transform: uppercase;
        font-weight: 800;
        cursor: pointer;

        &:hover{
            background-size: 130%;
            color: #fff;
        }

        @include media-breakpoint-down(lg){
            background-size: 125%;

            &:hover{
                background-size: 140%;
            }
        }

        @include media-breakpoint-down(md){
            background-size: 160%;
            font-size: 2rem;

            &:hover{
                background-size: 180%;
            }
        }

        @include media-breakpoint-down(xs){
            background-size: 230%;
            font-size: 1.6rem;
        }

        @media(max-width: 320px){
            font-size: 1.5rem;
        }
    }
}