.about-us-section{
    @include media-breakpoint-down(md){
        display: flex;
        flex-direction: column-reverse;
    }
    & > :nth-child(2){
        width: min-content;

        @include media-breakpoint-down(md){
            display: flex;
            width: 100%;
        }

        @include media-breakpoint-down(xs){
            flex-direction: column;
        }
    }

    & img{
        width: 43rem;

        @include media-breakpoint-down(md){
            width: 50%;
            height: 29rem;
            max-width: 48%;
            &:first-child{
                margin-right: 4%;
            }
        }

        @include media-breakpoint-down(sm){
            height: 23rem;
        }
        
        @include media-breakpoint-down(xs){
            min-width: 100%;
            height: auto;
            margin-right: 0;

            &:nth-child(2){
                display: none;
            }
        }
    }

    & > :first-child{
        box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15);

        @include media-breakpoint-down(md){
            margin-right: 0 !important;
        }
    }
}

.about-us-carousel{
    width: 100%;
    overflow: hidden;

    & .carousel{
        width: 100%;
        height: 34rem;

        &-inner{
            height: 100%;
        }

        &-item{
            height: 100%;
            text-align: center;
            background: linear-gradient(rgba(0,0,0,0.45), rgba(0,0,0,0.45)), url('../img/wynajem-header.png');
            background-size: cover;
            background-position: 50% 50%;
            color: $white;

            padding: 9rem 0;

            & h3{
                font-weight: bold;
                line-height: 190%;
                margin-bottom: 3rem;

                @include media-breakpoint-down(xs){
                    margin-bottom: 1rem;
                }
            }

            & p{
                margin: 0 auto;
                max-width: 50rem;
                font-size: 1.2rem;
                line-height: 190%;

                @include media-breakpoint-down(sm){
                    max-width: 40rem;
                }
            }
        }

        &-indicators li{
            width: 5rem;
            height: .6rem;
            background-color: $white;
            opacity: 1;

            @include media-breakpoint-down(xs){
                width: 4rem;
                height: .5rem;
            }

            &.active{
                background-color: $accent-blue;
            }
        }

        &-control-prev{
            left: 20%;
            @include media-breakpoint-down(lg){
                left: 10%;
            }
            @include media-breakpoint-down(md){
                left: 5%;
            }
            @include media-breakpoint-down(sm){
                left: 0;
            }

            &-icon{
                &::after{
                    background-image: url('../img/icons/arrow-simple-left.svg');
                }
            }
        }
        
        &-control-next{
            right: 20%;
            @include media-breakpoint-down(lg){
                right: 10%;
            }
            @include media-breakpoint-down(md){
                right: 5%;
            }
            @include media-breakpoint-down(sm){
                right: 0;
            }

            &-icon{
                &::after{
                    background-image: url('../img/icons/arrow-simple-right.svg');
                }
            }
        }

        &-control-prev, &-control-next{
            @include media-breakpoint-down(xs){
                top: initial;
                bottom: 1rem;
            }
            &-icon{
                padding: 2rem;
                background-color: $white;
                background-image: none;
                position: relative;

                @include media-breakpoint-down(xs){
                    padding: 1.5rem;
                }

                &::after{
                    content: "";
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 2rem;
                    height: 2rem;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                }
            }
        }
    }
}