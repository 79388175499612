.contact{
    &-item{
        height: auto;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 3rem;

        &.mb-n5{
            @include media-breakpoint-down(md){
                margin-bottom: -1rem !important;
            }

            @include media-breakpoint-down(sm){
                margin-bottom: 6.5rem !important;
            }
        }

        @include media-breakpoint-down(lg){
            padding-right: 0rem;
        }

        @include media-breakpoint-down(sm){
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
            flex-direction: column;
            width: 100%;
            max-width: 34.6rem;

            &:nth-child(even){
                flex-direction: column-reverse;

                & .shadow{
                    flex-direction: column-reverse;
                }
            }
        }

        &-container{
            @include media-breakpoint-down(md){
                width: 100%;
                max-width: 100%;
            }            
        }

        &.contact-item-margin:not(:last-child){
            margin-bottom: 6.5rem;
        }

        & > .shadow{
            background-color: $white;
            align-items: center;

            @include media-breakpoint-down(sm){
                flex-direction: column;
                width: 100%;
            }

            & > div{
                padding: 5rem 6rem 5rem 7.5rem;

                @include media-breakpoint-down(lg){
                    padding: 0 5rem;
                    display: flex;
                    align-items: center;
                }

                @include media-breakpoint-down(md){
                    padding: 0 4rem;
                }

                @include media-breakpoint-down(sm){
                    padding: 0;

                    & > .d-flex{
                        text-align: center !important;
                    }

                    & .mb-5{
                        margin-bottom: 1rem !important;
                    }
                }
            }

            @include media-breakpoint-down(lg){
                &:nth-child(odd){
                    margin-right: 3rem;
                }

                &:nth-child(even){
                    margin-left: 3rem;
                }
            }

            @include media-breakpoint-down(md){
                &:nth-child(odd){
                    margin-right: 2rem;
                }

                &:nth-child(even){
                    margin-left: 2rem;
                }
            }

            @include media-breakpoint-down(sm){
                box-shadow: none !important;
                margin: 0 !important;
            }
        }

        & img{
            // width: 26rem;
            height: 23rem;

            @include media-breakpoint-down(lg){
                width: 23rem;
                height: 21rem;
            }

            @include media-breakpoint-down(md){
                width: 20rem;
                height: auto;
            }

            @include media-breakpoint-down(sm){
                width: 100%;
                margin-bottom: 1rem;
            }
        }

        & button{
            height: 4rem;
            padding: 0 4rem;
            background-color: transparent;
            color: $accent-blue;
            border-color: $accent-blue;

            @include media-breakpoint-down(lg){
                padding: 0 2rem;
            }

            @include media-breakpoint-down(md){
                font-size: 1.2rem;
                padding: 0 1rem;
            }

            @include media-breakpoint-down(sm){
                margin-top: 1rem;
                margin-bottom: 1rem;
                padding: 0 4rem;

                svg{
                    display: none;
                }
            }

            &:hover{
                color: $white;
                background-color: $accent-blue;

                & path{
                    fill: $white;
                }
            }
        }
    }
}