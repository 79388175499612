.header{
    width: 100%;
    height: 63rem;
    background-image: url('../img/home-baner.png');
    background-size: cover;
    background-position: 50% 50%;
    padding-top: 12rem;
    position: relative;

    @media(max-width: 1400px){
        padding-top: 6rem;
        height: 48rem;
    }

    @include media-breakpoint-down(md){
        padding-top: 4rem;
        height: 45rem;
        background: linear-gradient(#00000060, #00000060), url('../img/home-baner.png');
        background-size: cover;
        background-position: 50% 50%;
    }

    @include media-breakpoint-down(sm){
        height: 42rem;
        & .container{
            display: flex;
            justify-content: center;
        }
    }

    @include media-breakpoint-down(xs){
        height: 40rem;
    }

    &-items{
        color: $white;
        max-width: 26rem;

        @include media-breakpoint-down(sm){
            align-items: center !important;
            text-align: center;
        }

        @include media-breakpoint-down(xs){
            max-width: 65%;
        }

        @media(max-width: 335px){
            max-width: 80%;
        }

        & > *:not(:last-child){
            margin-bottom: 3rem;

            @media(max-width: 1400px){
                margin-bottom: 2rem;
            }

            @include media-breakpoint-down(md){
                margin-bottom: 2rem;
            }
        }

        & .header-desc{
            line-height: 2.2rem;

            @include media-breakpoint-down(xs){
                font-size: 1.4rem;
            }
        }
    }


    &-pages{
        position: relative;
        @include media-breakpoint-down(lg){
            //height: 50rem;
        }

        @include media-breakpoint-down(md){
            height: 43rem;
        }

        @include media-breakpoint-down(sm){
            height: 38rem;
        }

        @include media-breakpoint-down(xs){
            &:not(.header-sprzedaj){
                margin-top: 9.2rem;
                height: 20rem;
                &:not(.header-finansowanie){
                    margin-bottom: 9rem;
                }            
            }
        }

        & .header-items{
            max-width: 56rem;
            margin-bottom: 3rem;

            @include media-breakpoint-down(sm){
                margin-bottom: 1rem;
            }

            & .heading-1{
                font-size: 6rem;
                margin-bottom: 1rem;

                @include media-breakpoint-down(md){
                    font-size: 5rem;
                }

                @include media-breakpoint-down(sm){
                    font-size: 4rem;
                }

                @include media-breakpoint-down(xs){
                    font-size: 3.4rem;
                }
            }

            & .header-desc{
                line-height: 2.2rem;

                @include media-breakpoint-down(md){
                    font-size: 1.4rem;
                    line-height: 1.8rem;
                }
            }
        }

        
        &:not(.header-sprzedaj){
            & .header-items{
                @include media-breakpoint-down(xs){
                    position: absolute;
                    top: 77%;
                }
            }

            & .header-desc{
                @include media-breakpoint-down(xs){
                    color: $black;
                    height: 20rem;
                }
            }
        }
    }

    &-wynajem{
        background-image: url('../img/wynajem-header.png');
    }

    &-o-firmie{
        background-image: url('../img/o-firmie-header.png');
    }

    &-sprzedaj{
        background: linear-gradient(#00000055, #00000055), url('../img/sprzedaj-header.png');
        background-size: cover;
        background-position: 50% 50%;

        & .header-items{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            max-width: 100%;
            font-weight: 800;
            margin-bottom: 8rem;

            @include media-breakpoint-down(lg){
                margin-bottom: 6rem;

                @include media-breakpoint-down(md){
                    margin-bottom: 4rem;

                    @include media-breakpoint-down(xs){
                        margin-bottom: 3rem;
                    }
                }
            }

            & .heading-1{
                @include media-breakpoint-down(xs){
                    line-height: 110%;
                    margin-bottom: .4rem;
                }
            }

            & .header-desc{
                font-size: 38px;
                margin-top: 1rem;
                text-transform: uppercase;
                line-height: 110%;

                @include media-breakpoint-down(sm){
                    font-size: 2.8rem;
                    margin-top: 0;

                    @include media-breakpoint-down(xs){
                        font-size: 2.4rem;
                    }
                }
            }
        }
    }

    &-finansowanie{
        background-image: url('../img/finance-header.png');
    }
}