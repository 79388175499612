/*=============================================
=            Fonts, Variables, etc.           =
=============================================*/
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
@import url("https://use.typekit.net/ead4xiq.css");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap");
@import url("https://unpkg.com/ionicons@4.5.10-0/dist/css/ionicons.min.css");
/*=====  End of Fonts, Variables, etc. ======*/
/*=============================================
=                  Libraries                  =
=============================================*/
/*! Bootstrap v4.3.1 (https://getbootstrap.com/) Copyright 2011-2019 The Bootstrap Authors Copyright 2011-2019 Twitter, Inc. Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE) */
:root { --blue: #007bff; --indigo: #6610f2; --purple: #6f42c1; --pink: #e83e8c; --red: #dc3545; --orange: #fd7e14; --yellow: #ffc107; --green: #28a745; --teal: #20c997; --cyan: #17a2b8; --white: #fff; --gray: #6c757d; --gray-dark: #343a40; --primary: #007bff; --secondary: #6c757d; --success: #28a745; --info: #17a2b8; --warning: #ffc107; --danger: #dc3545; --light: #f8f9fa; --dark: #343a40; --breakpoint-xs: 0; --breakpoint-sm: 576px; --breakpoint-md: 768px; --breakpoint-lg: 992px; --breakpoint-xl: 1200px; --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*, *::before, *::after { box-sizing: border-box; }

html { font-family: sans-serif; line-height: 1.15; -webkit-text-size-adjust: 100%; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section { display: block; }

body { margin: 0; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #212529; text-align: left; background-color: #fff; }

[tabindex="-1"]:focus { outline: 0 !important; }

hr { box-sizing: content-box; height: 0; overflow: visible; }

h1, h2, h3, h4, h5, h6 { margin-top: 0; margin-bottom: 0.5rem; }

p { margin-top: 0; margin-bottom: 1rem; }

abbr[title], abbr[data-original-title] { text-decoration: underline; text-decoration: underline dotted; cursor: help; border-bottom: 0; text-decoration-skip-ink: none; }

address { margin-bottom: 1rem; font-style: normal; line-height: inherit; }

ol, ul, dl { margin-top: 0; margin-bottom: 1rem; }

ol ol, ul ul, ol ul, ul ol { margin-bottom: 0; }

dt { font-weight: 700; }

dd { margin-bottom: .5rem; margin-left: 0; }

blockquote { margin: 0 0 1rem; }

b, strong { font-weight: bolder; }

small { font-size: 80%; }

sub, sup { position: relative; font-size: 75%; line-height: 0; vertical-align: baseline; }

sub { bottom: -.25em; }

sup { top: -.5em; }

a { color: #007bff; text-decoration: none; background-color: transparent; }

a:hover { color: #0056b3; text-decoration: underline; }

a:not([href]):not([tabindex]) { color: inherit; text-decoration: none; }

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus { color: inherit; text-decoration: none; }

a:not([href]):not([tabindex]):focus { outline: 0; }

pre, code, kbd, samp { font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; font-size: 1em; }

pre { margin-top: 0; margin-bottom: 1rem; overflow: auto; }

figure { margin: 0 0 1rem; }

img { vertical-align: middle; border-style: none; }

svg { overflow: hidden; vertical-align: middle; }

table { border-collapse: collapse; }

caption { padding-top: 0.75rem; padding-bottom: 0.75rem; color: #6c757d; text-align: left; caption-side: bottom; }

th { text-align: inherit; }

label { display: inline-block; margin-bottom: 0.5rem; }

button { border-radius: 0; }

button:focus { outline: 1px dotted; outline: 5px auto -webkit-focus-ring-color; }

input, button, select, optgroup, textarea { margin: 0; font-family: inherit; font-size: inherit; line-height: inherit; }

button, input { overflow: visible; }

button, select { text-transform: none; }

select { word-wrap: normal; }

button, [type="button"], [type="reset"], [type="submit"] { -webkit-appearance: button; }

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) { cursor: pointer; }

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner { padding: 0; border-style: none; }

input[type="radio"], input[type="checkbox"] { box-sizing: border-box; padding: 0; }

input[type="date"], input[type="time"], input[type="datetime-local"], input[type="month"] { -webkit-appearance: listbox; }

textarea { overflow: auto; resize: vertical; }

fieldset { min-width: 0; padding: 0; margin: 0; border: 0; }

legend { display: block; width: 100%; max-width: 100%; padding: 0; margin-bottom: .5rem; font-size: 1.5rem; line-height: inherit; color: inherit; white-space: normal; }

progress { vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button { height: auto; }

[type="search"] { outline-offset: -2px; -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }

::-webkit-file-upload-button { font: inherit; -webkit-appearance: button; }

output { display: inline-block; }

summary { display: list-item; cursor: pointer; }

template { display: none; }

[hidden] { display: none !important; }

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 { margin-bottom: 0.5rem; font-weight: 500; line-height: 1.2; }

h1, .h1 { font-size: 2.5rem; }

h2, .h2 { font-size: 2rem; }

h3, .h3 { font-size: 1.75rem; }

h4, .h4 { font-size: 1.5rem; }

h5, .h5 { font-size: 1.25rem; }

h6, .h6 { font-size: 1rem; }

.lead { font-size: 1.25rem; font-weight: 300; }

.display-1 { font-size: 6rem; font-weight: 300; line-height: 1.2; }

.display-2 { font-size: 5.5rem; font-weight: 300; line-height: 1.2; }

.display-3 { font-size: 4.5rem; font-weight: 300; line-height: 1.2; }

.display-4 { font-size: 3.5rem; font-weight: 300; line-height: 1.2; }

hr { margin-top: 1rem; margin-bottom: 1rem; border: 0; border-top: 1px solid rgba(0, 0, 0, 0.1); }

small, .small { font-size: 80%; font-weight: 400; }

mark, .mark { padding: 0.2em; background-color: #fcf8e3; }

.list-unstyled { padding-left: 0; list-style: none; }

.list-inline { padding-left: 0; list-style: none; }

.list-inline-item { display: inline-block; }

.list-inline-item:not(:last-child) { margin-right: 0.5rem; }

.initialism { font-size: 90%; text-transform: uppercase; }

.blockquote { margin-bottom: 1rem; font-size: 1.25rem; }

.blockquote-footer { display: block; font-size: 80%; color: #6c757d; }

.blockquote-footer::before { content: "\2014\00A0"; }

.img-fluid { max-width: 100%; height: auto; }

.img-thumbnail { padding: 0.25rem; background-color: #fff; border: 1px solid #dee2e6; border-radius: 0.25rem; max-width: 100%; height: auto; }

.figure { display: inline-block; }

.figure-img { margin-bottom: 0.5rem; line-height: 1; }

.figure-caption { font-size: 90%; color: #6c757d; }

code { font-size: 87.5%; color: #e83e8c; word-break: break-word; }

a > code { color: inherit; }

kbd { padding: 0.2rem 0.4rem; font-size: 87.5%; color: #fff; background-color: #212529; border-radius: 0.2rem; }

kbd kbd { padding: 0; font-size: 100%; font-weight: 700; }

pre { display: block; font-size: 87.5%; color: #212529; }

pre code { font-size: inherit; color: inherit; word-break: normal; }

.pre-scrollable { max-height: 340px; overflow-y: scroll; }

.container { width: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }

@media (min-width: 576px) { .container { max-width: 540px; } }

@media (min-width: 768px) { .container { max-width: 720px; } }

@media (min-width: 992px) { .container { max-width: 960px; } }

@media (min-width: 1200px) { .container { max-width: 1140px; } }

.container-fluid { width: 100%; padding-right: 15px; padding-left: 15px; margin-right: auto; margin-left: auto; }

.row { display: flex; flex-wrap: wrap; margin-right: -15px; margin-left: -15px; }

.no-gutters { margin-right: 0; margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*="col-"] { padding-right: 0; padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto { position: relative; width: 100%; padding-right: 15px; padding-left: 15px; }

.col { flex-basis: 0; flex-grow: 1; max-width: 100%; }

.col-auto { flex: 0 0 auto; width: auto; max-width: 100%; }

.col-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }

.col-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }

.col-3 { flex: 0 0 25%; max-width: 25%; }

.col-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }

.col-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }

.col-6 { flex: 0 0 50%; max-width: 50%; }

.col-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }

.col-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }

.col-9 { flex: 0 0 75%; max-width: 75%; }

.col-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }

.col-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }

.col-12 { flex: 0 0 100%; max-width: 100%; }

.order-first { order: -1; }

.order-last { order: 13; }

.order-0 { order: 0; }

.order-1 { order: 1; }

.order-2 { order: 2; }

.order-3 { order: 3; }

.order-4 { order: 4; }

.order-5 { order: 5; }

.order-6 { order: 6; }

.order-7 { order: 7; }

.order-8 { order: 8; }

.order-9 { order: 9; }

.order-10 { order: 10; }

.order-11 { order: 11; }

.order-12 { order: 12; }

.offset-1 { margin-left: 8.33333%; }

.offset-2 { margin-left: 16.66667%; }

.offset-3 { margin-left: 25%; }

.offset-4 { margin-left: 33.33333%; }

.offset-5 { margin-left: 41.66667%; }

.offset-6 { margin-left: 50%; }

.offset-7 { margin-left: 58.33333%; }

.offset-8 { margin-left: 66.66667%; }

.offset-9 { margin-left: 75%; }

.offset-10 { margin-left: 83.33333%; }

.offset-11 { margin-left: 91.66667%; }

@media (min-width: 576px) { .col-sm { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-sm-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-sm-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-sm-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-sm-3 { flex: 0 0 25%; max-width: 25%; }
  .col-sm-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-sm-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-sm-6 { flex: 0 0 50%; max-width: 50%; }
  .col-sm-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-sm-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-sm-9 { flex: 0 0 75%; max-width: 75%; }
  .col-sm-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-sm-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-sm-12 { flex: 0 0 100%; max-width: 100%; }
  .order-sm-first { order: -1; }
  .order-sm-last { order: 13; }
  .order-sm-0 { order: 0; }
  .order-sm-1 { order: 1; }
  .order-sm-2 { order: 2; }
  .order-sm-3 { order: 3; }
  .order-sm-4 { order: 4; }
  .order-sm-5 { order: 5; }
  .order-sm-6 { order: 6; }
  .order-sm-7 { order: 7; }
  .order-sm-8 { order: 8; }
  .order-sm-9 { order: 9; }
  .order-sm-10 { order: 10; }
  .order-sm-11 { order: 11; }
  .order-sm-12 { order: 12; }
  .offset-sm-0 { margin-left: 0; }
  .offset-sm-1 { margin-left: 8.33333%; }
  .offset-sm-2 { margin-left: 16.66667%; }
  .offset-sm-3 { margin-left: 25%; }
  .offset-sm-4 { margin-left: 33.33333%; }
  .offset-sm-5 { margin-left: 41.66667%; }
  .offset-sm-6 { margin-left: 50%; }
  .offset-sm-7 { margin-left: 58.33333%; }
  .offset-sm-8 { margin-left: 66.66667%; }
  .offset-sm-9 { margin-left: 75%; }
  .offset-sm-10 { margin-left: 83.33333%; }
  .offset-sm-11 { margin-left: 91.66667%; } }

@media (min-width: 768px) { .col-md { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-md-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-md-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-md-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-md-3 { flex: 0 0 25%; max-width: 25%; }
  .col-md-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-md-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-md-6 { flex: 0 0 50%; max-width: 50%; }
  .col-md-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-md-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-md-9 { flex: 0 0 75%; max-width: 75%; }
  .col-md-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-md-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-md-12 { flex: 0 0 100%; max-width: 100%; }
  .order-md-first { order: -1; }
  .order-md-last { order: 13; }
  .order-md-0 { order: 0; }
  .order-md-1 { order: 1; }
  .order-md-2 { order: 2; }
  .order-md-3 { order: 3; }
  .order-md-4 { order: 4; }
  .order-md-5 { order: 5; }
  .order-md-6 { order: 6; }
  .order-md-7 { order: 7; }
  .order-md-8 { order: 8; }
  .order-md-9 { order: 9; }
  .order-md-10 { order: 10; }
  .order-md-11 { order: 11; }
  .order-md-12 { order: 12; }
  .offset-md-0 { margin-left: 0; }
  .offset-md-1 { margin-left: 8.33333%; }
  .offset-md-2 { margin-left: 16.66667%; }
  .offset-md-3 { margin-left: 25%; }
  .offset-md-4 { margin-left: 33.33333%; }
  .offset-md-5 { margin-left: 41.66667%; }
  .offset-md-6 { margin-left: 50%; }
  .offset-md-7 { margin-left: 58.33333%; }
  .offset-md-8 { margin-left: 66.66667%; }
  .offset-md-9 { margin-left: 75%; }
  .offset-md-10 { margin-left: 83.33333%; }
  .offset-md-11 { margin-left: 91.66667%; } }

@media (min-width: 992px) { .col-lg { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-lg-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-lg-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-lg-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-lg-3 { flex: 0 0 25%; max-width: 25%; }
  .col-lg-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-lg-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-lg-6 { flex: 0 0 50%; max-width: 50%; }
  .col-lg-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-lg-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-lg-9 { flex: 0 0 75%; max-width: 75%; }
  .col-lg-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-lg-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-lg-12 { flex: 0 0 100%; max-width: 100%; }
  .order-lg-first { order: -1; }
  .order-lg-last { order: 13; }
  .order-lg-0 { order: 0; }
  .order-lg-1 { order: 1; }
  .order-lg-2 { order: 2; }
  .order-lg-3 { order: 3; }
  .order-lg-4 { order: 4; }
  .order-lg-5 { order: 5; }
  .order-lg-6 { order: 6; }
  .order-lg-7 { order: 7; }
  .order-lg-8 { order: 8; }
  .order-lg-9 { order: 9; }
  .order-lg-10 { order: 10; }
  .order-lg-11 { order: 11; }
  .order-lg-12 { order: 12; }
  .offset-lg-0 { margin-left: 0; }
  .offset-lg-1 { margin-left: 8.33333%; }
  .offset-lg-2 { margin-left: 16.66667%; }
  .offset-lg-3 { margin-left: 25%; }
  .offset-lg-4 { margin-left: 33.33333%; }
  .offset-lg-5 { margin-left: 41.66667%; }
  .offset-lg-6 { margin-left: 50%; }
  .offset-lg-7 { margin-left: 58.33333%; }
  .offset-lg-8 { margin-left: 66.66667%; }
  .offset-lg-9 { margin-left: 75%; }
  .offset-lg-10 { margin-left: 83.33333%; }
  .offset-lg-11 { margin-left: 91.66667%; } }

@media (min-width: 1200px) { .col-xl { flex-basis: 0; flex-grow: 1; max-width: 100%; }
  .col-xl-auto { flex: 0 0 auto; width: auto; max-width: 100%; }
  .col-xl-1 { flex: 0 0 8.33333%; max-width: 8.33333%; }
  .col-xl-2 { flex: 0 0 16.66667%; max-width: 16.66667%; }
  .col-xl-3 { flex: 0 0 25%; max-width: 25%; }
  .col-xl-4 { flex: 0 0 33.33333%; max-width: 33.33333%; }
  .col-xl-5 { flex: 0 0 41.66667%; max-width: 41.66667%; }
  .col-xl-6 { flex: 0 0 50%; max-width: 50%; }
  .col-xl-7 { flex: 0 0 58.33333%; max-width: 58.33333%; }
  .col-xl-8 { flex: 0 0 66.66667%; max-width: 66.66667%; }
  .col-xl-9 { flex: 0 0 75%; max-width: 75%; }
  .col-xl-10 { flex: 0 0 83.33333%; max-width: 83.33333%; }
  .col-xl-11 { flex: 0 0 91.66667%; max-width: 91.66667%; }
  .col-xl-12 { flex: 0 0 100%; max-width: 100%; }
  .order-xl-first { order: -1; }
  .order-xl-last { order: 13; }
  .order-xl-0 { order: 0; }
  .order-xl-1 { order: 1; }
  .order-xl-2 { order: 2; }
  .order-xl-3 { order: 3; }
  .order-xl-4 { order: 4; }
  .order-xl-5 { order: 5; }
  .order-xl-6 { order: 6; }
  .order-xl-7 { order: 7; }
  .order-xl-8 { order: 8; }
  .order-xl-9 { order: 9; }
  .order-xl-10 { order: 10; }
  .order-xl-11 { order: 11; }
  .order-xl-12 { order: 12; }
  .offset-xl-0 { margin-left: 0; }
  .offset-xl-1 { margin-left: 8.33333%; }
  .offset-xl-2 { margin-left: 16.66667%; }
  .offset-xl-3 { margin-left: 25%; }
  .offset-xl-4 { margin-left: 33.33333%; }
  .offset-xl-5 { margin-left: 41.66667%; }
  .offset-xl-6 { margin-left: 50%; }
  .offset-xl-7 { margin-left: 58.33333%; }
  .offset-xl-8 { margin-left: 66.66667%; }
  .offset-xl-9 { margin-left: 75%; }
  .offset-xl-10 { margin-left: 83.33333%; }
  .offset-xl-11 { margin-left: 91.66667%; } }

.table { width: 100%; margin-bottom: 1rem; color: #212529; }

.table th, .table td { padding: 0.75rem; vertical-align: top; border-top: 1px solid #dee2e6; }

.table thead th { vertical-align: bottom; border-bottom: 2px solid #dee2e6; }

.table tbody + tbody { border-top: 2px solid #dee2e6; }

.table-sm th, .table-sm td { padding: 0.3rem; }

.table-bordered { border: 1px solid #dee2e6; }

.table-bordered th, .table-bordered td { border: 1px solid #dee2e6; }

.table-bordered thead th, .table-bordered thead td { border-bottom-width: 2px; }

.table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody + tbody { border: 0; }

.table-striped tbody tr:nth-of-type(odd) { background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover { color: #212529; background-color: rgba(0, 0, 0, 0.075); }

.table-primary, .table-primary > th, .table-primary > td { background-color: #b8daff; }

.table-primary th, .table-primary td, .table-primary thead th, .table-primary tbody + tbody { border-color: #7abaff; }

.table-hover .table-primary:hover { background-color: #9fcdff; }

.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th { background-color: #9fcdff; }

.table-secondary, .table-secondary > th, .table-secondary > td { background-color: #d6d8db; }

.table-secondary th, .table-secondary td, .table-secondary thead th, .table-secondary tbody + tbody { border-color: #b3b7bb; }

.table-hover .table-secondary:hover { background-color: #c8cbcf; }

.table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th { background-color: #c8cbcf; }

.table-success, .table-success > th, .table-success > td { background-color: #c3e6cb; }

.table-success th, .table-success td, .table-success thead th, .table-success tbody + tbody { border-color: #8fd19e; }

.table-hover .table-success:hover { background-color: #b1dfbb; }

.table-hover .table-success:hover > td, .table-hover .table-success:hover > th { background-color: #b1dfbb; }

.table-info, .table-info > th, .table-info > td { background-color: #bee5eb; }

.table-info th, .table-info td, .table-info thead th, .table-info tbody + tbody { border-color: #86cfda; }

.table-hover .table-info:hover { background-color: #abdde5; }

.table-hover .table-info:hover > td, .table-hover .table-info:hover > th { background-color: #abdde5; }

.table-warning, .table-warning > th, .table-warning > td { background-color: #ffeeba; }

.table-warning th, .table-warning td, .table-warning thead th, .table-warning tbody + tbody { border-color: #ffdf7e; }

.table-hover .table-warning:hover { background-color: #ffe8a1; }

.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th { background-color: #ffe8a1; }

.table-danger, .table-danger > th, .table-danger > td { background-color: #f5c6cb; }

.table-danger th, .table-danger td, .table-danger thead th, .table-danger tbody + tbody { border-color: #ed969e; }

.table-hover .table-danger:hover { background-color: #f1b0b7; }

.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th { background-color: #f1b0b7; }

.table-light, .table-light > th, .table-light > td { background-color: #fdfdfe; }

.table-light th, .table-light td, .table-light thead th, .table-light tbody + tbody { border-color: #fbfcfc; }

.table-hover .table-light:hover { background-color: #ececf6; }

.table-hover .table-light:hover > td, .table-hover .table-light:hover > th { background-color: #ececf6; }

.table-dark, .table-dark > th, .table-dark > td { background-color: #c6c8ca; }

.table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody { border-color: #95999c; }

.table-hover .table-dark:hover { background-color: #b9bbbe; }

.table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th { background-color: #b9bbbe; }

.table-active, .table-active > th, .table-active > td { background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover { background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover > td, .table-hover .table-active:hover > th { background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th { color: #fff; background-color: #343a40; border-color: #454d55; }

.table .thead-light th { color: #495057; background-color: #e9ecef; border-color: #dee2e6; }

.table-dark { color: #fff; background-color: #343a40; }

.table-dark th, .table-dark td, .table-dark thead th { border-color: #454d55; }

.table-dark.table-bordered { border: 0; }

.table-dark.table-striped tbody tr:nth-of-type(odd) { background-color: rgba(255, 255, 255, 0.05); }

.table-dark.table-hover tbody tr:hover { color: #fff; background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) { .table-responsive-sm { display: block; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; }
  .table-responsive-sm > .table-bordered { border: 0; } }

@media (max-width: 767.98px) { .table-responsive-md { display: block; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; }
  .table-responsive-md > .table-bordered { border: 0; } }

@media (max-width: 991.98px) { .table-responsive-lg { display: block; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; }
  .table-responsive-lg > .table-bordered { border: 0; } }

@media (max-width: 1199.98px) { .table-responsive-xl { display: block; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; }
  .table-responsive-xl > .table-bordered { border: 0; } }

.table-responsive { display: block; width: 100%; overflow-x: auto; -webkit-overflow-scrolling: touch; }

.table-responsive > .table-bordered { border: 0; }

.form-control { display: block; width: 100%; height: calc(1.5em + 0.75rem + 2px); padding: 0.375rem 0.75rem; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #495057; background-color: #fff; background-clip: padding-box; border: 1px solid #ced4da; border-radius: 0.25rem; transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) { .form-control { transition: none; } }

.form-control::-ms-expand { background-color: transparent; border: 0; }

.form-control:focus { color: #495057; background-color: #fff; border-color: #80bdff; outline: 0; box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.form-control::placeholder { color: #6c757d; opacity: 1; }

.form-control:disabled, .form-control[readonly] { background-color: #e9ecef; opacity: 1; }

select.form-control:focus::-ms-value { color: #495057; background-color: #fff; }

.form-control-file, .form-control-range { display: block; width: 100%; }

.col-form-label { padding-top: calc(0.375rem + 1px); padding-bottom: calc(0.375rem + 1px); margin-bottom: 0; font-size: inherit; line-height: 1.5; }

.col-form-label-lg { padding-top: calc(0.5rem + 1px); padding-bottom: calc(0.5rem + 1px); font-size: 1.25rem; line-height: 1.5; }

.col-form-label-sm { padding-top: calc(0.25rem + 1px); padding-bottom: calc(0.25rem + 1px); font-size: 0.875rem; line-height: 1.5; }

.form-control-plaintext { display: block; width: 100%; padding-top: 0.375rem; padding-bottom: 0.375rem; margin-bottom: 0; line-height: 1.5; color: #212529; background-color: transparent; border: solid transparent; border-width: 1px 0; }

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg { padding-right: 0; padding-left: 0; }

.form-control-sm { height: calc(1.5em + 0.5rem + 2px); padding: 0.25rem 0.5rem; font-size: 0.875rem; line-height: 1.5; border-radius: 0.2rem; }

.form-control-lg { height: calc(1.5em + 1rem + 2px); padding: 0.5rem 1rem; font-size: 1.25rem; line-height: 1.5; border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] { height: auto; }

textarea.form-control { height: auto; }

.form-group { margin-bottom: 1rem; }

.form-text { display: block; margin-top: 0.25rem; }

.form-row { display: flex; flex-wrap: wrap; margin-right: -5px; margin-left: -5px; }

.form-row > .col, .form-row > [class*="col-"] { padding-right: 5px; padding-left: 5px; }

.form-check { position: relative; display: block; padding-left: 1.25rem; }

.form-check-input { position: absolute; margin-top: 0.3rem; margin-left: -1.25rem; }

.form-check-input:disabled ~ .form-check-label { color: #6c757d; }

.form-check-label { margin-bottom: 0; }

.form-check-inline { display: inline-flex; align-items: center; padding-left: 0; margin-right: 0.75rem; }

.form-check-inline .form-check-input { position: static; margin-top: 0; margin-right: 0.3125rem; margin-left: 0; }

.valid-feedback { display: none; width: 100%; margin-top: 0.25rem; font-size: 80%; color: #28a745; }

.valid-tooltip { position: absolute; top: 100%; z-index: 5; display: none; max-width: 100%; padding: 0.25rem 0.5rem; margin-top: .1rem; font-size: 0.875rem; line-height: 1.5; color: #fff; background-color: rgba(40, 167, 69, 0.9); border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid { border-color: #28a745; padding-right: calc(1.5em + 0.75rem); background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); background-repeat: no-repeat; background-position: center right calc(0.375em + 0.1875rem); background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }

.was-validated .form-control:valid:focus, .form-control.is-valid:focus { border-color: #28a745; box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip { display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid { padding-right: calc(1.5em + 0.75rem); background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid { border-color: #28a745; padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem); background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus { border-color: #28a745; box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip { display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback, .was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback, .form-control-file.is-valid ~ .valid-tooltip { display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label { color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip { display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label { color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before { border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip { display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before { border-color: #34ce57; background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before { box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before { border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label { border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip { display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label { border-color: #28a745; box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback { display: none; width: 100%; margin-top: 0.25rem; font-size: 80%; color: #dc3545; }

.invalid-tooltip { position: absolute; top: 100%; z-index: 5; display: none; max-width: 100%; padding: 0.25rem 0.5rem; margin-top: .1rem; font-size: 0.875rem; line-height: 1.5; color: #fff; background-color: rgba(220, 53, 69, 0.9); border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid { border-color: #dc3545; padding-right: calc(1.5em + 0.75rem); background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"); background-repeat: no-repeat; background-position: center right calc(0.375em + 0.1875rem); background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus { border-color: #dc3545; box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip { display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid { padding-right: calc(1.5em + 0.75rem); background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid { border-color: #dc3545; padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem); background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus { border-color: #dc3545; box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip { display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback, .was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback, .form-control-file.is-invalid ~ .invalid-tooltip { display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label { color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip { display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label { color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before { border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip { display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before { border-color: #e4606d; background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before { box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before { border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label { border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip { display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label { border-color: #dc3545; box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline { display: flex; flex-flow: row wrap; align-items: center; }

.form-inline .form-check { width: 100%; }

@media (min-width: 576px) { .form-inline label { display: flex; align-items: center; justify-content: center; margin-bottom: 0; }
  .form-inline .form-group { display: flex; flex: 0 0 auto; flex-flow: row wrap; align-items: center; margin-bottom: 0; }
  .form-inline .form-control { display: inline-block; width: auto; vertical-align: middle; }
  .form-inline .form-control-plaintext { display: inline-block; }
  .form-inline .input-group, .form-inline .custom-select { width: auto; }
  .form-inline .form-check { display: flex; align-items: center; justify-content: center; width: auto; padding-left: 0; }
  .form-inline .form-check-input { position: relative; flex-shrink: 0; margin-top: 0; margin-right: 0.25rem; margin-left: 0; }
  .form-inline .custom-control { align-items: center; justify-content: center; }
  .form-inline .custom-control-label { margin-bottom: 0; } }

.btn { display: inline-block; font-weight: 400; color: #212529; text-align: center; vertical-align: middle; user-select: none; background-color: transparent; border: 1px solid transparent; padding: 0.375rem 0.75rem; font-size: 1rem; line-height: 1.5; border-radius: 0.25rem; transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) { .btn { transition: none; } }

.btn:hover { color: #212529; text-decoration: none; }

.btn:focus, .btn.focus { outline: 0; box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.btn.disabled, .btn:disabled { opacity: 0.65; }

a.btn.disabled, fieldset:disabled a.btn { pointer-events: none; }

.btn-primary { color: #fff; background-color: #007bff; border-color: #007bff; }

.btn-primary:hover { color: #fff; background-color: #0069d9; border-color: #0062cc; }

.btn-primary:focus, .btn-primary.focus { box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-primary.disabled, .btn-primary:disabled { color: #fff; background-color: #007bff; border-color: #007bff; }

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle { color: #fff; background-color: #0062cc; border-color: #005cbf; }

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary { color: #fff; background-color: #6c757d; border-color: #6c757d; }

.btn-secondary:hover { color: #fff; background-color: #5a6268; border-color: #545b62; }

.btn-secondary:focus, .btn-secondary.focus { box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-secondary.disabled, .btn-secondary:disabled { color: #fff; background-color: #6c757d; border-color: #6c757d; }

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle { color: #fff; background-color: #545b62; border-color: #4e555b; }

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success { color: #fff; background-color: #28a745; border-color: #28a745; }

.btn-success:hover { color: #fff; background-color: #218838; border-color: #1e7e34; }

.btn-success:focus, .btn-success.focus { box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-success.disabled, .btn-success:disabled { color: #fff; background-color: #28a745; border-color: #28a745; }

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle { color: #fff; background-color: #1e7e34; border-color: #1c7430; }

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info { color: #fff; background-color: #17a2b8; border-color: #17a2b8; }

.btn-info:hover { color: #fff; background-color: #138496; border-color: #117a8b; }

.btn-info:focus, .btn-info.focus { box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-info.disabled, .btn-info:disabled { color: #fff; background-color: #17a2b8; border-color: #17a2b8; }

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle { color: #fff; background-color: #117a8b; border-color: #10707f; }

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning { color: #212529; background-color: #ffc107; border-color: #ffc107; }

.btn-warning:hover { color: #212529; background-color: #e0a800; border-color: #d39e00; }

.btn-warning:focus, .btn-warning.focus { box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-warning.disabled, .btn-warning:disabled { color: #212529; background-color: #ffc107; border-color: #ffc107; }

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle { color: #212529; background-color: #d39e00; border-color: #c69500; }

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger { color: #fff; background-color: #dc3545; border-color: #dc3545; }

.btn-danger:hover { color: #fff; background-color: #c82333; border-color: #bd2130; }

.btn-danger:focus, .btn-danger.focus { box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-danger.disabled, .btn-danger:disabled { color: #fff; background-color: #dc3545; border-color: #dc3545; }

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle { color: #fff; background-color: #bd2130; border-color: #b21f2d; }

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light { color: #212529; background-color: #f8f9fa; border-color: #f8f9fa; }

.btn-light:hover { color: #212529; background-color: #e2e6ea; border-color: #dae0e5; }

.btn-light:focus, .btn-light.focus { box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-light.disabled, .btn-light:disabled { color: #212529; background-color: #f8f9fa; border-color: #f8f9fa; }

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle { color: #212529; background-color: #dae0e5; border-color: #d3d9df; }

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark { color: #fff; background-color: #343a40; border-color: #343a40; }

.btn-dark:hover { color: #fff; background-color: #23272b; border-color: #1d2124; }

.btn-dark:focus, .btn-dark.focus { box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-dark.disabled, .btn-dark:disabled { color: #fff; background-color: #343a40; border-color: #343a40; }

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle { color: #fff; background-color: #1d2124; border-color: #171a1d; }

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary { color: #007bff; border-color: #007bff; }

.btn-outline-primary:hover { color: #fff; background-color: #007bff; border-color: #007bff; }

.btn-outline-primary:focus, .btn-outline-primary.focus { box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-primary.disabled, .btn-outline-primary:disabled { color: #007bff; background-color: transparent; }

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle { color: #fff; background-color: #007bff; border-color: #007bff; }

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary { color: #6c757d; border-color: #6c757d; }

.btn-outline-secondary:hover { color: #fff; background-color: #6c757d; border-color: #6c757d; }

.btn-outline-secondary:focus, .btn-outline-secondary.focus { box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled { color: #6c757d; background-color: transparent; }

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle { color: #fff; background-color: #6c757d; border-color: #6c757d; }

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success { color: #28a745; border-color: #28a745; }

.btn-outline-success:hover { color: #fff; background-color: #28a745; border-color: #28a745; }

.btn-outline-success:focus, .btn-outline-success.focus { box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-success.disabled, .btn-outline-success:disabled { color: #28a745; background-color: transparent; }

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle { color: #fff; background-color: #28a745; border-color: #28a745; }

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info { color: #17a2b8; border-color: #17a2b8; }

.btn-outline-info:hover { color: #fff; background-color: #17a2b8; border-color: #17a2b8; }

.btn-outline-info:focus, .btn-outline-info.focus { box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-info.disabled, .btn-outline-info:disabled { color: #17a2b8; background-color: transparent; }

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle { color: #fff; background-color: #17a2b8; border-color: #17a2b8; }

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning { color: #ffc107; border-color: #ffc107; }

.btn-outline-warning:hover { color: #212529; background-color: #ffc107; border-color: #ffc107; }

.btn-outline-warning:focus, .btn-outline-warning.focus { box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-warning.disabled, .btn-outline-warning:disabled { color: #ffc107; background-color: transparent; }

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle { color: #212529; background-color: #ffc107; border-color: #ffc107; }

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger { color: #dc3545; border-color: #dc3545; }

.btn-outline-danger:hover { color: #fff; background-color: #dc3545; border-color: #dc3545; }

.btn-outline-danger:focus, .btn-outline-danger.focus { box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-danger.disabled, .btn-outline-danger:disabled { color: #dc3545; background-color: transparent; }

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle { color: #fff; background-color: #dc3545; border-color: #dc3545; }

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light { color: #f8f9fa; border-color: #f8f9fa; }

.btn-outline-light:hover { color: #212529; background-color: #f8f9fa; border-color: #f8f9fa; }

.btn-outline-light:focus, .btn-outline-light.focus { box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-light.disabled, .btn-outline-light:disabled { color: #f8f9fa; background-color: transparent; }

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle { color: #212529; background-color: #f8f9fa; border-color: #f8f9fa; }

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark { color: #343a40; border-color: #343a40; }

.btn-outline-dark:hover { color: #fff; background-color: #343a40; border-color: #343a40; }

.btn-outline-dark:focus, .btn-outline-dark.focus { box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-dark.disabled, .btn-outline-dark:disabled { color: #343a40; background-color: transparent; }

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle { color: #fff; background-color: #343a40; border-color: #343a40; }

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus { box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link { font-weight: 400; color: #007bff; text-decoration: none; }

.btn-link:hover { color: #0056b3; text-decoration: underline; }

.btn-link:focus, .btn-link.focus { text-decoration: underline; box-shadow: none; }

.btn-link:disabled, .btn-link.disabled { color: #6c757d; pointer-events: none; }

.btn-lg, .btn-group-lg > .btn { padding: 0.5rem 1rem; font-size: 1.25rem; line-height: 1.5; border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn { padding: 0.25rem 0.5rem; font-size: 0.875rem; line-height: 1.5; border-radius: 0.2rem; }

.btn-block { display: block; width: 100%; }

.btn-block + .btn-block { margin-top: 0.5rem; }

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block { width: 100%; }

.fade { transition: opacity 0.15s linear; }

@media (prefers-reduced-motion: reduce) { .fade { transition: none; } }

.fade:not(.show) { opacity: 0; }

.collapse:not(.show) { display: none; }

.collapsing { position: relative; height: 0; overflow: hidden; transition: height 0.35s ease; }

@media (prefers-reduced-motion: reduce) { .collapsing { transition: none; } }

.dropup, .dropright, .dropdown, .dropleft { position: relative; }

.dropdown-toggle { white-space: nowrap; }

.dropdown-toggle::after { display: inline-block; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0.3em solid; border-right: 0.3em solid transparent; border-bottom: 0; border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after { margin-left: 0; }

.dropdown-menu { position: absolute; top: 100%; left: 0; z-index: 1000; display: none; float: left; min-width: 10rem; padding: 0.5rem 0; margin: 0.125rem 0 0; font-size: 1rem; color: #212529; text-align: left; list-style: none; background-color: #fff; background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.15); border-radius: 0.25rem; }

.dropdown-menu-left { right: auto; left: 0; }

.dropdown-menu-right { right: 0; left: auto; }

@media (min-width: 576px) { .dropdown-menu-sm-left { right: auto; left: 0; }
  .dropdown-menu-sm-right { right: 0; left: auto; } }

@media (min-width: 768px) { .dropdown-menu-md-left { right: auto; left: 0; }
  .dropdown-menu-md-right { right: 0; left: auto; } }

@media (min-width: 992px) { .dropdown-menu-lg-left { right: auto; left: 0; }
  .dropdown-menu-lg-right { right: 0; left: auto; } }

@media (min-width: 1200px) { .dropdown-menu-xl-left { right: auto; left: 0; }
  .dropdown-menu-xl-right { right: 0; left: auto; } }

.dropup .dropdown-menu { top: auto; bottom: 100%; margin-top: 0; margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after { display: inline-block; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0; border-right: 0.3em solid transparent; border-bottom: 0.3em solid; border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after { margin-left: 0; }

.dropright .dropdown-menu { top: 0; right: auto; left: 100%; margin-top: 0; margin-left: 0.125rem; }

.dropright .dropdown-toggle::after { display: inline-block; margin-left: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0.3em solid transparent; border-right: 0; border-bottom: 0.3em solid transparent; border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after { margin-left: 0; }

.dropright .dropdown-toggle::after { vertical-align: 0; }

.dropleft .dropdown-menu { top: 0; right: 100%; left: auto; margin-top: 0; margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after { display: inline-block; margin-left: 0.255em; vertical-align: 0.255em; content: ""; }

.dropleft .dropdown-toggle::after { display: none; }

.dropleft .dropdown-toggle::before { display: inline-block; margin-right: 0.255em; vertical-align: 0.255em; content: ""; border-top: 0.3em solid transparent; border-right: 0.3em solid; border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after { margin-left: 0; }

.dropleft .dropdown-toggle::before { vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] { right: auto; bottom: auto; }

.dropdown-divider { height: 0; margin: 0.5rem 0; overflow: hidden; border-top: 1px solid #e9ecef; }

.dropdown-item { display: block; width: 100%; padding: 0.25rem 1.5rem; clear: both; font-weight: 400; color: #212529; text-align: inherit; white-space: nowrap; background-color: transparent; border: 0; }

.dropdown-item:hover, .dropdown-item:focus { color: #16181b; text-decoration: none; background-color: #f8f9fa; }

.dropdown-item.active, .dropdown-item:active { color: #fff; text-decoration: none; background-color: #007bff; }

.dropdown-item.disabled, .dropdown-item:disabled { color: #6c757d; pointer-events: none; background-color: transparent; }

.dropdown-menu.show { display: block; }

.dropdown-header { display: block; padding: 0.5rem 1.5rem; margin-bottom: 0; font-size: 0.875rem; color: #6c757d; white-space: nowrap; }

.dropdown-item-text { display: block; padding: 0.25rem 1.5rem; color: #212529; }

.btn-group, .btn-group-vertical { position: relative; display: inline-flex; vertical-align: middle; }

.btn-group > .btn, .btn-group-vertical > .btn { position: relative; flex: 1 1 auto; }

.btn-group > .btn:hover, .btn-group-vertical > .btn:hover { z-index: 1; }

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active { z-index: 1; }

.btn-toolbar { display: flex; flex-wrap: wrap; justify-content: flex-start; }

.btn-toolbar .input-group { width: auto; }

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) { margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn { border-top-right-radius: 0; border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn { border-top-left-radius: 0; border-bottom-left-radius: 0; }

.dropdown-toggle-split { padding-right: 0.5625rem; padding-left: 0.5625rem; }

.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after { margin-left: 0; }

.dropleft .dropdown-toggle-split::before { margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split { padding-right: 0.375rem; padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split { padding-right: 0.75rem; padding-left: 0.75rem; }

.btn-group-vertical { flex-direction: column; align-items: flex-start; justify-content: center; }

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group { width: 100%; }

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) { margin-top: -1px; }

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn { border-bottom-right-radius: 0; border-bottom-left-radius: 0; }

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) > .btn { border-top-left-radius: 0; border-top-right-radius: 0; }

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn { margin-bottom: 0; }

.btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"] { position: absolute; clip: rect(0, 0, 0, 0); pointer-events: none; }

.input-group { position: relative; display: flex; flex-wrap: wrap; align-items: stretch; width: 100%; }

.input-group > .form-control, .input-group > .form-control-plaintext, .input-group > .custom-select, .input-group > .custom-file { position: relative; flex: 1 1 auto; width: 1%; margin-bottom: 0; }

.input-group > .form-control + .form-control, .input-group > .form-control + .custom-select, .input-group > .form-control + .custom-file, .input-group > .form-control-plaintext + .form-control, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .custom-file, .input-group > .custom-select + .form-control, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .custom-file, .input-group > .custom-file + .form-control, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .custom-file { margin-left: -1px; }

.input-group > .form-control:focus, .input-group > .custom-select:focus, .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label { z-index: 3; }

.input-group > .custom-file .custom-file-input:focus { z-index: 4; }

.input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) { border-top-right-radius: 0; border-bottom-right-radius: 0; }

.input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child) { border-top-left-radius: 0; border-bottom-left-radius: 0; }

.input-group > .custom-file { display: flex; align-items: center; }

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after { border-top-right-radius: 0; border-bottom-right-radius: 0; }

.input-group > .custom-file:not(:first-child) .custom-file-label { border-top-left-radius: 0; border-bottom-left-radius: 0; }

.input-group-prepend, .input-group-append { display: flex; }

.input-group-prepend .btn, .input-group-append .btn { position: relative; z-index: 2; }

.input-group-prepend .btn:focus, .input-group-append .btn:focus { z-index: 3; }

.input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .input-group-text, .input-group-append .input-group-text + .btn { margin-left: -1px; }

.input-group-prepend { margin-right: -1px; }

.input-group-append { margin-left: -1px; }

.input-group-text { display: flex; align-items: center; padding: 0.375rem 0.75rem; margin-bottom: 0; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #495057; text-align: center; white-space: nowrap; background-color: #e9ecef; border: 1px solid #ced4da; border-radius: 0.25rem; }

.input-group-text input[type="radio"], .input-group-text input[type="checkbox"] { margin-top: 0; }

.input-group-lg > .form-control:not(textarea), .input-group-lg > .custom-select { height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control, .input-group-lg > .custom-select, .input-group-lg > .input-group-prepend > .input-group-text, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-append > .btn { padding: 0.5rem 1rem; font-size: 1.25rem; line-height: 1.5; border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea), .input-group-sm > .custom-select { height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn { padding: 0.25rem 0.5rem; font-size: 0.875rem; line-height: 1.5; border-radius: 0.2rem; }

.input-group-lg > .custom-select, .input-group-sm > .custom-select { padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text, .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) { border-top-right-radius: 0; border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) { border-top-left-radius: 0; border-bottom-left-radius: 0; }

.custom-control { position: relative; display: block; min-height: 1.5rem; padding-left: 1.5rem; }

.custom-control-inline { display: inline-flex; margin-right: 1rem; }

.custom-control-input { position: absolute; z-index: -1; opacity: 0; }

.custom-control-input:checked ~ .custom-control-label::before { color: #fff; border-color: #007bff; background-color: #007bff; }

.custom-control-input:focus ~ .custom-control-label::before { box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before { border-color: #80bdff; }

.custom-control-input:not(:disabled):active ~ .custom-control-label::before { color: #fff; background-color: #b3d7ff; border-color: #b3d7ff; }

.custom-control-input:disabled ~ .custom-control-label { color: #6c757d; }

.custom-control-input:disabled ~ .custom-control-label::before { background-color: #e9ecef; }

.custom-control-label { position: relative; margin-bottom: 0; vertical-align: top; }

.custom-control-label::before { position: absolute; top: 0.25rem; left: -1.5rem; display: block; width: 1rem; height: 1rem; pointer-events: none; content: ""; background-color: #fff; border: #adb5bd solid 1px; }

.custom-control-label::after { position: absolute; top: 0.25rem; left: -1.5rem; display: block; width: 1rem; height: 1rem; content: ""; background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before { border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before { border-color: #007bff; background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before { background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before { background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before { border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before { background-color: rgba(0, 123, 255, 0.5); }

.custom-switch { padding-left: 2.25rem; }

.custom-switch .custom-control-label::before { left: -2.25rem; width: 1.75rem; pointer-events: all; border-radius: 0.5rem; }

.custom-switch .custom-control-label::after { top: calc(0.25rem + 2px); left: calc(-2.25rem + 2px); width: calc(1rem - 4px); height: calc(1rem - 4px); background-color: #adb5bd; border-radius: 0.5rem; transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) { .custom-switch .custom-control-label::after { transition: none; } }

.custom-switch .custom-control-input:checked ~ .custom-control-label::after { background-color: #fff; transform: translateX(0.75rem); }

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before { background-color: rgba(0, 123, 255, 0.5); }

.custom-select { display: inline-block; width: 100%; height: calc(1.5em + 0.75rem + 2px); padding: 0.375rem 1.75rem 0.375rem 0.75rem; font-size: 1rem; font-weight: 400; line-height: 1.5; color: #495057; vertical-align: middle; background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px; background-color: #fff; border: 1px solid #ced4da; border-radius: 0.25rem; appearance: none; }

.custom-select:focus { border-color: #80bdff; outline: 0; box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-select:focus::-ms-value { color: #495057; background-color: #fff; }

.custom-select[multiple], .custom-select[size]:not([size="1"]) { height: auto; padding-right: 0.75rem; background-image: none; }

.custom-select:disabled { color: #6c757d; background-color: #e9ecef; }

.custom-select::-ms-expand { display: none; }

.custom-select-sm { height: calc(1.5em + 0.5rem + 2px); padding-top: 0.25rem; padding-bottom: 0.25rem; padding-left: 0.5rem; font-size: 0.875rem; }

.custom-select-lg { height: calc(1.5em + 1rem + 2px); padding-top: 0.5rem; padding-bottom: 0.5rem; padding-left: 1rem; font-size: 1.25rem; }

.custom-file { position: relative; display: inline-block; width: 100%; height: calc(1.5em + 0.75rem + 2px); margin-bottom: 0; }

.custom-file-input { position: relative; z-index: 2; width: 100%; height: calc(1.5em + 0.75rem + 2px); margin: 0; opacity: 0; }

.custom-file-input:focus ~ .custom-file-label { border-color: #80bdff; box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-file-input:disabled ~ .custom-file-label { background-color: #e9ecef; }

.custom-file-input:lang(en) ~ .custom-file-label::after { content: "Browse"; }

.custom-file-input ~ .custom-file-label[data-browse]::after { content: attr(data-browse); }

.custom-file-label { position: absolute; top: 0; right: 0; left: 0; z-index: 1; height: calc(1.5em + 0.75rem + 2px); padding: 0.375rem 0.75rem; font-weight: 400; line-height: 1.5; color: #495057; background-color: #fff; border: 1px solid #ced4da; border-radius: 0.25rem; }

.custom-file-label::after { position: absolute; top: 0; right: 0; bottom: 0; z-index: 3; display: block; height: calc(1.5em + 0.75rem); padding: 0.375rem 0.75rem; line-height: 1.5; color: #495057; content: "Browse"; background-color: #e9ecef; border-left: inherit; border-radius: 0 0.25rem 0.25rem 0; }

.custom-range { width: 100%; height: calc(1rem + 0.4rem); padding: 0; background-color: transparent; appearance: none; }

.custom-range:focus { outline: none; }

.custom-range:focus::-webkit-slider-thumb { box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range:focus::-moz-range-thumb { box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range:focus::-ms-thumb { box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range::-moz-focus-outer { border: 0; }

.custom-range::-webkit-slider-thumb { width: 1rem; height: 1rem; margin-top: -0.25rem; background-color: #007bff; border: 0; border-radius: 1rem; transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; appearance: none; }

@media (prefers-reduced-motion: reduce) { .custom-range::-webkit-slider-thumb { transition: none; } }

.custom-range::-webkit-slider-thumb:active { background-color: #b3d7ff; }

.custom-range::-webkit-slider-runnable-track { width: 100%; height: 0.5rem; color: transparent; cursor: pointer; background-color: #dee2e6; border-color: transparent; border-radius: 1rem; }

.custom-range::-moz-range-thumb { width: 1rem; height: 1rem; background-color: #007bff; border: 0; border-radius: 1rem; transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; appearance: none; }

@media (prefers-reduced-motion: reduce) { .custom-range::-moz-range-thumb { transition: none; } }

.custom-range::-moz-range-thumb:active { background-color: #b3d7ff; }

.custom-range::-moz-range-track { width: 100%; height: 0.5rem; color: transparent; cursor: pointer; background-color: #dee2e6; border-color: transparent; border-radius: 1rem; }

.custom-range::-ms-thumb { width: 1rem; height: 1rem; margin-top: 0; margin-right: 0.2rem; margin-left: 0.2rem; background-color: #007bff; border: 0; border-radius: 1rem; transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; appearance: none; }

@media (prefers-reduced-motion: reduce) { .custom-range::-ms-thumb { transition: none; } }

.custom-range::-ms-thumb:active { background-color: #b3d7ff; }

.custom-range::-ms-track { width: 100%; height: 0.5rem; color: transparent; cursor: pointer; background-color: transparent; border-color: transparent; border-width: 0.5rem; }

.custom-range::-ms-fill-lower { background-color: #dee2e6; border-radius: 1rem; }

.custom-range::-ms-fill-upper { margin-right: 15px; background-color: #dee2e6; border-radius: 1rem; }

.custom-range:disabled::-webkit-slider-thumb { background-color: #adb5bd; }

.custom-range:disabled::-webkit-slider-runnable-track { cursor: default; }

.custom-range:disabled::-moz-range-thumb { background-color: #adb5bd; }

.custom-range:disabled::-moz-range-track { cursor: default; }

.custom-range:disabled::-ms-thumb { background-color: #adb5bd; }

.custom-control-label::before, .custom-file-label, .custom-select { transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) { .custom-control-label::before, .custom-file-label, .custom-select { transition: none; } }

.nav { display: flex; flex-wrap: wrap; padding-left: 0; margin-bottom: 0; list-style: none; }

.nav-link { display: block; padding: 0.5rem 1rem; }

.nav-link:hover, .nav-link:focus { text-decoration: none; }

.nav-link.disabled { color: #6c757d; pointer-events: none; cursor: default; }

.nav-tabs { border-bottom: 1px solid #dee2e6; }

.nav-tabs .nav-item { margin-bottom: -1px; }

.nav-tabs .nav-link { border: 1px solid transparent; border-top-left-radius: 0.25rem; border-top-right-radius: 0.25rem; }

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus { border-color: #e9ecef #e9ecef #dee2e6; }

.nav-tabs .nav-link.disabled { color: #6c757d; background-color: transparent; border-color: transparent; }

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link { color: #495057; background-color: #fff; border-color: #dee2e6 #dee2e6 #fff; }

.nav-tabs .dropdown-menu { margin-top: -1px; border-top-left-radius: 0; border-top-right-radius: 0; }

.nav-pills .nav-link { border-radius: 0.25rem; }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link { color: #fff; background-color: #007bff; }

.nav-fill .nav-item { flex: 1 1 auto; text-align: center; }

.nav-justified .nav-item { flex-basis: 0; flex-grow: 1; text-align: center; }

.tab-content > .tab-pane { display: none; }

.tab-content > .active { display: block; }

.navbar { position: relative; display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; padding: 0.5rem 1rem; }

.navbar > .container, .navbar > .container-fluid { display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; }

.navbar-brand { display: inline-block; padding-top: 0.3125rem; padding-bottom: 0.3125rem; margin-right: 1rem; font-size: 1.25rem; line-height: inherit; white-space: nowrap; }

.navbar-brand:hover, .navbar-brand:focus { text-decoration: none; }

.navbar-nav { display: flex; flex-direction: column; padding-left: 0; margin-bottom: 0; list-style: none; }

.navbar-nav .nav-link { padding-right: 0; padding-left: 0; }

.navbar-nav .dropdown-menu { position: static; float: none; }

.navbar-text { display: inline-block; padding-top: 0.5rem; padding-bottom: 0.5rem; }

.navbar-collapse { flex-basis: 100%; flex-grow: 1; align-items: center; }

.navbar-toggler { padding: 0.25rem 0.75rem; font-size: 1.25rem; line-height: 1; background-color: transparent; border: 1px solid transparent; border-radius: 0.25rem; }

.navbar-toggler:hover, .navbar-toggler:focus { text-decoration: none; }

.navbar-toggler-icon { display: inline-block; width: 1.5em; height: 1.5em; vertical-align: middle; content: ""; background: no-repeat center center; background-size: 100% 100%; }

@media (max-width: 575.98px) { .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid { padding-right: 0; padding-left: 0; } }

@media (min-width: 576px) { .navbar-expand-sm { flex-flow: row nowrap; justify-content: flex-start; }
  .navbar-expand-sm .navbar-nav { flex-direction: row; }
  .navbar-expand-sm .navbar-nav .dropdown-menu { position: absolute; }
  .navbar-expand-sm .navbar-nav .nav-link { padding-right: 0.5rem; padding-left: 0.5rem; }
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid { flex-wrap: nowrap; }
  .navbar-expand-sm .navbar-collapse { display: flex !important; flex-basis: auto; }
  .navbar-expand-sm .navbar-toggler { display: none; } }

@media (max-width: 767.98px) { .navbar-expand-md > .container, .navbar-expand-md > .container-fluid { padding-right: 0; padding-left: 0; } }

@media (min-width: 768px) { .navbar-expand-md { flex-flow: row nowrap; justify-content: flex-start; }
  .navbar-expand-md .navbar-nav { flex-direction: row; }
  .navbar-expand-md .navbar-nav .dropdown-menu { position: absolute; }
  .navbar-expand-md .navbar-nav .nav-link { padding-right: 0.5rem; padding-left: 0.5rem; }
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid { flex-wrap: nowrap; }
  .navbar-expand-md .navbar-collapse { display: flex !important; flex-basis: auto; }
  .navbar-expand-md .navbar-toggler { display: none; } }

@media (max-width: 991.98px) { .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid { padding-right: 0; padding-left: 0; } }

@media (min-width: 992px) { .navbar-expand-lg { flex-flow: row nowrap; justify-content: flex-start; }
  .navbar-expand-lg .navbar-nav { flex-direction: row; }
  .navbar-expand-lg .navbar-nav .dropdown-menu { position: absolute; }
  .navbar-expand-lg .navbar-nav .nav-link { padding-right: 0.5rem; padding-left: 0.5rem; }
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid { flex-wrap: nowrap; }
  .navbar-expand-lg .navbar-collapse { display: flex !important; flex-basis: auto; }
  .navbar-expand-lg .navbar-toggler { display: none; } }

@media (max-width: 1199.98px) { .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid { padding-right: 0; padding-left: 0; } }

@media (min-width: 1200px) { .navbar-expand-xl { flex-flow: row nowrap; justify-content: flex-start; }
  .navbar-expand-xl .navbar-nav { flex-direction: row; }
  .navbar-expand-xl .navbar-nav .dropdown-menu { position: absolute; }
  .navbar-expand-xl .navbar-nav .nav-link { padding-right: 0.5rem; padding-left: 0.5rem; }
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid { flex-wrap: nowrap; }
  .navbar-expand-xl .navbar-collapse { display: flex !important; flex-basis: auto; }
  .navbar-expand-xl .navbar-toggler { display: none; } }

.navbar-expand { flex-flow: row nowrap; justify-content: flex-start; }

.navbar-expand > .container, .navbar-expand > .container-fluid { padding-right: 0; padding-left: 0; }

.navbar-expand .navbar-nav { flex-direction: row; }

.navbar-expand .navbar-nav .dropdown-menu { position: absolute; }

.navbar-expand .navbar-nav .nav-link { padding-right: 0.5rem; padding-left: 0.5rem; }

.navbar-expand > .container, .navbar-expand > .container-fluid { flex-wrap: nowrap; }

.navbar-expand .navbar-collapse { display: flex !important; flex-basis: auto; }

.navbar-expand .navbar-toggler { display: none; }

.navbar-light .navbar-brand { color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus { color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link { color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus { color: rgba(0, 0, 0, 0.7); }

.navbar-light .navbar-nav .nav-link.disabled { color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active { color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler { color: rgba(0, 0, 0, 0.5); border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon { background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text { color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-text a { color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus { color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand { color: #fff; }

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus { color: #fff; }

.navbar-dark .navbar-nav .nav-link { color: rgba(255, 255, 255, 0.5); }

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus { color: rgba(255, 255, 255, 0.75); }

.navbar-dark .navbar-nav .nav-link.disabled { color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active { color: #fff; }

.navbar-dark .navbar-toggler { color: rgba(255, 255, 255, 0.5); border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon { background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text { color: rgba(255, 255, 255, 0.5); }

.navbar-dark .navbar-text a { color: #fff; }

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus { color: #fff; }

.card { position: relative; display: flex; flex-direction: column; min-width: 0; word-wrap: break-word; background-color: #fff; background-clip: border-box; border: 1px solid rgba(0, 0, 0, 0.125); border-radius: 0.25rem; }

.card > hr { margin-right: 0; margin-left: 0; }

.card > .list-group:first-child .list-group-item:first-child { border-top-left-radius: 0.25rem; border-top-right-radius: 0.25rem; }

.card > .list-group:last-child .list-group-item:last-child { border-bottom-right-radius: 0.25rem; border-bottom-left-radius: 0.25rem; }

.card-body { flex: 1 1 auto; padding: 1.25rem; }

.card-title { margin-bottom: 0.75rem; }

.card-subtitle { margin-top: -0.375rem; margin-bottom: 0; }

.card-text:last-child { margin-bottom: 0; }

.card-link:hover { text-decoration: none; }

.card-link + .card-link { margin-left: 1.25rem; }

.card-header { padding: 0.75rem 1.25rem; margin-bottom: 0; background-color: rgba(0, 0, 0, 0.03); border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-header:first-child { border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-header + .list-group .list-group-item:first-child { border-top: 0; }

.card-footer { padding: 0.75rem 1.25rem; background-color: rgba(0, 0, 0, 0.03); border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-footer:last-child { border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs { margin-right: -0.625rem; margin-bottom: -0.75rem; margin-left: -0.625rem; border-bottom: 0; }

.card-header-pills { margin-right: -0.625rem; margin-left: -0.625rem; }

.card-img-overlay { position: absolute; top: 0; right: 0; bottom: 0; left: 0; padding: 1.25rem; }

.card-img { width: 100%; border-radius: calc(0.25rem - 1px); }

.card-img-top { width: 100%; border-top-left-radius: calc(0.25rem - 1px); border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom { width: 100%; border-bottom-right-radius: calc(0.25rem - 1px); border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck { display: flex; flex-direction: column; }

.card-deck .card { margin-bottom: 15px; }

@media (min-width: 576px) { .card-deck { flex-flow: row wrap; margin-right: -15px; margin-left: -15px; }
  .card-deck .card { display: flex; flex: 1 0 0%; flex-direction: column; margin-right: 15px; margin-bottom: 0; margin-left: 15px; } }

.card-group { display: flex; flex-direction: column; }

.card-group > .card { margin-bottom: 15px; }

@media (min-width: 576px) { .card-group { flex-flow: row wrap; }
  .card-group > .card { flex: 1 0 0%; margin-bottom: 0; }
  .card-group > .card + .card { margin-left: 0; border-left: 0; }
  .card-group > .card:not(:last-child) { border-top-right-radius: 0; border-bottom-right-radius: 0; }
  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header { border-top-right-radius: 0; }
  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer { border-bottom-right-radius: 0; }
  .card-group > .card:not(:first-child) { border-top-left-radius: 0; border-bottom-left-radius: 0; }
  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header { border-top-left-radius: 0; }
  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer { border-bottom-left-radius: 0; } }

.card-columns .card { margin-bottom: 0.75rem; }

@media (min-width: 576px) { .card-columns { column-count: 3; column-gap: 1.25rem; orphans: 1; widows: 1; }
  .card-columns .card { display: inline-block; width: 100%; } }

.accordion > .card { overflow: hidden; }

.accordion > .card:not(:first-of-type) .card-header:first-child { border-radius: 0; }

.accordion > .card:not(:first-of-type):not(:last-of-type) { border-bottom: 0; border-radius: 0; }

.accordion > .card:first-of-type { border-bottom: 0; border-bottom-right-radius: 0; border-bottom-left-radius: 0; }

.accordion > .card:last-of-type { border-top-left-radius: 0; border-top-right-radius: 0; }

.accordion > .card .card-header { margin-bottom: -1px; }

.breadcrumb { display: flex; flex-wrap: wrap; padding: 0.75rem 1rem; margin-bottom: 1rem; list-style: none; background-color: #e9ecef; border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item { padding-left: 0.5rem; }

.breadcrumb-item + .breadcrumb-item::before { display: inline-block; padding-right: 0.5rem; color: #6c757d; content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before { text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before { text-decoration: none; }

.breadcrumb-item.active { color: #6c757d; }

.pagination { display: flex; padding-left: 0; list-style: none; border-radius: 0.25rem; }

.page-link { position: relative; display: block; padding: 0.5rem 0.75rem; margin-left: -1px; line-height: 1.25; color: #007bff; background-color: #fff; border: 1px solid #dee2e6; }

.page-link:hover { z-index: 2; color: #0056b3; text-decoration: none; background-color: #e9ecef; border-color: #dee2e6; }

.page-link:focus { z-index: 2; outline: 0; box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link { margin-left: 0; border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link { border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link { z-index: 1; color: #fff; background-color: #007bff; border-color: #007bff; }

.page-item.disabled .page-link { color: #6c757d; pointer-events: none; cursor: auto; background-color: #fff; border-color: #dee2e6; }

.pagination-lg .page-link { padding: 0.75rem 1.5rem; font-size: 1.25rem; line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link { border-top-left-radius: 0.3rem; border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link { border-top-right-radius: 0.3rem; border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link { padding: 0.25rem 0.5rem; font-size: 0.875rem; line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link { border-top-left-radius: 0.2rem; border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link { border-top-right-radius: 0.2rem; border-bottom-right-radius: 0.2rem; }

.badge { display: inline-block; padding: 0.25em 0.4em; font-size: 75%; font-weight: 700; line-height: 1; text-align: center; white-space: nowrap; vertical-align: baseline; border-radius: 0.25rem; transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media (prefers-reduced-motion: reduce) { .badge { transition: none; } }

a.badge:hover, a.badge:focus { text-decoration: none; }

.badge:empty { display: none; }

.btn .badge { position: relative; top: -1px; }

.badge-pill { padding-right: 0.6em; padding-left: 0.6em; border-radius: 10rem; }

.badge-primary { color: #fff; background-color: #007bff; }

a.badge-primary:hover, a.badge-primary:focus { color: #fff; background-color: #0062cc; }

a.badge-primary:focus, a.badge-primary.focus { outline: 0; box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary { color: #fff; background-color: #6c757d; }

a.badge-secondary:hover, a.badge-secondary:focus { color: #fff; background-color: #545b62; }

a.badge-secondary:focus, a.badge-secondary.focus { outline: 0; box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success { color: #fff; background-color: #28a745; }

a.badge-success:hover, a.badge-success:focus { color: #fff; background-color: #1e7e34; }

a.badge-success:focus, a.badge-success.focus { outline: 0; box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info { color: #fff; background-color: #17a2b8; }

a.badge-info:hover, a.badge-info:focus { color: #fff; background-color: #117a8b; }

a.badge-info:focus, a.badge-info.focus { outline: 0; box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning { color: #212529; background-color: #ffc107; }

a.badge-warning:hover, a.badge-warning:focus { color: #212529; background-color: #d39e00; }

a.badge-warning:focus, a.badge-warning.focus { outline: 0; box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger { color: #fff; background-color: #dc3545; }

a.badge-danger:hover, a.badge-danger:focus { color: #fff; background-color: #bd2130; }

a.badge-danger:focus, a.badge-danger.focus { outline: 0; box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light { color: #212529; background-color: #f8f9fa; }

a.badge-light:hover, a.badge-light:focus { color: #212529; background-color: #dae0e5; }

a.badge-light:focus, a.badge-light.focus { outline: 0; box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark { color: #fff; background-color: #343a40; }

a.badge-dark:hover, a.badge-dark:focus { color: #fff; background-color: #1d2124; }

a.badge-dark:focus, a.badge-dark.focus { outline: 0; box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron { padding: 2rem 1rem; margin-bottom: 2rem; background-color: #e9ecef; border-radius: 0.3rem; }

@media (min-width: 576px) { .jumbotron { padding: 4rem 2rem; } }

.jumbotron-fluid { padding-right: 0; padding-left: 0; border-radius: 0; }

.alert { position: relative; padding: 0.75rem 1.25rem; margin-bottom: 1rem; border: 1px solid transparent; border-radius: 0.25rem; }

.alert-heading { color: inherit; }

.alert-link { font-weight: 700; }

.alert-dismissible { padding-right: 4rem; }

.alert-dismissible .close { position: absolute; top: 0; right: 0; padding: 0.75rem 1.25rem; color: inherit; }

.alert-primary { color: #004085; background-color: #cce5ff; border-color: #b8daff; }

.alert-primary hr { border-top-color: #9fcdff; }

.alert-primary .alert-link { color: #002752; }

.alert-secondary { color: #383d41; background-color: #e2e3e5; border-color: #d6d8db; }

.alert-secondary hr { border-top-color: #c8cbcf; }

.alert-secondary .alert-link { color: #202326; }

.alert-success { color: #155724; background-color: #d4edda; border-color: #c3e6cb; }

.alert-success hr { border-top-color: #b1dfbb; }

.alert-success .alert-link { color: #0b2e13; }

.alert-info { color: #0c5460; background-color: #d1ecf1; border-color: #bee5eb; }

.alert-info hr { border-top-color: #abdde5; }

.alert-info .alert-link { color: #062c33; }

.alert-warning { color: #856404; background-color: #fff3cd; border-color: #ffeeba; }

.alert-warning hr { border-top-color: #ffe8a1; }

.alert-warning .alert-link { color: #533f03; }

.alert-danger { color: #721c24; background-color: #f8d7da; border-color: #f5c6cb; }

.alert-danger hr { border-top-color: #f1b0b7; }

.alert-danger .alert-link { color: #491217; }

.alert-light { color: #818182; background-color: #fefefe; border-color: #fdfdfe; }

.alert-light hr { border-top-color: #ececf6; }

.alert-light .alert-link { color: #686868; }

.alert-dark { color: #1b1e21; background-color: #d6d8d9; border-color: #c6c8ca; }

.alert-dark hr { border-top-color: #b9bbbe; }

.alert-dark .alert-link { color: #040505; }

@keyframes progress-bar-stripes { from { background-position: 1rem 0; }
  to { background-position: 0 0; } }

.progress { display: flex; height: 1rem; overflow: hidden; font-size: 0.75rem; background-color: #e9ecef; border-radius: 0.25rem; }

.progress-bar { display: flex; flex-direction: column; justify-content: center; color: #fff; text-align: center; white-space: nowrap; background-color: #007bff; transition: width 0.6s ease; }

@media (prefers-reduced-motion: reduce) { .progress-bar { transition: none; } }

.progress-bar-striped { background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent); background-size: 1rem 1rem; }

.progress-bar-animated { animation: progress-bar-stripes 1s linear infinite; }

@media (prefers-reduced-motion: reduce) { .progress-bar-animated { animation: none; } }

.media { display: flex; align-items: flex-start; }

.media-body { flex: 1; }

.list-group { display: flex; flex-direction: column; padding-left: 0; margin-bottom: 0; }

.list-group-item-action { width: 100%; color: #495057; text-align: inherit; }

.list-group-item-action:hover, .list-group-item-action:focus { z-index: 1; color: #495057; text-decoration: none; background-color: #f8f9fa; }

.list-group-item-action:active { color: #212529; background-color: #e9ecef; }

.list-group-item { position: relative; display: block; padding: 0.75rem 1.25rem; margin-bottom: -1px; background-color: #fff; border: 1px solid rgba(0, 0, 0, 0.125); }

.list-group-item:first-child { border-top-left-radius: 0.25rem; border-top-right-radius: 0.25rem; }

.list-group-item:last-child { margin-bottom: 0; border-bottom-right-radius: 0.25rem; border-bottom-left-radius: 0.25rem; }

.list-group-item.disabled, .list-group-item:disabled { color: #6c757d; pointer-events: none; background-color: #fff; }

.list-group-item.active { z-index: 2; color: #fff; background-color: #007bff; border-color: #007bff; }

.list-group-horizontal { flex-direction: row; }

.list-group-horizontal .list-group-item { margin-right: -1px; margin-bottom: 0; }

.list-group-horizontal .list-group-item:first-child { border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem; border-top-right-radius: 0; }

.list-group-horizontal .list-group-item:last-child { margin-right: 0; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem; border-bottom-left-radius: 0; }

@media (min-width: 576px) { .list-group-horizontal-sm { flex-direction: row; }
  .list-group-horizontal-sm .list-group-item { margin-right: -1px; margin-bottom: 0; }
  .list-group-horizontal-sm .list-group-item:first-child { border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem; border-top-right-radius: 0; }
  .list-group-horizontal-sm .list-group-item:last-child { margin-right: 0; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem; border-bottom-left-radius: 0; } }

@media (min-width: 768px) { .list-group-horizontal-md { flex-direction: row; }
  .list-group-horizontal-md .list-group-item { margin-right: -1px; margin-bottom: 0; }
  .list-group-horizontal-md .list-group-item:first-child { border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem; border-top-right-radius: 0; }
  .list-group-horizontal-md .list-group-item:last-child { margin-right: 0; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem; border-bottom-left-radius: 0; } }

@media (min-width: 992px) { .list-group-horizontal-lg { flex-direction: row; }
  .list-group-horizontal-lg .list-group-item { margin-right: -1px; margin-bottom: 0; }
  .list-group-horizontal-lg .list-group-item:first-child { border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem; border-top-right-radius: 0; }
  .list-group-horizontal-lg .list-group-item:last-child { margin-right: 0; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem; border-bottom-left-radius: 0; } }

@media (min-width: 1200px) { .list-group-horizontal-xl { flex-direction: row; }
  .list-group-horizontal-xl .list-group-item { margin-right: -1px; margin-bottom: 0; }
  .list-group-horizontal-xl .list-group-item:first-child { border-top-left-radius: 0.25rem; border-bottom-left-radius: 0.25rem; border-top-right-radius: 0; }
  .list-group-horizontal-xl .list-group-item:last-child { margin-right: 0; border-top-right-radius: 0.25rem; border-bottom-right-radius: 0.25rem; border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item { border-right: 0; border-left: 0; border-radius: 0; }

.list-group-flush .list-group-item:last-child { margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child { border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child { margin-bottom: 0; border-bottom: 0; }

.list-group-item-primary { color: #004085; background-color: #b8daff; }

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus { color: #004085; background-color: #9fcdff; }

.list-group-item-primary.list-group-item-action.active { color: #fff; background-color: #004085; border-color: #004085; }

.list-group-item-secondary { color: #383d41; background-color: #d6d8db; }

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus { color: #383d41; background-color: #c8cbcf; }

.list-group-item-secondary.list-group-item-action.active { color: #fff; background-color: #383d41; border-color: #383d41; }

.list-group-item-success { color: #155724; background-color: #c3e6cb; }

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus { color: #155724; background-color: #b1dfbb; }

.list-group-item-success.list-group-item-action.active { color: #fff; background-color: #155724; border-color: #155724; }

.list-group-item-info { color: #0c5460; background-color: #bee5eb; }

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus { color: #0c5460; background-color: #abdde5; }

.list-group-item-info.list-group-item-action.active { color: #fff; background-color: #0c5460; border-color: #0c5460; }

.list-group-item-warning { color: #856404; background-color: #ffeeba; }

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus { color: #856404; background-color: #ffe8a1; }

.list-group-item-warning.list-group-item-action.active { color: #fff; background-color: #856404; border-color: #856404; }

.list-group-item-danger { color: #721c24; background-color: #f5c6cb; }

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus { color: #721c24; background-color: #f1b0b7; }

.list-group-item-danger.list-group-item-action.active { color: #fff; background-color: #721c24; border-color: #721c24; }

.list-group-item-light { color: #818182; background-color: #fdfdfe; }

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus { color: #818182; background-color: #ececf6; }

.list-group-item-light.list-group-item-action.active { color: #fff; background-color: #818182; border-color: #818182; }

.list-group-item-dark { color: #1b1e21; background-color: #c6c8ca; }

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus { color: #1b1e21; background-color: #b9bbbe; }

.list-group-item-dark.list-group-item-action.active { color: #fff; background-color: #1b1e21; border-color: #1b1e21; }

.close { float: right; font-size: 1.5rem; font-weight: 700; line-height: 1; color: #000; text-shadow: 0 1px 0 #fff; opacity: .5; }

.close:hover { color: #000; text-decoration: none; }

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus { opacity: .75; }

button.close { padding: 0; background-color: transparent; border: 0; appearance: none; }

a.close.disabled { pointer-events: none; }

.toast { max-width: 350px; overflow: hidden; font-size: 0.875rem; background-color: rgba(255, 255, 255, 0.85); background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.1); box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1); backdrop-filter: blur(10px); opacity: 0; border-radius: 0.25rem; }

.toast:not(:last-child) { margin-bottom: 0.75rem; }

.toast.showing { opacity: 1; }

.toast.show { display: block; opacity: 1; }

.toast.hide { display: none; }

.toast-header { display: flex; align-items: center; padding: 0.25rem 0.75rem; color: #6c757d; background-color: rgba(255, 255, 255, 0.85); background-clip: padding-box; border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body { padding: 0.75rem; }

.modal-open { overflow: hidden; }

.modal-open .modal { overflow-x: hidden; overflow-y: auto; }

.modal { position: fixed; top: 0; left: 0; z-index: 1050; display: none; width: 100%; height: 100%; overflow: hidden; outline: 0; }

.modal-dialog { position: relative; width: auto; margin: 0.5rem; pointer-events: none; }

.modal.fade .modal-dialog { transition: transform 0.3s ease-out; transform: translate(0, -50px); }

@media (prefers-reduced-motion: reduce) { .modal.fade .modal-dialog { transition: none; } }

.modal.show .modal-dialog { transform: none; }

.modal-dialog-scrollable { display: flex; max-height: calc(100% - 1rem); }

.modal-dialog-scrollable .modal-content { max-height: calc(100vh - 1rem); overflow: hidden; }

.modal-dialog-scrollable .modal-header, .modal-dialog-scrollable .modal-footer { flex-shrink: 0; }

.modal-dialog-scrollable .modal-body { overflow-y: auto; }

.modal-dialog-centered { display: flex; align-items: center; min-height: calc(100% - 1rem); }

.modal-dialog-centered::before { display: block; height: calc(100vh - 1rem); content: ""; }

.modal-dialog-centered.modal-dialog-scrollable { flex-direction: column; justify-content: center; height: 100%; }

.modal-dialog-centered.modal-dialog-scrollable .modal-content { max-height: none; }

.modal-dialog-centered.modal-dialog-scrollable::before { content: none; }

.modal-content { position: relative; display: flex; flex-direction: column; width: 100%; pointer-events: auto; background-color: #fff; background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.2); border-radius: 0.3rem; outline: 0; }

.modal-backdrop { position: fixed; top: 0; left: 0; z-index: 1040; width: 100vw; height: 100vh; background-color: #000; }

.modal-backdrop.fade { opacity: 0; }

.modal-backdrop.show { opacity: 0.5; }

.modal-header { display: flex; align-items: flex-start; justify-content: space-between; padding: 1rem 1rem; border-bottom: 1px solid #dee2e6; border-top-left-radius: 0.3rem; border-top-right-radius: 0.3rem; }

.modal-header .close { padding: 1rem 1rem; margin: -1rem -1rem -1rem auto; }

.modal-title { margin-bottom: 0; line-height: 1.5; }

.modal-body { position: relative; flex: 1 1 auto; padding: 1rem; }

.modal-footer { display: flex; align-items: center; justify-content: flex-end; padding: 1rem; border-top: 1px solid #dee2e6; border-bottom-right-radius: 0.3rem; border-bottom-left-radius: 0.3rem; }

.modal-footer > :not(:first-child) { margin-left: .25rem; }

.modal-footer > :not(:last-child) { margin-right: .25rem; }

.modal-scrollbar-measure { position: absolute; top: -9999px; width: 50px; height: 50px; overflow: scroll; }

@media (min-width: 576px) { .modal-dialog { max-width: 500px; margin: 1.75rem auto; }
  .modal-dialog-scrollable { max-height: calc(100% - 3.5rem); }
  .modal-dialog-scrollable .modal-content { max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered { min-height: calc(100% - 3.5rem); }
  .modal-dialog-centered::before { height: calc(100vh - 3.5rem); }
  .modal-sm { max-width: 300px; } }

@media (min-width: 992px) { .modal-lg, .modal-xl { max-width: 800px; } }

@media (min-width: 1200px) { .modal-xl { max-width: 1140px; } }

.tooltip { position: absolute; z-index: 1070; display: block; margin: 0; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-style: normal; font-weight: 400; line-height: 1.5; text-align: left; text-align: start; text-decoration: none; text-shadow: none; text-transform: none; letter-spacing: normal; word-break: normal; word-spacing: normal; white-space: normal; line-break: auto; font-size: 0.875rem; word-wrap: break-word; opacity: 0; }

.tooltip.show { opacity: 0.9; }

.tooltip .arrow { position: absolute; display: block; width: 0.8rem; height: 0.4rem; }

.tooltip .arrow::before { position: absolute; content: ""; border-color: transparent; border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] { padding: 0.4rem 0; }

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow { bottom: 0; }

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before { top: 0; border-width: 0.4rem 0.4rem 0; border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] { padding: 0 0.4rem; }

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow { left: 0; width: 0.4rem; height: 0.8rem; }

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before { right: 0; border-width: 0.4rem 0.4rem 0.4rem 0; border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] { padding: 0.4rem 0; }

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow { top: 0; }

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before { bottom: 0; border-width: 0 0.4rem 0.4rem; border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] { padding: 0 0.4rem; }

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow { right: 0; width: 0.4rem; height: 0.8rem; }

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before { left: 0; border-width: 0.4rem 0 0.4rem 0.4rem; border-left-color: #000; }

.tooltip-inner { max-width: 200px; padding: 0.25rem 0.5rem; color: #fff; text-align: center; background-color: #000; border-radius: 0.25rem; }

.popover { position: absolute; top: 0; left: 0; z-index: 1060; display: block; max-width: 276px; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; font-style: normal; font-weight: 400; line-height: 1.5; text-align: left; text-align: start; text-decoration: none; text-shadow: none; text-transform: none; letter-spacing: normal; word-break: normal; word-spacing: normal; white-space: normal; line-break: auto; font-size: 0.875rem; word-wrap: break-word; background-color: #fff; background-clip: padding-box; border: 1px solid rgba(0, 0, 0, 0.2); border-radius: 0.3rem; }

.popover .arrow { position: absolute; display: block; width: 1rem; height: 0.5rem; margin: 0 0.3rem; }

.popover .arrow::before, .popover .arrow::after { position: absolute; display: block; content: ""; border-color: transparent; border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] { margin-bottom: 0.5rem; }

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow { bottom: calc((0.5rem + 1px) * -1); }

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before { bottom: 0; border-width: 0.5rem 0.5rem 0; border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after { bottom: 1px; border-width: 0.5rem 0.5rem 0; border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] { margin-left: 0.5rem; }

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow { left: calc((0.5rem + 1px) * -1); width: 0.5rem; height: 1rem; margin: 0.3rem 0; }

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before { left: 0; border-width: 0.5rem 0.5rem 0.5rem 0; border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after { left: 1px; border-width: 0.5rem 0.5rem 0.5rem 0; border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] { margin-top: 0.5rem; }

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow { top: calc((0.5rem + 1px) * -1); }

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before { top: 0; border-width: 0 0.5rem 0.5rem 0.5rem; border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after { top: 1px; border-width: 0 0.5rem 0.5rem 0.5rem; border-bottom-color: #fff; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before { position: absolute; top: 0; left: 50%; display: block; width: 1rem; margin-left: -0.5rem; content: ""; border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] { margin-right: 0.5rem; }

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow { right: calc((0.5rem + 1px) * -1); width: 0.5rem; height: 1rem; margin: 0.3rem 0; }

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before { right: 0; border-width: 0.5rem 0 0.5rem 0.5rem; border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after { right: 1px; border-width: 0.5rem 0 0.5rem 0.5rem; border-left-color: #fff; }

.popover-header { padding: 0.5rem 0.75rem; margin-bottom: 0; font-size: 1rem; background-color: #f7f7f7; border-bottom: 1px solid #ebebeb; border-top-left-radius: calc(0.3rem - 1px); border-top-right-radius: calc(0.3rem - 1px); }

.popover-header:empty { display: none; }

.popover-body { padding: 0.5rem 0.75rem; color: #212529; }

.carousel { position: relative; }

.carousel.pointer-event { touch-action: pan-y; }

.carousel-inner { position: relative; width: 100%; overflow: hidden; }

.carousel-inner::after { display: block; clear: both; content: ""; }

.carousel-item { position: relative; display: none; float: left; width: 100%; margin-right: -100%; backface-visibility: hidden; transition: transform 0.6s ease-in-out; }

@media (prefers-reduced-motion: reduce) { .carousel-item { transition: none; } }

.carousel-item.active, .carousel-item-next, .carousel-item-prev { display: block; }

.carousel-item-next:not(.carousel-item-left), .active.carousel-item-right { transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left { transform: translateX(-100%); }

.carousel-fade .carousel-item { opacity: 0; transition-property: opacity; transform: none; }

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right { z-index: 1; opacity: 1; }

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right { z-index: 0; opacity: 0; transition: 0s 0.6s opacity; }

@media (prefers-reduced-motion: reduce) { .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right { transition: none; } }

.carousel-control-prev, .carousel-control-next { position: absolute; top: 0; bottom: 0; z-index: 1; display: flex; align-items: center; justify-content: center; width: 15%; color: #fff; text-align: center; opacity: 0.5; transition: opacity 0.15s ease; }

@media (prefers-reduced-motion: reduce) { .carousel-control-prev, .carousel-control-next { transition: none; } }

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus { color: #fff; text-decoration: none; outline: 0; opacity: 0.9; }

.carousel-control-prev { left: 0; }

.carousel-control-next { right: 0; }

.carousel-control-prev-icon, .carousel-control-next-icon { display: inline-block; width: 20px; height: 20px; background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators { position: absolute; right: 0; bottom: 0; left: 0; z-index: 15; display: flex; justify-content: center; padding-left: 0; margin-right: 15%; margin-left: 15%; list-style: none; }

.carousel-indicators li { box-sizing: content-box; flex: 0 1 auto; width: 30px; height: 3px; margin-right: 3px; margin-left: 3px; text-indent: -999px; cursor: pointer; background-color: #fff; background-clip: padding-box; border-top: 10px solid transparent; border-bottom: 10px solid transparent; opacity: .5; transition: opacity 0.6s ease; }

@media (prefers-reduced-motion: reduce) { .carousel-indicators li { transition: none; } }

.carousel-indicators .active { opacity: 1; }

.carousel-caption { position: absolute; right: 15%; bottom: 20px; left: 15%; z-index: 10; padding-top: 20px; padding-bottom: 20px; color: #fff; text-align: center; }

@keyframes spinner-border { to { transform: rotate(360deg); } }

.spinner-border { display: inline-block; width: 2rem; height: 2rem; vertical-align: text-bottom; border: 0.25em solid currentColor; border-right-color: transparent; border-radius: 50%; animation: spinner-border .75s linear infinite; }

.spinner-border-sm { width: 1rem; height: 1rem; border-width: 0.2em; }

@keyframes spinner-grow { 0% { transform: scale(0); }
  50% { opacity: 1; } }

.spinner-grow { display: inline-block; width: 2rem; height: 2rem; vertical-align: text-bottom; background-color: currentColor; border-radius: 50%; opacity: 0; animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm { width: 1rem; height: 1rem; }

.align-baseline { vertical-align: baseline !important; }

.align-top { vertical-align: top !important; }

.align-middle { vertical-align: middle !important; }

.align-bottom { vertical-align: bottom !important; }

.align-text-bottom { vertical-align: text-bottom !important; }

.align-text-top { vertical-align: text-top !important; }

.bg-primary { background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus { background-color: #0062cc !important; }

.bg-secondary { background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus, button.bg-secondary:hover, button.bg-secondary:focus { background-color: #545b62 !important; }

.bg-success { background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus, button.bg-success:hover, button.bg-success:focus { background-color: #1e7e34 !important; }

.bg-info { background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus, button.bg-info:hover, button.bg-info:focus { background-color: #117a8b !important; }

.bg-warning { background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus, button.bg-warning:hover, button.bg-warning:focus { background-color: #d39e00 !important; }

.bg-danger { background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus, button.bg-danger:hover, button.bg-danger:focus { background-color: #bd2130 !important; }

.bg-light { background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus, button.bg-light:hover, button.bg-light:focus { background-color: #dae0e5 !important; }

.bg-dark { background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus, button.bg-dark:hover, button.bg-dark:focus { background-color: #1d2124 !important; }

.bg-white { background-color: #fff !important; }

.bg-transparent { background-color: transparent !important; }

.border { border: 1px solid #dee2e6 !important; }

.border-top { border-top: 1px solid #dee2e6 !important; }

.border-right { border-right: 1px solid #dee2e6 !important; }

.border-bottom { border-bottom: 1px solid #dee2e6 !important; }

.border-left { border-left: 1px solid #dee2e6 !important; }

.border-0 { border: 0 !important; }

.border-top-0 { border-top: 0 !important; }

.border-right-0 { border-right: 0 !important; }

.border-bottom-0 { border-bottom: 0 !important; }

.border-left-0 { border-left: 0 !important; }

.border-primary { border-color: #007bff !important; }

.border-secondary { border-color: #6c757d !important; }

.border-success { border-color: #28a745 !important; }

.border-info { border-color: #17a2b8 !important; }

.border-warning { border-color: #ffc107 !important; }

.border-danger { border-color: #dc3545 !important; }

.border-light { border-color: #f8f9fa !important; }

.border-dark { border-color: #343a40 !important; }

.border-white { border-color: #fff !important; }

.rounded-sm { border-radius: 0.2rem !important; }

.rounded { border-radius: 0.25rem !important; }

.rounded-top { border-top-left-radius: 0.25rem !important; border-top-right-radius: 0.25rem !important; }

.rounded-right { border-top-right-radius: 0.25rem !important; border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom { border-bottom-right-radius: 0.25rem !important; border-bottom-left-radius: 0.25rem !important; }

.rounded-left { border-top-left-radius: 0.25rem !important; border-bottom-left-radius: 0.25rem !important; }

.rounded-lg { border-radius: 0.3rem !important; }

.rounded-circle { border-radius: 50% !important; }

.rounded-pill { border-radius: 50rem !important; }

.rounded-0 { border-radius: 0 !important; }

.clearfix::after { display: block; clear: both; content: ""; }

.d-none { display: none !important; }

.d-inline { display: inline !important; }

.d-inline-block { display: inline-block !important; }

.d-block { display: block !important; }

.d-table { display: table !important; }

.d-table-row { display: table-row !important; }

.d-table-cell { display: table-cell !important; }

.d-flex { display: flex !important; }

.d-inline-flex { display: inline-flex !important; }

@media (min-width: 576px) { .d-sm-none { display: none !important; }
  .d-sm-inline { display: inline !important; }
  .d-sm-inline-block { display: inline-block !important; }
  .d-sm-block { display: block !important; }
  .d-sm-table { display: table !important; }
  .d-sm-table-row { display: table-row !important; }
  .d-sm-table-cell { display: table-cell !important; }
  .d-sm-flex { display: flex !important; }
  .d-sm-inline-flex { display: inline-flex !important; } }

@media (min-width: 768px) { .d-md-none { display: none !important; }
  .d-md-inline { display: inline !important; }
  .d-md-inline-block { display: inline-block !important; }
  .d-md-block { display: block !important; }
  .d-md-table { display: table !important; }
  .d-md-table-row { display: table-row !important; }
  .d-md-table-cell { display: table-cell !important; }
  .d-md-flex { display: flex !important; }
  .d-md-inline-flex { display: inline-flex !important; } }

@media (min-width: 992px) { .d-lg-none { display: none !important; }
  .d-lg-inline { display: inline !important; }
  .d-lg-inline-block { display: inline-block !important; }
  .d-lg-block { display: block !important; }
  .d-lg-table { display: table !important; }
  .d-lg-table-row { display: table-row !important; }
  .d-lg-table-cell { display: table-cell !important; }
  .d-lg-flex { display: flex !important; }
  .d-lg-inline-flex { display: inline-flex !important; } }

@media (min-width: 1200px) { .d-xl-none { display: none !important; }
  .d-xl-inline { display: inline !important; }
  .d-xl-inline-block { display: inline-block !important; }
  .d-xl-block { display: block !important; }
  .d-xl-table { display: table !important; }
  .d-xl-table-row { display: table-row !important; }
  .d-xl-table-cell { display: table-cell !important; }
  .d-xl-flex { display: flex !important; }
  .d-xl-inline-flex { display: inline-flex !important; } }

@media print { .d-print-none { display: none !important; }
  .d-print-inline { display: inline !important; }
  .d-print-inline-block { display: inline-block !important; }
  .d-print-block { display: block !important; }
  .d-print-table { display: table !important; }
  .d-print-table-row { display: table-row !important; }
  .d-print-table-cell { display: table-cell !important; }
  .d-print-flex { display: flex !important; }
  .d-print-inline-flex { display: inline-flex !important; } }

.embed-responsive { position: relative; display: block; width: 100%; padding: 0; overflow: hidden; }

.embed-responsive::before { display: block; content: ""; }

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video { position: absolute; top: 0; bottom: 0; left: 0; width: 100%; height: 100%; border: 0; }

.embed-responsive-21by9::before { padding-top: 42.85714%; }

.embed-responsive-16by9::before { padding-top: 56.25%; }

.embed-responsive-4by3::before { padding-top: 75%; }

.embed-responsive-1by1::before { padding-top: 100%; }

.flex-row { flex-direction: row !important; }

.flex-column { flex-direction: column !important; }

.flex-row-reverse { flex-direction: row-reverse !important; }

.flex-column-reverse { flex-direction: column-reverse !important; }

.flex-wrap { flex-wrap: wrap !important; }

.flex-nowrap { flex-wrap: nowrap !important; }

.flex-wrap-reverse { flex-wrap: wrap-reverse !important; }

.flex-fill { flex: 1 1 auto !important; }

.flex-grow-0 { flex-grow: 0 !important; }

.flex-grow-1 { flex-grow: 1 !important; }

.flex-shrink-0 { flex-shrink: 0 !important; }

.flex-shrink-1 { flex-shrink: 1 !important; }

.justify-content-start { justify-content: flex-start !important; }

.justify-content-end { justify-content: flex-end !important; }

.justify-content-center { justify-content: center !important; }

.justify-content-between { justify-content: space-between !important; }

.justify-content-around { justify-content: space-around !important; }

.align-items-start { align-items: flex-start !important; }

.align-items-end { align-items: flex-end !important; }

.align-items-center { align-items: center !important; }

.align-items-baseline { align-items: baseline !important; }

.align-items-stretch { align-items: stretch !important; }

.align-content-start { align-content: flex-start !important; }

.align-content-end { align-content: flex-end !important; }

.align-content-center { align-content: center !important; }

.align-content-between { align-content: space-between !important; }

.align-content-around { align-content: space-around !important; }

.align-content-stretch { align-content: stretch !important; }

.align-self-auto { align-self: auto !important; }

.align-self-start { align-self: flex-start !important; }

.align-self-end { align-self: flex-end !important; }

.align-self-center { align-self: center !important; }

.align-self-baseline { align-self: baseline !important; }

.align-self-stretch { align-self: stretch !important; }

@media (min-width: 576px) { .flex-sm-row { flex-direction: row !important; }
  .flex-sm-column { flex-direction: column !important; }
  .flex-sm-row-reverse { flex-direction: row-reverse !important; }
  .flex-sm-column-reverse { flex-direction: column-reverse !important; }
  .flex-sm-wrap { flex-wrap: wrap !important; }
  .flex-sm-nowrap { flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-sm-fill { flex: 1 1 auto !important; }
  .flex-sm-grow-0 { flex-grow: 0 !important; }
  .flex-sm-grow-1 { flex-grow: 1 !important; }
  .flex-sm-shrink-0 { flex-shrink: 0 !important; }
  .flex-sm-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-sm-start { justify-content: flex-start !important; }
  .justify-content-sm-end { justify-content: flex-end !important; }
  .justify-content-sm-center { justify-content: center !important; }
  .justify-content-sm-between { justify-content: space-between !important; }
  .justify-content-sm-around { justify-content: space-around !important; }
  .align-items-sm-start { align-items: flex-start !important; }
  .align-items-sm-end { align-items: flex-end !important; }
  .align-items-sm-center { align-items: center !important; }
  .align-items-sm-baseline { align-items: baseline !important; }
  .align-items-sm-stretch { align-items: stretch !important; }
  .align-content-sm-start { align-content: flex-start !important; }
  .align-content-sm-end { align-content: flex-end !important; }
  .align-content-sm-center { align-content: center !important; }
  .align-content-sm-between { align-content: space-between !important; }
  .align-content-sm-around { align-content: space-around !important; }
  .align-content-sm-stretch { align-content: stretch !important; }
  .align-self-sm-auto { align-self: auto !important; }
  .align-self-sm-start { align-self: flex-start !important; }
  .align-self-sm-end { align-self: flex-end !important; }
  .align-self-sm-center { align-self: center !important; }
  .align-self-sm-baseline { align-self: baseline !important; }
  .align-self-sm-stretch { align-self: stretch !important; } }

@media (min-width: 768px) { .flex-md-row { flex-direction: row !important; }
  .flex-md-column { flex-direction: column !important; }
  .flex-md-row-reverse { flex-direction: row-reverse !important; }
  .flex-md-column-reverse { flex-direction: column-reverse !important; }
  .flex-md-wrap { flex-wrap: wrap !important; }
  .flex-md-nowrap { flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-md-fill { flex: 1 1 auto !important; }
  .flex-md-grow-0 { flex-grow: 0 !important; }
  .flex-md-grow-1 { flex-grow: 1 !important; }
  .flex-md-shrink-0 { flex-shrink: 0 !important; }
  .flex-md-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-md-start { justify-content: flex-start !important; }
  .justify-content-md-end { justify-content: flex-end !important; }
  .justify-content-md-center { justify-content: center !important; }
  .justify-content-md-between { justify-content: space-between !important; }
  .justify-content-md-around { justify-content: space-around !important; }
  .align-items-md-start { align-items: flex-start !important; }
  .align-items-md-end { align-items: flex-end !important; }
  .align-items-md-center { align-items: center !important; }
  .align-items-md-baseline { align-items: baseline !important; }
  .align-items-md-stretch { align-items: stretch !important; }
  .align-content-md-start { align-content: flex-start !important; }
  .align-content-md-end { align-content: flex-end !important; }
  .align-content-md-center { align-content: center !important; }
  .align-content-md-between { align-content: space-between !important; }
  .align-content-md-around { align-content: space-around !important; }
  .align-content-md-stretch { align-content: stretch !important; }
  .align-self-md-auto { align-self: auto !important; }
  .align-self-md-start { align-self: flex-start !important; }
  .align-self-md-end { align-self: flex-end !important; }
  .align-self-md-center { align-self: center !important; }
  .align-self-md-baseline { align-self: baseline !important; }
  .align-self-md-stretch { align-self: stretch !important; } }

@media (min-width: 992px) { .flex-lg-row { flex-direction: row !important; }
  .flex-lg-column { flex-direction: column !important; }
  .flex-lg-row-reverse { flex-direction: row-reverse !important; }
  .flex-lg-column-reverse { flex-direction: column-reverse !important; }
  .flex-lg-wrap { flex-wrap: wrap !important; }
  .flex-lg-nowrap { flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-lg-fill { flex: 1 1 auto !important; }
  .flex-lg-grow-0 { flex-grow: 0 !important; }
  .flex-lg-grow-1 { flex-grow: 1 !important; }
  .flex-lg-shrink-0 { flex-shrink: 0 !important; }
  .flex-lg-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-lg-start { justify-content: flex-start !important; }
  .justify-content-lg-end { justify-content: flex-end !important; }
  .justify-content-lg-center { justify-content: center !important; }
  .justify-content-lg-between { justify-content: space-between !important; }
  .justify-content-lg-around { justify-content: space-around !important; }
  .align-items-lg-start { align-items: flex-start !important; }
  .align-items-lg-end { align-items: flex-end !important; }
  .align-items-lg-center { align-items: center !important; }
  .align-items-lg-baseline { align-items: baseline !important; }
  .align-items-lg-stretch { align-items: stretch !important; }
  .align-content-lg-start { align-content: flex-start !important; }
  .align-content-lg-end { align-content: flex-end !important; }
  .align-content-lg-center { align-content: center !important; }
  .align-content-lg-between { align-content: space-between !important; }
  .align-content-lg-around { align-content: space-around !important; }
  .align-content-lg-stretch { align-content: stretch !important; }
  .align-self-lg-auto { align-self: auto !important; }
  .align-self-lg-start { align-self: flex-start !important; }
  .align-self-lg-end { align-self: flex-end !important; }
  .align-self-lg-center { align-self: center !important; }
  .align-self-lg-baseline { align-self: baseline !important; }
  .align-self-lg-stretch { align-self: stretch !important; } }

@media (min-width: 1200px) { .flex-xl-row { flex-direction: row !important; }
  .flex-xl-column { flex-direction: column !important; }
  .flex-xl-row-reverse { flex-direction: row-reverse !important; }
  .flex-xl-column-reverse { flex-direction: column-reverse !important; }
  .flex-xl-wrap { flex-wrap: wrap !important; }
  .flex-xl-nowrap { flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse { flex-wrap: wrap-reverse !important; }
  .flex-xl-fill { flex: 1 1 auto !important; }
  .flex-xl-grow-0 { flex-grow: 0 !important; }
  .flex-xl-grow-1 { flex-grow: 1 !important; }
  .flex-xl-shrink-0 { flex-shrink: 0 !important; }
  .flex-xl-shrink-1 { flex-shrink: 1 !important; }
  .justify-content-xl-start { justify-content: flex-start !important; }
  .justify-content-xl-end { justify-content: flex-end !important; }
  .justify-content-xl-center { justify-content: center !important; }
  .justify-content-xl-between { justify-content: space-between !important; }
  .justify-content-xl-around { justify-content: space-around !important; }
  .align-items-xl-start { align-items: flex-start !important; }
  .align-items-xl-end { align-items: flex-end !important; }
  .align-items-xl-center { align-items: center !important; }
  .align-items-xl-baseline { align-items: baseline !important; }
  .align-items-xl-stretch { align-items: stretch !important; }
  .align-content-xl-start { align-content: flex-start !important; }
  .align-content-xl-end { align-content: flex-end !important; }
  .align-content-xl-center { align-content: center !important; }
  .align-content-xl-between { align-content: space-between !important; }
  .align-content-xl-around { align-content: space-around !important; }
  .align-content-xl-stretch { align-content: stretch !important; }
  .align-self-xl-auto { align-self: auto !important; }
  .align-self-xl-start { align-self: flex-start !important; }
  .align-self-xl-end { align-self: flex-end !important; }
  .align-self-xl-center { align-self: center !important; }
  .align-self-xl-baseline { align-self: baseline !important; }
  .align-self-xl-stretch { align-self: stretch !important; } }

.float-left { float: left !important; }

.float-right { float: right !important; }

.float-none { float: none !important; }

@media (min-width: 576px) { .float-sm-left { float: left !important; }
  .float-sm-right { float: right !important; }
  .float-sm-none { float: none !important; } }

@media (min-width: 768px) { .float-md-left { float: left !important; }
  .float-md-right { float: right !important; }
  .float-md-none { float: none !important; } }

@media (min-width: 992px) { .float-lg-left { float: left !important; }
  .float-lg-right { float: right !important; }
  .float-lg-none { float: none !important; } }

@media (min-width: 1200px) { .float-xl-left { float: left !important; }
  .float-xl-right { float: right !important; }
  .float-xl-none { float: none !important; } }

.overflow-auto { overflow: auto !important; }

.overflow-hidden { overflow: hidden !important; }

.position-static { position: static !important; }

.position-relative { position: relative !important; }

.position-absolute { position: absolute !important; }

.position-fixed { position: fixed !important; }

.position-sticky { position: sticky !important; }

.fixed-top { position: fixed; top: 0; right: 0; left: 0; z-index: 1030; }

.fixed-bottom { position: fixed; right: 0; bottom: 0; left: 0; z-index: 1030; }

@supports (position: sticky) { .sticky-top { position: sticky; top: 0; z-index: 1020; } }

.sr-only { position: absolute; width: 1px; height: 1px; padding: 0; overflow: hidden; clip: rect(0, 0, 0, 0); white-space: nowrap; border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus { position: static; width: auto; height: auto; overflow: visible; clip: auto; white-space: normal; }

.shadow-sm { box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow { box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg { box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none { box-shadow: none !important; }

.w-25 { width: 25% !important; }

.w-50 { width: 50% !important; }

.w-75 { width: 75% !important; }

.w-100 { width: 100% !important; }

.w-auto { width: auto !important; }

.h-25 { height: 25% !important; }

.h-50 { height: 50% !important; }

.h-75 { height: 75% !important; }

.h-100 { height: 100% !important; }

.h-auto { height: auto !important; }

.mw-100 { max-width: 100% !important; }

.mh-100 { max-height: 100% !important; }

.min-vw-100 { min-width: 100vw !important; }

.min-vh-100 { min-height: 100vh !important; }

.vw-100 { width: 100vw !important; }

.vh-100 { height: 100vh !important; }

.stretched-link::after { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 1; pointer-events: auto; content: ""; background-color: rgba(0, 0, 0, 0); }

.m-0 { margin: 0 !important; }

.mt-0, .my-0 { margin-top: 0 !important; }

.mr-0, .mx-0 { margin-right: 0 !important; }

.mb-0, .my-0 { margin-bottom: 0 !important; }

.ml-0, .mx-0 { margin-left: 0 !important; }

.m-1 { margin: 0.25rem !important; }

.mt-1, .my-1 { margin-top: 0.25rem !important; }

.mr-1, .mx-1 { margin-right: 0.25rem !important; }

.mb-1, .my-1 { margin-bottom: 0.25rem !important; }

.ml-1, .mx-1 { margin-left: 0.25rem !important; }

.m-2 { margin: 0.5rem !important; }

.mt-2, .my-2 { margin-top: 0.5rem !important; }

.mr-2, .mx-2 { margin-right: 0.5rem !important; }

.mb-2, .my-2 { margin-bottom: 0.5rem !important; }

.ml-2, .mx-2 { margin-left: 0.5rem !important; }

.m-3 { margin: 1rem !important; }

.mt-3, .my-3 { margin-top: 1rem !important; }

.mr-3, .mx-3 { margin-right: 1rem !important; }

.mb-3, .my-3 { margin-bottom: 1rem !important; }

.ml-3, .mx-3 { margin-left: 1rem !important; }

.m-4 { margin: 1.5rem !important; }

.mt-4, .my-4 { margin-top: 1.5rem !important; }

.mr-4, .mx-4 { margin-right: 1.5rem !important; }

.mb-4, .my-4 { margin-bottom: 1.5rem !important; }

.ml-4, .mx-4 { margin-left: 1.5rem !important; }

.m-5 { margin: 3rem !important; }

.mt-5, .my-5 { margin-top: 3rem !important; }

.mr-5, .mx-5 { margin-right: 3rem !important; }

.mb-5, .my-5 { margin-bottom: 3rem !important; }

.ml-5, .mx-5 { margin-left: 3rem !important; }

.p-0 { padding: 0 !important; }

.pt-0, .py-0 { padding-top: 0 !important; }

.pr-0, .px-0 { padding-right: 0 !important; }

.pb-0, .py-0 { padding-bottom: 0 !important; }

.pl-0, .px-0 { padding-left: 0 !important; }

.p-1 { padding: 0.25rem !important; }

.pt-1, .py-1 { padding-top: 0.25rem !important; }

.pr-1, .px-1 { padding-right: 0.25rem !important; }

.pb-1, .py-1 { padding-bottom: 0.25rem !important; }

.pl-1, .px-1 { padding-left: 0.25rem !important; }

.p-2 { padding: 0.5rem !important; }

.pt-2, .py-2 { padding-top: 0.5rem !important; }

.pr-2, .px-2 { padding-right: 0.5rem !important; }

.pb-2, .py-2 { padding-bottom: 0.5rem !important; }

.pl-2, .px-2 { padding-left: 0.5rem !important; }

.p-3 { padding: 1rem !important; }

.pt-3, .py-3 { padding-top: 1rem !important; }

.pr-3, .px-3 { padding-right: 1rem !important; }

.pb-3, .py-3 { padding-bottom: 1rem !important; }

.pl-3, .px-3 { padding-left: 1rem !important; }

.p-4 { padding: 1.5rem !important; }

.pt-4, .py-4 { padding-top: 1.5rem !important; }

.pr-4, .px-4 { padding-right: 1.5rem !important; }

.pb-4, .py-4 { padding-bottom: 1.5rem !important; }

.pl-4, .px-4 { padding-left: 1.5rem !important; }

.p-5 { padding: 3rem !important; }

.pt-5, .py-5 { padding-top: 3rem !important; }

.pr-5, .px-5 { padding-right: 3rem !important; }

.pb-5, .py-5 { padding-bottom: 3rem !important; }

.pl-5, .px-5 { padding-left: 3rem !important; }

.m-n1 { margin: -0.25rem !important; }

.mt-n1, .my-n1 { margin-top: -0.25rem !important; }

.mr-n1, .mx-n1 { margin-right: -0.25rem !important; }

.mb-n1, .my-n1 { margin-bottom: -0.25rem !important; }

.ml-n1, .mx-n1 { margin-left: -0.25rem !important; }

.m-n2 { margin: -0.5rem !important; }

.mt-n2, .my-n2 { margin-top: -0.5rem !important; }

.mr-n2, .mx-n2 { margin-right: -0.5rem !important; }

.mb-n2, .my-n2 { margin-bottom: -0.5rem !important; }

.ml-n2, .mx-n2 { margin-left: -0.5rem !important; }

.m-n3 { margin: -1rem !important; }

.mt-n3, .my-n3 { margin-top: -1rem !important; }

.mr-n3, .mx-n3 { margin-right: -1rem !important; }

.mb-n3, .my-n3 { margin-bottom: -1rem !important; }

.ml-n3, .mx-n3 { margin-left: -1rem !important; }

.m-n4 { margin: -1.5rem !important; }

.mt-n4, .my-n4 { margin-top: -1.5rem !important; }

.mr-n4, .mx-n4 { margin-right: -1.5rem !important; }

.mb-n4, .my-n4 { margin-bottom: -1.5rem !important; }

.ml-n4, .mx-n4 { margin-left: -1.5rem !important; }

.m-n5 { margin: -3rem !important; }

.mt-n5, .my-n5 { margin-top: -3rem !important; }

.mr-n5, .mx-n5 { margin-right: -3rem !important; }

.mb-n5, .my-n5 { margin-bottom: -3rem !important; }

.ml-n5, .mx-n5 { margin-left: -3rem !important; }

.m-auto { margin: auto !important; }

.mt-auto, .my-auto { margin-top: auto !important; }

.mr-auto, .mx-auto { margin-right: auto !important; }

.mb-auto, .my-auto { margin-bottom: auto !important; }

.ml-auto, .mx-auto { margin-left: auto !important; }

@media (min-width: 576px) { .m-sm-0 { margin: 0 !important; }
  .mt-sm-0, .my-sm-0 { margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 { margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 { margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 { margin-left: 0 !important; }
  .m-sm-1 { margin: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 { margin-top: 0.25rem !important; }
  .mr-sm-1, .mx-sm-1 { margin-right: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 { margin-bottom: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 { margin-left: 0.25rem !important; }
  .m-sm-2 { margin: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 { margin-top: 0.5rem !important; }
  .mr-sm-2, .mx-sm-2 { margin-right: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 { margin-bottom: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 { margin-left: 0.5rem !important; }
  .m-sm-3 { margin: 1rem !important; }
  .mt-sm-3, .my-sm-3 { margin-top: 1rem !important; }
  .mr-sm-3, .mx-sm-3 { margin-right: 1rem !important; }
  .mb-sm-3, .my-sm-3 { margin-bottom: 1rem !important; }
  .ml-sm-3, .mx-sm-3 { margin-left: 1rem !important; }
  .m-sm-4 { margin: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 { margin-top: 1.5rem !important; }
  .mr-sm-4, .mx-sm-4 { margin-right: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 { margin-bottom: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 { margin-left: 1.5rem !important; }
  .m-sm-5 { margin: 3rem !important; }
  .mt-sm-5, .my-sm-5 { margin-top: 3rem !important; }
  .mr-sm-5, .mx-sm-5 { margin-right: 3rem !important; }
  .mb-sm-5, .my-sm-5 { margin-bottom: 3rem !important; }
  .ml-sm-5, .mx-sm-5 { margin-left: 3rem !important; }
  .p-sm-0 { padding: 0 !important; }
  .pt-sm-0, .py-sm-0 { padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 { padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 { padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 { padding-left: 0 !important; }
  .p-sm-1 { padding: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 { padding-top: 0.25rem !important; }
  .pr-sm-1, .px-sm-1 { padding-right: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 { padding-bottom: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 { padding-left: 0.25rem !important; }
  .p-sm-2 { padding: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 { padding-top: 0.5rem !important; }
  .pr-sm-2, .px-sm-2 { padding-right: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 { padding-bottom: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 { padding-left: 0.5rem !important; }
  .p-sm-3 { padding: 1rem !important; }
  .pt-sm-3, .py-sm-3 { padding-top: 1rem !important; }
  .pr-sm-3, .px-sm-3 { padding-right: 1rem !important; }
  .pb-sm-3, .py-sm-3 { padding-bottom: 1rem !important; }
  .pl-sm-3, .px-sm-3 { padding-left: 1rem !important; }
  .p-sm-4 { padding: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 { padding-top: 1.5rem !important; }
  .pr-sm-4, .px-sm-4 { padding-right: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 { padding-bottom: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 { padding-left: 1.5rem !important; }
  .p-sm-5 { padding: 3rem !important; }
  .pt-sm-5, .py-sm-5 { padding-top: 3rem !important; }
  .pr-sm-5, .px-sm-5 { padding-right: 3rem !important; }
  .pb-sm-5, .py-sm-5 { padding-bottom: 3rem !important; }
  .pl-sm-5, .px-sm-5 { padding-left: 3rem !important; }
  .m-sm-n1 { margin: -0.25rem !important; }
  .mt-sm-n1, .my-sm-n1 { margin-top: -0.25rem !important; }
  .mr-sm-n1, .mx-sm-n1 { margin-right: -0.25rem !important; }
  .mb-sm-n1, .my-sm-n1 { margin-bottom: -0.25rem !important; }
  .ml-sm-n1, .mx-sm-n1 { margin-left: -0.25rem !important; }
  .m-sm-n2 { margin: -0.5rem !important; }
  .mt-sm-n2, .my-sm-n2 { margin-top: -0.5rem !important; }
  .mr-sm-n2, .mx-sm-n2 { margin-right: -0.5rem !important; }
  .mb-sm-n2, .my-sm-n2 { margin-bottom: -0.5rem !important; }
  .ml-sm-n2, .mx-sm-n2 { margin-left: -0.5rem !important; }
  .m-sm-n3 { margin: -1rem !important; }
  .mt-sm-n3, .my-sm-n3 { margin-top: -1rem !important; }
  .mr-sm-n3, .mx-sm-n3 { margin-right: -1rem !important; }
  .mb-sm-n3, .my-sm-n3 { margin-bottom: -1rem !important; }
  .ml-sm-n3, .mx-sm-n3 { margin-left: -1rem !important; }
  .m-sm-n4 { margin: -1.5rem !important; }
  .mt-sm-n4, .my-sm-n4 { margin-top: -1.5rem !important; }
  .mr-sm-n4, .mx-sm-n4 { margin-right: -1.5rem !important; }
  .mb-sm-n4, .my-sm-n4 { margin-bottom: -1.5rem !important; }
  .ml-sm-n4, .mx-sm-n4 { margin-left: -1.5rem !important; }
  .m-sm-n5 { margin: -3rem !important; }
  .mt-sm-n5, .my-sm-n5 { margin-top: -3rem !important; }
  .mr-sm-n5, .mx-sm-n5 { margin-right: -3rem !important; }
  .mb-sm-n5, .my-sm-n5 { margin-bottom: -3rem !important; }
  .ml-sm-n5, .mx-sm-n5 { margin-left: -3rem !important; }
  .m-sm-auto { margin: auto !important; }
  .mt-sm-auto, .my-sm-auto { margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto { margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto { margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto { margin-left: auto !important; } }

@media (min-width: 768px) { .m-md-0 { margin: 0 !important; }
  .mt-md-0, .my-md-0 { margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 { margin-right: 0 !important; }
  .mb-md-0, .my-md-0 { margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 { margin-left: 0 !important; }
  .m-md-1 { margin: 0.25rem !important; }
  .mt-md-1, .my-md-1 { margin-top: 0.25rem !important; }
  .mr-md-1, .mx-md-1 { margin-right: 0.25rem !important; }
  .mb-md-1, .my-md-1 { margin-bottom: 0.25rem !important; }
  .ml-md-1, .mx-md-1 { margin-left: 0.25rem !important; }
  .m-md-2 { margin: 0.5rem !important; }
  .mt-md-2, .my-md-2 { margin-top: 0.5rem !important; }
  .mr-md-2, .mx-md-2 { margin-right: 0.5rem !important; }
  .mb-md-2, .my-md-2 { margin-bottom: 0.5rem !important; }
  .ml-md-2, .mx-md-2 { margin-left: 0.5rem !important; }
  .m-md-3 { margin: 1rem !important; }
  .mt-md-3, .my-md-3 { margin-top: 1rem !important; }
  .mr-md-3, .mx-md-3 { margin-right: 1rem !important; }
  .mb-md-3, .my-md-3 { margin-bottom: 1rem !important; }
  .ml-md-3, .mx-md-3 { margin-left: 1rem !important; }
  .m-md-4 { margin: 1.5rem !important; }
  .mt-md-4, .my-md-4 { margin-top: 1.5rem !important; }
  .mr-md-4, .mx-md-4 { margin-right: 1.5rem !important; }
  .mb-md-4, .my-md-4 { margin-bottom: 1.5rem !important; }
  .ml-md-4, .mx-md-4 { margin-left: 1.5rem !important; }
  .m-md-5 { margin: 3rem !important; }
  .mt-md-5, .my-md-5 { margin-top: 3rem !important; }
  .mr-md-5, .mx-md-5 { margin-right: 3rem !important; }
  .mb-md-5, .my-md-5 { margin-bottom: 3rem !important; }
  .ml-md-5, .mx-md-5 { margin-left: 3rem !important; }
  .p-md-0 { padding: 0 !important; }
  .pt-md-0, .py-md-0 { padding-top: 0 !important; }
  .pr-md-0, .px-md-0 { padding-right: 0 !important; }
  .pb-md-0, .py-md-0 { padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 { padding-left: 0 !important; }
  .p-md-1 { padding: 0.25rem !important; }
  .pt-md-1, .py-md-1 { padding-top: 0.25rem !important; }
  .pr-md-1, .px-md-1 { padding-right: 0.25rem !important; }
  .pb-md-1, .py-md-1 { padding-bottom: 0.25rem !important; }
  .pl-md-1, .px-md-1 { padding-left: 0.25rem !important; }
  .p-md-2 { padding: 0.5rem !important; }
  .pt-md-2, .py-md-2 { padding-top: 0.5rem !important; }
  .pr-md-2, .px-md-2 { padding-right: 0.5rem !important; }
  .pb-md-2, .py-md-2 { padding-bottom: 0.5rem !important; }
  .pl-md-2, .px-md-2 { padding-left: 0.5rem !important; }
  .p-md-3 { padding: 1rem !important; }
  .pt-md-3, .py-md-3 { padding-top: 1rem !important; }
  .pr-md-3, .px-md-3 { padding-right: 1rem !important; }
  .pb-md-3, .py-md-3 { padding-bottom: 1rem !important; }
  .pl-md-3, .px-md-3 { padding-left: 1rem !important; }
  .p-md-4 { padding: 1.5rem !important; }
  .pt-md-4, .py-md-4 { padding-top: 1.5rem !important; }
  .pr-md-4, .px-md-4 { padding-right: 1.5rem !important; }
  .pb-md-4, .py-md-4 { padding-bottom: 1.5rem !important; }
  .pl-md-4, .px-md-4 { padding-left: 1.5rem !important; }
  .p-md-5 { padding: 3rem !important; }
  .pt-md-5, .py-md-5 { padding-top: 3rem !important; }
  .pr-md-5, .px-md-5 { padding-right: 3rem !important; }
  .pb-md-5, .py-md-5 { padding-bottom: 3rem !important; }
  .pl-md-5, .px-md-5 { padding-left: 3rem !important; }
  .m-md-n1 { margin: -0.25rem !important; }
  .mt-md-n1, .my-md-n1 { margin-top: -0.25rem !important; }
  .mr-md-n1, .mx-md-n1 { margin-right: -0.25rem !important; }
  .mb-md-n1, .my-md-n1 { margin-bottom: -0.25rem !important; }
  .ml-md-n1, .mx-md-n1 { margin-left: -0.25rem !important; }
  .m-md-n2 { margin: -0.5rem !important; }
  .mt-md-n2, .my-md-n2 { margin-top: -0.5rem !important; }
  .mr-md-n2, .mx-md-n2 { margin-right: -0.5rem !important; }
  .mb-md-n2, .my-md-n2 { margin-bottom: -0.5rem !important; }
  .ml-md-n2, .mx-md-n2 { margin-left: -0.5rem !important; }
  .m-md-n3 { margin: -1rem !important; }
  .mt-md-n3, .my-md-n3 { margin-top: -1rem !important; }
  .mr-md-n3, .mx-md-n3 { margin-right: -1rem !important; }
  .mb-md-n3, .my-md-n3 { margin-bottom: -1rem !important; }
  .ml-md-n3, .mx-md-n3 { margin-left: -1rem !important; }
  .m-md-n4 { margin: -1.5rem !important; }
  .mt-md-n4, .my-md-n4 { margin-top: -1.5rem !important; }
  .mr-md-n4, .mx-md-n4 { margin-right: -1.5rem !important; }
  .mb-md-n4, .my-md-n4 { margin-bottom: -1.5rem !important; }
  .ml-md-n4, .mx-md-n4 { margin-left: -1.5rem !important; }
  .m-md-n5 { margin: -3rem !important; }
  .mt-md-n5, .my-md-n5 { margin-top: -3rem !important; }
  .mr-md-n5, .mx-md-n5 { margin-right: -3rem !important; }
  .mb-md-n5, .my-md-n5 { margin-bottom: -3rem !important; }
  .ml-md-n5, .mx-md-n5 { margin-left: -3rem !important; }
  .m-md-auto { margin: auto !important; }
  .mt-md-auto, .my-md-auto { margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto { margin-right: auto !important; }
  .mb-md-auto, .my-md-auto { margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto { margin-left: auto !important; } }

@media (min-width: 992px) { .m-lg-0 { margin: 0 !important; }
  .mt-lg-0, .my-lg-0 { margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 { margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 { margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 { margin-left: 0 !important; }
  .m-lg-1 { margin: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 { margin-top: 0.25rem !important; }
  .mr-lg-1, .mx-lg-1 { margin-right: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 { margin-bottom: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 { margin-left: 0.25rem !important; }
  .m-lg-2 { margin: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 { margin-top: 0.5rem !important; }
  .mr-lg-2, .mx-lg-2 { margin-right: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 { margin-bottom: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 { margin-left: 0.5rem !important; }
  .m-lg-3 { margin: 1rem !important; }
  .mt-lg-3, .my-lg-3 { margin-top: 1rem !important; }
  .mr-lg-3, .mx-lg-3 { margin-right: 1rem !important; }
  .mb-lg-3, .my-lg-3 { margin-bottom: 1rem !important; }
  .ml-lg-3, .mx-lg-3 { margin-left: 1rem !important; }
  .m-lg-4 { margin: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 { margin-top: 1.5rem !important; }
  .mr-lg-4, .mx-lg-4 { margin-right: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 { margin-bottom: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 { margin-left: 1.5rem !important; }
  .m-lg-5 { margin: 3rem !important; }
  .mt-lg-5, .my-lg-5 { margin-top: 3rem !important; }
  .mr-lg-5, .mx-lg-5 { margin-right: 3rem !important; }
  .mb-lg-5, .my-lg-5 { margin-bottom: 3rem !important; }
  .ml-lg-5, .mx-lg-5 { margin-left: 3rem !important; }
  .p-lg-0 { padding: 0 !important; }
  .pt-lg-0, .py-lg-0 { padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 { padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 { padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 { padding-left: 0 !important; }
  .p-lg-1 { padding: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 { padding-top: 0.25rem !important; }
  .pr-lg-1, .px-lg-1 { padding-right: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 { padding-bottom: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 { padding-left: 0.25rem !important; }
  .p-lg-2 { padding: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 { padding-top: 0.5rem !important; }
  .pr-lg-2, .px-lg-2 { padding-right: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 { padding-bottom: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 { padding-left: 0.5rem !important; }
  .p-lg-3 { padding: 1rem !important; }
  .pt-lg-3, .py-lg-3 { padding-top: 1rem !important; }
  .pr-lg-3, .px-lg-3 { padding-right: 1rem !important; }
  .pb-lg-3, .py-lg-3 { padding-bottom: 1rem !important; }
  .pl-lg-3, .px-lg-3 { padding-left: 1rem !important; }
  .p-lg-4 { padding: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 { padding-top: 1.5rem !important; }
  .pr-lg-4, .px-lg-4 { padding-right: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 { padding-bottom: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 { padding-left: 1.5rem !important; }
  .p-lg-5 { padding: 3rem !important; }
  .pt-lg-5, .py-lg-5 { padding-top: 3rem !important; }
  .pr-lg-5, .px-lg-5 { padding-right: 3rem !important; }
  .pb-lg-5, .py-lg-5 { padding-bottom: 3rem !important; }
  .pl-lg-5, .px-lg-5 { padding-left: 3rem !important; }
  .m-lg-n1 { margin: -0.25rem !important; }
  .mt-lg-n1, .my-lg-n1 { margin-top: -0.25rem !important; }
  .mr-lg-n1, .mx-lg-n1 { margin-right: -0.25rem !important; }
  .mb-lg-n1, .my-lg-n1 { margin-bottom: -0.25rem !important; }
  .ml-lg-n1, .mx-lg-n1 { margin-left: -0.25rem !important; }
  .m-lg-n2 { margin: -0.5rem !important; }
  .mt-lg-n2, .my-lg-n2 { margin-top: -0.5rem !important; }
  .mr-lg-n2, .mx-lg-n2 { margin-right: -0.5rem !important; }
  .mb-lg-n2, .my-lg-n2 { margin-bottom: -0.5rem !important; }
  .ml-lg-n2, .mx-lg-n2 { margin-left: -0.5rem !important; }
  .m-lg-n3 { margin: -1rem !important; }
  .mt-lg-n3, .my-lg-n3 { margin-top: -1rem !important; }
  .mr-lg-n3, .mx-lg-n3 { margin-right: -1rem !important; }
  .mb-lg-n3, .my-lg-n3 { margin-bottom: -1rem !important; }
  .ml-lg-n3, .mx-lg-n3 { margin-left: -1rem !important; }
  .m-lg-n4 { margin: -1.5rem !important; }
  .mt-lg-n4, .my-lg-n4 { margin-top: -1.5rem !important; }
  .mr-lg-n4, .mx-lg-n4 { margin-right: -1.5rem !important; }
  .mb-lg-n4, .my-lg-n4 { margin-bottom: -1.5rem !important; }
  .ml-lg-n4, .mx-lg-n4 { margin-left: -1.5rem !important; }
  .m-lg-n5 { margin: -3rem !important; }
  .mt-lg-n5, .my-lg-n5 { margin-top: -3rem !important; }
  .mr-lg-n5, .mx-lg-n5 { margin-right: -3rem !important; }
  .mb-lg-n5, .my-lg-n5 { margin-bottom: -3rem !important; }
  .ml-lg-n5, .mx-lg-n5 { margin-left: -3rem !important; }
  .m-lg-auto { margin: auto !important; }
  .mt-lg-auto, .my-lg-auto { margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto { margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto { margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto { margin-left: auto !important; } }

@media (min-width: 1200px) { .m-xl-0 { margin: 0 !important; }
  .mt-xl-0, .my-xl-0 { margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 { margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 { margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 { margin-left: 0 !important; }
  .m-xl-1 { margin: 0.25rem !important; }
  .mt-xl-1, .my-xl-1 { margin-top: 0.25rem !important; }
  .mr-xl-1, .mx-xl-1 { margin-right: 0.25rem !important; }
  .mb-xl-1, .my-xl-1 { margin-bottom: 0.25rem !important; }
  .ml-xl-1, .mx-xl-1 { margin-left: 0.25rem !important; }
  .m-xl-2 { margin: 0.5rem !important; }
  .mt-xl-2, .my-xl-2 { margin-top: 0.5rem !important; }
  .mr-xl-2, .mx-xl-2 { margin-right: 0.5rem !important; }
  .mb-xl-2, .my-xl-2 { margin-bottom: 0.5rem !important; }
  .ml-xl-2, .mx-xl-2 { margin-left: 0.5rem !important; }
  .m-xl-3 { margin: 1rem !important; }
  .mt-xl-3, .my-xl-3 { margin-top: 1rem !important; }
  .mr-xl-3, .mx-xl-3 { margin-right: 1rem !important; }
  .mb-xl-3, .my-xl-3 { margin-bottom: 1rem !important; }
  .ml-xl-3, .mx-xl-3 { margin-left: 1rem !important; }
  .m-xl-4 { margin: 1.5rem !important; }
  .mt-xl-4, .my-xl-4 { margin-top: 1.5rem !important; }
  .mr-xl-4, .mx-xl-4 { margin-right: 1.5rem !important; }
  .mb-xl-4, .my-xl-4 { margin-bottom: 1.5rem !important; }
  .ml-xl-4, .mx-xl-4 { margin-left: 1.5rem !important; }
  .m-xl-5 { margin: 3rem !important; }
  .mt-xl-5, .my-xl-5 { margin-top: 3rem !important; }
  .mr-xl-5, .mx-xl-5 { margin-right: 3rem !important; }
  .mb-xl-5, .my-xl-5 { margin-bottom: 3rem !important; }
  .ml-xl-5, .mx-xl-5 { margin-left: 3rem !important; }
  .p-xl-0 { padding: 0 !important; }
  .pt-xl-0, .py-xl-0 { padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 { padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 { padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 { padding-left: 0 !important; }
  .p-xl-1 { padding: 0.25rem !important; }
  .pt-xl-1, .py-xl-1 { padding-top: 0.25rem !important; }
  .pr-xl-1, .px-xl-1 { padding-right: 0.25rem !important; }
  .pb-xl-1, .py-xl-1 { padding-bottom: 0.25rem !important; }
  .pl-xl-1, .px-xl-1 { padding-left: 0.25rem !important; }
  .p-xl-2 { padding: 0.5rem !important; }
  .pt-xl-2, .py-xl-2 { padding-top: 0.5rem !important; }
  .pr-xl-2, .px-xl-2 { padding-right: 0.5rem !important; }
  .pb-xl-2, .py-xl-2 { padding-bottom: 0.5rem !important; }
  .pl-xl-2, .px-xl-2 { padding-left: 0.5rem !important; }
  .p-xl-3 { padding: 1rem !important; }
  .pt-xl-3, .py-xl-3 { padding-top: 1rem !important; }
  .pr-xl-3, .px-xl-3 { padding-right: 1rem !important; }
  .pb-xl-3, .py-xl-3 { padding-bottom: 1rem !important; }
  .pl-xl-3, .px-xl-3 { padding-left: 1rem !important; }
  .p-xl-4 { padding: 1.5rem !important; }
  .pt-xl-4, .py-xl-4 { padding-top: 1.5rem !important; }
  .pr-xl-4, .px-xl-4 { padding-right: 1.5rem !important; }
  .pb-xl-4, .py-xl-4 { padding-bottom: 1.5rem !important; }
  .pl-xl-4, .px-xl-4 { padding-left: 1.5rem !important; }
  .p-xl-5 { padding: 3rem !important; }
  .pt-xl-5, .py-xl-5 { padding-top: 3rem !important; }
  .pr-xl-5, .px-xl-5 { padding-right: 3rem !important; }
  .pb-xl-5, .py-xl-5 { padding-bottom: 3rem !important; }
  .pl-xl-5, .px-xl-5 { padding-left: 3rem !important; }
  .m-xl-n1 { margin: -0.25rem !important; }
  .mt-xl-n1, .my-xl-n1 { margin-top: -0.25rem !important; }
  .mr-xl-n1, .mx-xl-n1 { margin-right: -0.25rem !important; }
  .mb-xl-n1, .my-xl-n1 { margin-bottom: -0.25rem !important; }
  .ml-xl-n1, .mx-xl-n1 { margin-left: -0.25rem !important; }
  .m-xl-n2 { margin: -0.5rem !important; }
  .mt-xl-n2, .my-xl-n2 { margin-top: -0.5rem !important; }
  .mr-xl-n2, .mx-xl-n2 { margin-right: -0.5rem !important; }
  .mb-xl-n2, .my-xl-n2 { margin-bottom: -0.5rem !important; }
  .ml-xl-n2, .mx-xl-n2 { margin-left: -0.5rem !important; }
  .m-xl-n3 { margin: -1rem !important; }
  .mt-xl-n3, .my-xl-n3 { margin-top: -1rem !important; }
  .mr-xl-n3, .mx-xl-n3 { margin-right: -1rem !important; }
  .mb-xl-n3, .my-xl-n3 { margin-bottom: -1rem !important; }
  .ml-xl-n3, .mx-xl-n3 { margin-left: -1rem !important; }
  .m-xl-n4 { margin: -1.5rem !important; }
  .mt-xl-n4, .my-xl-n4 { margin-top: -1.5rem !important; }
  .mr-xl-n4, .mx-xl-n4 { margin-right: -1.5rem !important; }
  .mb-xl-n4, .my-xl-n4 { margin-bottom: -1.5rem !important; }
  .ml-xl-n4, .mx-xl-n4 { margin-left: -1.5rem !important; }
  .m-xl-n5 { margin: -3rem !important; }
  .mt-xl-n5, .my-xl-n5 { margin-top: -3rem !important; }
  .mr-xl-n5, .mx-xl-n5 { margin-right: -3rem !important; }
  .mb-xl-n5, .my-xl-n5 { margin-bottom: -3rem !important; }
  .ml-xl-n5, .mx-xl-n5 { margin-left: -3rem !important; }
  .m-xl-auto { margin: auto !important; }
  .mt-xl-auto, .my-xl-auto { margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto { margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto { margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto { margin-left: auto !important; } }

.text-monospace { font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify { text-align: justify !important; }

.text-wrap { white-space: normal !important; }

.text-nowrap { white-space: nowrap !important; }

.text-truncate { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; }

.text-left { text-align: left !important; }

.text-right { text-align: right !important; }

.text-center { text-align: center !important; }

@media (min-width: 576px) { .text-sm-left { text-align: left !important; }
  .text-sm-right { text-align: right !important; }
  .text-sm-center { text-align: center !important; } }

@media (min-width: 768px) { .text-md-left { text-align: left !important; }
  .text-md-right { text-align: right !important; }
  .text-md-center { text-align: center !important; } }

@media (min-width: 992px) { .text-lg-left { text-align: left !important; }
  .text-lg-right { text-align: right !important; }
  .text-lg-center { text-align: center !important; } }

@media (min-width: 1200px) { .text-xl-left { text-align: left !important; }
  .text-xl-right { text-align: right !important; }
  .text-xl-center { text-align: center !important; } }

.text-lowercase { text-transform: lowercase !important; }

.text-uppercase { text-transform: uppercase !important; }

.text-capitalize { text-transform: capitalize !important; }

.font-weight-light { font-weight: 300 !important; }

.font-weight-lighter { font-weight: lighter !important; }

.font-weight-normal { font-weight: 400 !important; }

.font-weight-bold { font-weight: 700 !important; }

.font-weight-bolder { font-weight: bolder !important; }

.font-italic { font-style: italic !important; }

.text-white { color: #fff !important; }

.text-primary { color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus { color: #0056b3 !important; }

.text-secondary { color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus { color: #494f54 !important; }

.text-success { color: #28a745 !important; }

a.text-success:hover, a.text-success:focus { color: #19692c !important; }

.text-info { color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus { color: #0f6674 !important; }

.text-warning { color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus { color: #ba8b00 !important; }

.text-danger { color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus { color: #a71d2a !important; }

.text-light { color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus { color: #cbd3da !important; }

.text-dark { color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus { color: #121416 !important; }

.text-body { color: #212529 !important; }

.text-muted { color: #6c757d !important; }

.text-black-50 { color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 { color: rgba(255, 255, 255, 0.5) !important; }

.text-hide { font: 0/0 a; color: transparent; text-shadow: none; background-color: transparent; border: 0; }

.text-decoration-none { text-decoration: none !important; }

.text-break { word-break: break-word !important; overflow-wrap: break-word !important; }

.text-reset { color: inherit !important; }

.visible { visibility: visible !important; }

.invisible { visibility: hidden !important; }

@media print { *, *::before, *::after { text-shadow: none !important; box-shadow: none !important; }
  a:not(.btn) { text-decoration: underline; }
  abbr[title]::after { content: " (" attr(title) ")"; }
  pre { white-space: pre-wrap !important; }
  pre, blockquote { border: 1px solid #adb5bd; page-break-inside: avoid; }
  thead { display: table-header-group; }
  tr, img { page-break-inside: avoid; }
  p, h2, h3 { orphans: 3; widows: 3; }
  h2, h3 { page-break-after: avoid; }
  @page { size: a3; }
  body { min-width: 992px !important; }
  .container { min-width: 992px !important; }
  .navbar { display: none; }
  .badge { border: 1px solid #000; }
  .table { border-collapse: collapse !important; }
  .table td, .table th { background-color: #fff !important; }
  .table-bordered th, .table-bordered td { border: 1px solid #dee2e6 !important; }
  .table-dark { color: inherit; }
  .table-dark th, .table-dark td, .table-dark thead th, .table-dark tbody + tbody { border-color: #dee2e6; }
  .table .thead-dark th { color: inherit; border-color: #dee2e6; } }

@font-face { font-family: 'lg'; src: url("../fonts/lg.eot?n1z373"); src: url("../fonts/lg.eot?#iefixn1z373") format("embedded-opentype"), url("../fonts/lg.woff?n1z373") format("woff"), url("../fonts/lg.ttf?n1z373") format("truetype"), url("../fonts/lg.svg?n1z373#lg") format("svg"); font-weight: normal; font-style: normal; }

.lg-icon { font-family: 'lg'; speak: none; font-style: normal; font-weight: normal; font-variant: normal; text-transform: none; line-height: 1; /* Better Font Rendering =========== */ -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.lg-actions .lg-next, .lg-actions .lg-prev { background-color: rgba(0, 0, 0, 0.45); border-radius: 2px; color: #999; cursor: pointer; display: block; font-size: 22px; margin-top: -10px; padding: 8px 10px 9px; position: absolute; top: 50%; z-index: 1080; border: none; outline: none; }

.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled { pointer-events: none; opacity: 0.5; }

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover { color: #FFF; }

.lg-actions .lg-next { right: 20px; }

.lg-actions .lg-next:before { content: "\e095"; }

.lg-actions .lg-prev { left: 20px; }

.lg-actions .lg-prev:after { content: "\e094"; }

@-webkit-keyframes lg-right-end { 0% { left: 0; }
  50% { left: -30px; }
  100% { left: 0; } }

@-moz-keyframes lg-right-end { 0% { left: 0; }
  50% { left: -30px; }
  100% { left: 0; } }

@-ms-keyframes lg-right-end { 0% { left: 0; }
  50% { left: -30px; }
  100% { left: 0; } }

@keyframes lg-right-end { 0% { left: 0; }
  50% { left: -30px; }
  100% { left: 0; } }

@-webkit-keyframes lg-left-end { 0% { left: 0; }
  50% { left: 30px; }
  100% { left: 0; } }

@-moz-keyframes lg-left-end { 0% { left: 0; }
  50% { left: 30px; }
  100% { left: 0; } }

@-ms-keyframes lg-left-end { 0% { left: 0; }
  50% { left: 30px; }
  100% { left: 0; } }

@keyframes lg-left-end { 0% { left: 0; }
  50% { left: 30px; }
  100% { left: 0; } }

.lg-outer.lg-right-end .lg-object { -webkit-animation: lg-right-end 0.3s; -o-animation: lg-right-end 0.3s; animation: lg-right-end 0.3s; position: relative; }

.lg-outer.lg-left-end .lg-object { -webkit-animation: lg-left-end 0.3s; -o-animation: lg-left-end 0.3s; animation: lg-left-end 0.3s; position: relative; }

.lg-toolbar { z-index: 1082; left: 0; position: absolute; top: 0; width: 100%; background-color: rgba(0, 0, 0, 0.45); }

.lg-toolbar .lg-icon { color: #999; cursor: pointer; float: right; font-size: 24px; height: 47px; line-height: 27px; padding: 10px 0; text-align: center; width: 50px; text-decoration: none !important; outline: medium none; -webkit-transition: color 0.2s linear; -o-transition: color 0.2s linear; transition: color 0.2s linear; }

.lg-toolbar .lg-icon:hover { color: #FFF; }

.lg-toolbar .lg-close:after { content: "\e070"; }

.lg-toolbar .lg-download:after { content: "\e0f2"; }

.lg-sub-html { background-color: rgba(0, 0, 0, 0.45); bottom: 0; color: #EEE; font-size: 16px; left: 0; padding: 10px 40px; position: fixed; right: 0; text-align: center; z-index: 1080; }

.lg-sub-html h4 { margin: 0; font-size: 13px; font-weight: bold; }

.lg-sub-html p { font-size: 12px; margin: 5px 0 0; }

#lg-counter { color: #999; display: inline-block; font-size: 16px; padding-left: 20px; padding-top: 12px; vertical-align: middle; }

.lg-toolbar, .lg-prev, .lg-next { opacity: 1; -webkit-transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; -moz-transition: -moz-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; -o-transition: -o-transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, color 0.2s linear; }

.lg-hide-items .lg-prev { opacity: 0; -webkit-transform: translate3d(-10px, 0, 0); transform: translate3d(-10px, 0, 0); }

.lg-hide-items .lg-next { opacity: 0; -webkit-transform: translate3d(10px, 0, 0); transform: translate3d(10px, 0, 0); }

.lg-hide-items .lg-toolbar { opacity: 0; -webkit-transform: translate3d(0, -10px, 0); transform: translate3d(0, -10px, 0); }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object { -webkit-transform: scale3d(0.5, 0.5, 0.5); transform: scale3d(0.5, 0.5, 0.5); opacity: 0; -webkit-transition: -webkit-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important; -moz-transition: -moz-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important; -o-transition: -o-transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important; transition: transform 250ms cubic-bezier(0, 0, 0.25, 1) 0s, opacity 250ms cubic-bezier(0, 0, 0.25, 1) !important; -webkit-transform-origin: 50% 50%; -moz-transform-origin: 50% 50%; -ms-transform-origin: 50% 50%; transform-origin: 50% 50%; }

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object { -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); opacity: 1; }

.lg-outer .lg-thumb-outer { background-color: #0D0A0A; bottom: 0; position: absolute; width: 100%; z-index: 1080; max-height: 350px; -webkit-transform: translate3d(0, 100%, 0); transform: translate3d(0, 100%, 0); -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item { cursor: -webkit-grab; cursor: -moz-grab; cursor: -o-grab; cursor: -ms-grab; cursor: grab; }

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item { cursor: move; cursor: -webkit-grabbing; cursor: -moz-grabbing; cursor: -o-grabbing; cursor: -ms-grabbing; cursor: grabbing; }

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb { -webkit-transition-duration: 0s !important; transition-duration: 0s !important; }

.lg-outer.lg-thumb-open .lg-thumb-outer { -webkit-transform: translate3d(0, 0%, 0); transform: translate3d(0, 0%, 0); }

.lg-outer .lg-thumb { padding: 10px 0; height: 100%; margin-bottom: -5px; }

.lg-outer .lg-thumb-item { border-radius: 5px; cursor: pointer; float: left; overflow: hidden; height: 100%; border: 2px solid #FFF; border-radius: 4px; margin-bottom: 5px; }

@media (min-width: 1025px) { .lg-outer .lg-thumb-item { -webkit-transition: border-color 0.25s ease; -o-transition: border-color 0.25s ease; transition: border-color 0.25s ease; } }

.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover { border-color: #a90707; }

.lg-outer .lg-thumb-item img { width: 100%; height: 100%; object-fit: cover; }

.lg-outer.lg-has-thumb .lg-item { padding-bottom: 120px; }

.lg-outer.lg-can-toggle .lg-item { padding-bottom: 0; }

.lg-outer.lg-pull-caption-up .lg-sub-html { -webkit-transition: bottom 0.25s ease; -o-transition: bottom 0.25s ease; transition: bottom 0.25s ease; }

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html { bottom: 100px; }

.lg-outer .lg-toogle-thumb { background-color: #0D0A0A; border-radius: 2px 2px 0 0; color: #999; cursor: pointer; font-size: 24px; height: 39px; line-height: 27px; padding: 5px 0; position: absolute; right: 20px; text-align: center; top: -39px; width: 50px; }

.lg-outer .lg-toogle-thumb:after { content: "\e1ff"; }

.lg-outer .lg-toogle-thumb:hover { color: #FFF; }

.lg-outer .lg-video-cont { display: inline-block; vertical-align: middle; max-width: 1140px; max-height: 100%; width: 100%; padding: 0 5px; }

.lg-outer .lg-video { width: 100%; height: 0; padding-bottom: 56.25%; overflow: hidden; position: relative; }

.lg-outer .lg-video .lg-object { display: inline-block; position: absolute; top: 0; left: 0; width: 100% !important; height: 100% !important; }

.lg-outer .lg-video .lg-video-play { width: 84px; height: 59px; position: absolute; left: 50%; top: 50%; margin-left: -42px; margin-top: -30px; z-index: 1080; cursor: pointer; }

.lg-outer .lg-has-iframe .lg-video { -webkit-overflow-scrolling: touch; overflow: auto; }

.lg-outer .lg-has-vimeo .lg-video-play { background: url("../img/vimeo-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-vimeo:hover .lg-video-play { background: url("../img/vimeo-play.png") no-repeat scroll 0 -58px transparent; }

.lg-outer .lg-has-html5 .lg-video-play { background: transparent url("../img/video-play.png") no-repeat scroll 0 0; height: 64px; margin-left: -32px; margin-top: -32px; width: 64px; opacity: 0.8; }

.lg-outer .lg-has-html5:hover .lg-video-play { opacity: 1; }

.lg-outer .lg-has-youtube .lg-video-play { background: url("../img/youtube-play.png") no-repeat scroll 0 0 transparent; }

.lg-outer .lg-has-youtube:hover .lg-video-play { background: url("../img/youtube-play.png") no-repeat scroll 0 -60px transparent; }

.lg-outer .lg-video-object { width: 100% !important; height: 100% !important; position: absolute; top: 0; left: 0; }

.lg-outer .lg-has-video .lg-video-object { visibility: hidden; }

.lg-outer .lg-has-video.lg-video-playing .lg-object, .lg-outer .lg-has-video.lg-video-playing .lg-video-play { display: none; }

.lg-outer .lg-has-video.lg-video-playing .lg-video-object { visibility: visible; }

.lg-progress-bar { background-color: #333; height: 5px; left: 0; position: absolute; top: 0; width: 100%; z-index: 1083; opacity: 0; -webkit-transition: opacity 0.08s ease 0s; -moz-transition: opacity 0.08s ease 0s; -o-transition: opacity 0.08s ease 0s; transition: opacity 0.08s ease 0s; }

.lg-progress-bar .lg-progress { background-color: #a90707; height: 5px; width: 0; }

.lg-progress-bar.lg-start .lg-progress { width: 100%; }

.lg-show-autoplay .lg-progress-bar { opacity: 1; }

.lg-autoplay-button:after { content: "\e01d"; }

.lg-show-autoplay .lg-autoplay-button:after { content: "\e01a"; }

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image { -webkit-transition-duration: 0s; transition-duration: 0s; }

.lg-outer.lg-use-transition-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap { -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer.lg-use-left-for-zoom .lg-item.lg-complete.lg-zoomable .lg-img-wrap { -webkit-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; -moz-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; -o-transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; transition: left 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, top 0.3s cubic-bezier(0, 0, 0.25, 1) 0s; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; backface-visibility: hidden; }

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image { -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important; -moz-transition: -moz-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important; -o-transition: -o-transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important; transition: transform 0.3s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.15s !important; -webkit-transform-origin: 0 0; -moz-transform-origin: 0 0; -ms-transform-origin: 0 0; transform-origin: 0 0; -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; backface-visibility: hidden; }

#lg-zoom-in:after { content: "\e311"; }

#lg-actual-size { font-size: 20px; }

#lg-actual-size:after { content: "\e033"; }

#lg-zoom-out { opacity: 0.5; pointer-events: none; }

#lg-zoom-out:after { content: "\e312"; }

.lg-zoomed #lg-zoom-out { opacity: 1; pointer-events: auto; }

.lg-outer .lg-pager-outer { bottom: 60px; left: 0; position: absolute; right: 0; text-align: center; z-index: 1080; height: 10px; }

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont { overflow: visible; }

.lg-outer .lg-pager-cont { cursor: pointer; display: inline-block; overflow: hidden; position: relative; vertical-align: top; margin: 0 5px; }

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont { opacity: 1; -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager { box-shadow: 0 0 0 2px white inset; }

.lg-outer .lg-pager-thumb-cont { background-color: #fff; color: #FFF; bottom: 100%; height: 83px; left: 0; margin-bottom: 20px; margin-left: -60px; opacity: 0; padding: 5px; position: absolute; width: 120px; border-radius: 3px; -webkit-transition: opacity 0.15s ease 0s, -webkit-transform 0.15s ease 0s; -moz-transition: opacity 0.15s ease 0s, -moz-transform 0.15s ease 0s; -o-transition: opacity 0.15s ease 0s, -o-transform 0.15s ease 0s; transition: opacity 0.15s ease 0s, transform 0.15s ease 0s; -webkit-transform: translate3d(0, 5px, 0); transform: translate3d(0, 5px, 0); }

.lg-outer .lg-pager-thumb-cont img { width: 100%; height: 100%; }

.lg-outer .lg-pager { background-color: rgba(255, 255, 255, 0.5); border-radius: 50%; box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset; display: block; height: 12px; -webkit-transition: box-shadow 0.3s ease 0s; -o-transition: box-shadow 0.3s ease 0s; transition: box-shadow 0.3s ease 0s; width: 12px; }

.lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus { box-shadow: 0 0 0 8px white inset; }

.lg-outer .lg-caret { border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 10px dashed; bottom: -10px; display: inline-block; height: 0; left: 50%; margin-left: -5px; position: absolute; vertical-align: middle; width: 0; }

.lg-fullscreen:after { content: "\e20c"; }

.lg-fullscreen-on .lg-fullscreen:after { content: "\e20d"; }

.lg-outer #lg-dropdown-overlay { background-color: rgba(0, 0, 0, 0.25); bottom: 0; cursor: default; left: 0; position: fixed; right: 0; top: 0; z-index: 1081; opacity: 0; visibility: hidden; -webkit-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s; -o-transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s; transition: visibility 0s linear 0.18s, opacity 0.18s linear 0s; }

.lg-outer.lg-dropdown-active .lg-dropdown, .lg-outer.lg-dropdown-active #lg-dropdown-overlay { -webkit-transition-delay: 0s; transition-delay: 0s; -moz-transform: translate3d(0, 0px, 0); -o-transform: translate3d(0, 0px, 0); -ms-transform: translate3d(0, 0px, 0); -webkit-transform: translate3d(0, 0px, 0); transform: translate3d(0, 0px, 0); opacity: 1; visibility: visible; }

.lg-outer.lg-dropdown-active #lg-share { color: #FFF; }

.lg-outer .lg-dropdown { background-color: #fff; border-radius: 2px; font-size: 14px; list-style-type: none; margin: 0; padding: 10px 0; position: absolute; right: 0; text-align: left; top: 50px; opacity: 0; visibility: hidden; -moz-transform: translate3d(0, 5px, 0); -o-transform: translate3d(0, 5px, 0); -ms-transform: translate3d(0, 5px, 0); -webkit-transform: translate3d(0, 5px, 0); transform: translate3d(0, 5px, 0); -webkit-transition: -webkit-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; -moz-transition: -moz-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; -o-transition: -o-transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; transition: transform 0.18s linear 0s, visibility 0s linear 0.5s, opacity 0.18s linear 0s; }

.lg-outer .lg-dropdown:after { content: ""; display: block; height: 0; width: 0; position: absolute; border: 8px solid transparent; border-bottom-color: #FFF; right: 16px; top: -16px; }

.lg-outer .lg-dropdown > li:last-child { margin-bottom: 0px; }

.lg-outer .lg-dropdown > li:hover a, .lg-outer .lg-dropdown > li:hover .lg-icon { color: #333; }

.lg-outer .lg-dropdown a { color: #333; display: block; white-space: pre; padding: 4px 12px; font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif; font-size: 12px; }

.lg-outer .lg-dropdown a:hover { background-color: rgba(0, 0, 0, 0.07); }

.lg-outer .lg-dropdown .lg-dropdown-text { display: inline-block; line-height: 1; margin-top: -3px; vertical-align: middle; }

.lg-outer .lg-dropdown .lg-icon { color: #333; display: inline-block; float: none; font-size: 20px; height: auto; line-height: 1; margin-right: 8px; padding: 0; vertical-align: middle; width: auto; }

.lg-outer #lg-share { position: relative; }

.lg-outer #lg-share:after { content: "\e80d"; }

.lg-outer #lg-share-facebook .lg-icon { color: #3b5998; }

.lg-outer #lg-share-facebook .lg-icon:after { content: "\e901"; }

.lg-outer #lg-share-twitter .lg-icon { color: #00aced; }

.lg-outer #lg-share-twitter .lg-icon:after { content: "\e904"; }

.lg-outer #lg-share-googleplus .lg-icon { color: #dd4b39; }

.lg-outer #lg-share-googleplus .lg-icon:after { content: "\e902"; }

.lg-outer #lg-share-pinterest .lg-icon { color: #cb2027; }

.lg-outer #lg-share-pinterest .lg-icon:after { content: "\e903"; }

.lg-group:after { content: ""; display: table; clear: both; }

.lg-outer { width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 1050; text-align: left; opacity: 0; -webkit-transition: opacity 0.15s ease 0s; -o-transition: opacity 0.15s ease 0s; transition: opacity 0.15s ease 0s; }

.lg-outer * { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }

.lg-outer.lg-visible { opacity: 1; }

.lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current { -webkit-transition-duration: inherit !important; transition-duration: inherit !important; -webkit-transition-timing-function: inherit !important; transition-timing-function: inherit !important; }

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current { -webkit-transition-duration: 0s !important; transition-duration: 0s !important; opacity: 1; }

.lg-outer.lg-grab img.lg-object { cursor: -webkit-grab; cursor: -moz-grab; cursor: -o-grab; cursor: -ms-grab; cursor: grab; }

.lg-outer.lg-grabbing img.lg-object { cursor: move; cursor: -webkit-grabbing; cursor: -moz-grabbing; cursor: -o-grabbing; cursor: -ms-grabbing; cursor: grabbing; }

.lg-outer .lg { height: 100%; width: 100%; position: relative; overflow: hidden; margin-left: auto; margin-right: auto; max-width: 100%; max-height: 100%; }

.lg-outer .lg-inner { width: 100%; height: 100%; position: absolute; left: 0; top: 0; white-space: nowrap; }

.lg-outer .lg-item { background: url("../img/loading.gif") no-repeat scroll center center transparent; display: none !important; }

.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide { display: inline-block !important; }

.lg-outer.lg-css .lg-current { display: inline-block !important; }

.lg-outer .lg-item, .lg-outer .lg-img-wrap { display: inline-block; text-align: center; position: absolute; width: 100%; height: 100%; }

.lg-outer .lg-item:before, .lg-outer .lg-img-wrap:before { content: ""; display: inline-block; height: 50%; width: 1px; margin-right: -1px; }

.lg-outer .lg-img-wrap { position: absolute; padding: 0 5px; left: 0; right: 0; top: 0; bottom: 0; }

.lg-outer .lg-item.lg-complete { background-image: none; }

.lg-outer .lg-item.lg-current { z-index: 1060; }

.lg-outer .lg-image { display: inline-block; vertical-align: middle; max-width: 100%; max-height: 100%; width: auto !important; height: auto !important; }

.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play { opacity: 0; -webkit-transition: opacity 0.15s ease 0s; -o-transition: opacity 0.15s ease 0s; transition: opacity 0.15s ease 0s; }

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play { opacity: 1; }

.lg-outer .lg-empty-html { display: none; }

.lg-outer.lg-hide-download #lg-download { display: none; }

.lg-backdrop { position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 1040; background-color: #000; opacity: 0; -webkit-transition: opacity 0.15s ease 0s; -o-transition: opacity 0.15s ease 0s; transition: opacity 0.15s ease 0s; }

.lg-backdrop.in { opacity: 1; }

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current { -webkit-transition: none 0s ease 0s !important; -moz-transition: none 0s ease 0s !important; -o-transition: none 0s ease 0s !important; transition: none 0s ease 0s !important; }

.lg-css3.lg-use-css3 .lg-item { -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; backface-visibility: hidden; }

.lg-css3.lg-use-left .lg-item { -webkit-backface-visibility: hidden; -moz-backface-visibility: hidden; backface-visibility: hidden; }

.lg-css3.lg-fade .lg-item { opacity: 0; }

.lg-css3.lg-fade .lg-item.lg-current { opacity: 1; }

.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current { -webkit-transition: opacity 0.1s ease 0s; -moz-transition: opacity 0.1s ease 0s; -o-transition: opacity 0.1s ease 0s; transition: opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-css3 .lg-item { opacity: 0; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide { -webkit-transform: translate3d(-100%, 0, 0); transform: translate3d(-100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide { -webkit-transform: translate3d(100%, 0, 0); transform: translate3d(100%, 0, 0); }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current { -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); opacity: 1; }

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current { -webkit-transition: -webkit-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; -moz-transition: -moz-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; -o-transition: -o-transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

.lg-css3.lg-slide.lg-use-left .lg-item { opacity: 0; position: absolute; left: 0; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide { left: -100%; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide { left: 100%; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current { left: 0; opacity: 1; }

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current { -webkit-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; -moz-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; -o-transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s; }

/*======================================
=               Utility                =
======================================*/
/*COLORS*/
/*FONTS*/
.mt-8 { margin-top: 8rem; }

@media (max-width: 767.98px) { .mt-8 { margin-top: 7rem; } }

@media (max-width: 575.98px) { .mt-8 { margin-top: 5rem; } }

.mt-11 { margin-top: 11rem; }

@media (max-width: 767.98px) { .mt-11 { margin-top: 8rem; } }

@media (max-width: 575.98px) { .mt-11 { margin-top: 4rem; } }

.mt-my-5 { margin-top: 5rem; }

@media (max-width: 767.98px) { .mt-my-5 { margin-top: 2rem; } }

.mb-8 { margin-bottom: 8rem; }

.w-60 { min-width: 60%; }

.flex-50 { flex: 1 0 50%; }

.lh-190 { line-height: 190%; }

.img-m { width: 44rem; }

.z-100 { z-index: 100; }

input.hide-arrows[type=number]::-webkit-inner-spin-button, input.hide-arrows[type=number]::-webkit-outer-spin-button { -webkit-appearance: none; margin: 0; }

.max-xs { max-width: 47rem; }

@media (max-width: 767.98px) { .max-xs { width: 100%; max-width: 32.6rem; } }

.hover-under:hover { text-decoration: underline; }

@media (max-width: 767.98px) { .hide-sm { display: none !important; } }

@media (max-width: 991.98px) { .hide-md { display: none !important; } }

.blue-bar { background-color: #233E91; padding: 1.5rem 0; }

/*======================================
=                 Base                 =
======================================*/
*, *::before, *::after { margin: 0; padding: 0; box-sizing: border-box; }

body { overflow-x: hidden; }

@media (hover: none) { body { cursor: pointer; } }

html { font-size: 62.5%; }

body { font-size: 1.6rem; font-family: "Open Sans", sans-serif; }

a { text-decoration: none; color: currentColor; }

a:hover { text-decoration: none; color: currentColor; }

.nav-top-belt a, .nav-top-belt a:hover { text-decoration: none; color: #FFFFFF; }

.footer a:hover { color: #233E91; text-decoration: underline; font-weight: 600; }

.font-ss { font-size: 1.1rem; }

.font-s { font-size: 1.2rem; font-weight: 300; }

.font-sm { font-size: 1.4rem; font-weight: 300; }

@media (max-width: 767.98px) { .font-sm { font-size: 1.3rem; } }

.font-m { font-size: 1.6rem; }

@media (max-width: 1199.98px) { .font-m { font-size: 1.5rem; } }

@media (max-width: 991.98px) { .font-m { font-size: 1.4rem; } }

.font-mab { font-size: 1.8rem; }

@media (max-width: 991.98px) { .font-mab { font-size: 1.6rem; } }

.font-mb { font-size: 2rem; }

.font-ba { font-size: 2.3rem; }

@media (max-width: 991.98px) { .font-ba { font-size: 2rem; } }

.font-b { font-size: 2.4rem; }

@media (max-width: 575.98px) { .font-b { font-size: 2rem; } }

.font-h { font-size: 6.6rem; }

.font-darker { color: #353B40; font-weight: 700; }

.font-grey { color: #838383; }

.font-m-grey { color: #353B40; }

.font-l-grey { color: #2a2a2a99; }

.font-blue { color: #233E91; }

.font-white { color: #FFFFFF; }

.font-red { color: #ED2024; }

.font-400 { font-weight: 400; }

.font-600 { font-weight: 600; }

.font-800 { font-weight: 800; }

.font-bold { font-weight: bold; }

.placeholder-vis::placeholder { color: rgba(42, 42, 42, 0.25) !important; }

.heading-1 { font-size: 4.8rem; text-transform: uppercase; font-weight: 800; line-height: 6rem; letter-spacing: .1rem; }

@media (max-width: 1199.98px) { .heading-1 { font-size: 4.4rem; line-height: 5.5rem; } }

@media (max-width: 575.98px) { .heading-1 { font-size: 3.6rem; line-height: 125%; max-width: 97%; } }

.title-s-left { width: 100%; padding: 2rem 1rem; text-transform: uppercase; }

.link-underline:hover { text-decoration: underline; }

/*======================================
=               Components             =
======================================*/
.three-flex-column { padding: 2rem 3.5rem; display: flex; flex-direction: row; flex-wrap: wrap; font-weight: 600; }

.three-flex-column > * { flex: 0 0 30%; margin-right: 5%; margin-bottom: 1.5rem; display: flex; }

.three-flex-column > *:nth-child(3n) { margin-right: 0; }

@media (max-width: 991.98px) { .three-flex-column > * { flex: 1 0 45%; }
  .three-flex-column > *:nth-child(3n) { margin-right: 3%; }
  .three-flex-column > *:nth-child(2n) { margin-right: 0; } }

@media (max-width: 767.98px) { .three-flex-column > * { flex: 1 0 100%; margin-right: 0; margin-bottom: 1rem; } }

.three-flex-column-left, .three-flex-column-right { width: 50%; }

.three-flex-column-left { margin-right: .5rem; }

.three-flex-column img { margin-right: 1rem; }

@media (max-width: 1199.98px) { .three-flex-column { padding: 1.5rem 2rem; font-size: 1.4rem; } }

@media (max-width: 991.98px) { .three-flex-column-about { width: 100% !important; } }

@media (max-width: 991.98px) { .three-flex-column-about > * { flex: 1 0 30%; margin-right: 3%; margin-bottom: 1.5rem; }
  .three-flex-column-about > *:nth-child(3n) { margin-right: 0; }
  .three-flex-column-about > *:nth-child(2n) { margin-right: 3%; } }

@media (max-width: 767.98px) { .three-flex-column-about > * { flex: 1 0 100%; margin-right: 0 !important; } }

.bar-with-triangle { background-color: #233E91; padding: 2rem 0; position: relative; }

.bar-with-triangle::after { content: ""; display: block; position: absolute; border-left: 25px solid transparent; border-right: 25px solid transparent; border-top: 25px solid #233E91; left: 40%; top: 100%; }

@media (max-width: 991.98px) { .bar-with-triangle::after { left: 50%; transform: translateX(-50%); } }

.bar-with-triangle .search-item:not(.search-item-controls), .bar-with-triangle .search-item:not(.search-item-controls).open { border: 1px solid #FFFFFF; }

.bar-with-triangle .search-item:not(.search-item-controls):hover, .bar-with-triangle .search-item:not(.search-item-controls).open:hover { border: 1px solid #FFFFFF; }

@media (max-width: 991.98px) { .bar-with-triangle > .container { flex-direction: column; }
  .bar-with-triangle > .container > :first-child { margin-bottom: 1rem; }
  .bar-with-triangle > .container > .search-item { flex: 0 0 4rem !important; width: 90%; max-width: 80rem; margin-left: 0 !important; }
  .bar-with-triangle > .container > :last-child { display: none !important; } }

.bottom-nav { height: 4rem; border-bottom: 1px solid rgba(0, 0, 0, 0.15); }

.bottom-nav .nav-item { margin-right: 4.5rem; height: 100%; position: relative; top: 2px; }

.bottom-nav .nav-item .nav-link { display: flex; height: 100%; padding: 0.5rem 0; }

.bottom-nav .nav-item .nav-link.active { background-color: transparent; color: #233E91; font-weight: bold; border-bottom: 3px solid #233E91; border-radius: 0; }

.bottom-nav-content .tab-pane { padding: 0; }

.bottom-nav-content-list li { padding-right: 1rem; position: relative; }

.bottom-nav-content-list li:hover { text-decoration: underline; }

.bottom-nav-content-list li:not(:first-child) { padding-left: 1rem; }

.bottom-nav-content-list li:not(:first-child)::before { display: block; content: ""; position: absolute; left: 0; top: 50%; transform: translateY(-50%); height: 1.4rem; width: .1rem; background-color: #233E91; }

.button-rect { border-radius: 0; padding: 1rem 2rem; }

.button-blue { background-color: #233E91; color: #FFFFFF; border: 2px solid #233E91; font-weight: bold; }

.button-blue:hover { background-color: #FFFFFF; color: #233E91; }

.button-wide { padding: 1rem 9.5rem; }

.country-icon { width: 1.5rem; height: 1rem; box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.25); }

.cta { padding: 1rem 2rem; font-size: 1.5rem; font-weight: inherit; text-transform: uppercase; border-radius: 5rem; }

@media (max-width: 991.98px) { .cta { padding: .7rem 1.5rem; } }

.cta-blue { background-color: #233E91; color: #FFFFFF; }

.cta-blue:hover { background-color: #FFFFFF; color: #233E91; box-shadow: inset 0 0 0 1px #233E91; }

.cta-blue:hover .ion-md-add { border: 1px solid #233E91; }

.cta-blue:focus { box-shadow: inset 0 0 0 1px #233E91; }

.cta-rect { border-radius: 3px; }

.cta span { margin-right: .5rem; }

.cta .ion-md-add { display: flex; align-items: center; justify-content: center; border: 1px solid #FFFFFF; border-radius: 50%; font-size: 1.2rem; width: 1.6rem; height: 1.6rem; }

.custom-checkbox-show { width: 2rem; height: 2rem; border: 0.1rem solid #838383; border-radius: .4rem; cursor: pointer; position: relative; }

.custom-checkbox-show-sell { border-radius: 0; border: 0.2rem solid #233E91; }

.custom-checkbox-show:hover { background-color: #00000011; }

.custom-checkbox-show:after { content: ""; position: absolute; opacity: 0; left: .6rem; top: .1rem; width: .7rem; height: 1.4rem; border: solid #FFFFFF; border-width: 0 2px 2px 0; border-radius: 2px; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg); }

.custom-checkbox-hide { display: none; }

.custom-checkbox-hide:checked ~ .custom-checkbox-show { background-color: #233E91; border: 0.1rem solid #233E91; }

.custom-checkbox-hide:checked ~ .custom-checkbox-show:after { opacity: 1; }

.floating-label-field { border-width: 0; color: #353B40; }

.floating-label-field.floating-label-field-search { font-weight: 700; opacity: 1; }

.floating-label-field:focus { outline: 0; box-shadow: 0; }

.floating-label-field::placeholder { color: transparent; }

.floating-label-field, .floating-label-field + .floating-label { line-height: 1; font: inherit; padding: 1.5em; height: 4em; position: relative; transition: transform .25s, opacity .25s ease-in-out; transform-origin: 0 0; opacity: .5; }

.floating-label-field + .floating-label { cursor: text; position: absolute; top: 50%; transform: translate(0, -58%); left: 0; user-select: none; }

.floating-label-field + .floating-label.floating-label-search { padding: 0; padding-left: 1rem; height: auto; transform: translate(0, -50%); opacity: 0.5; }

.floating-label-field:focus + .floating-label, .floating-label-field:not(:placeholder-shown) + .floating-label { transform: translate(0, -68%) scale(0.8); pointer-events: none; }

.floating-label-field:focus + .floating-label.floating-label-search, .floating-label-field:not(:placeholder-shown) + .floating-label.floating-label-search { transform: translate(0, -120%) scale(0.8); pointer-events: none; }

.floating-label-wrap { position: relative; width: 100%; }

.floating-label-wrap .floating-label-field { width: 100%; }

.floating-label-my { position: absolute; top: 50%; transform: translate(0, -50%); padding-left: 1rem; font-size: 1.2rem; color: #353B40; cursor: text; transition: all .25s; font-weight: 600; pointer-events: none; }

.floating-label-my button { pointer-events: all; }

.floating-label-my-select { top: 53%; text-transform: uppercase; font-weight: bold; padding-left: 4.5rem; }

.floating-label-my-select:hover { color: #FFFFFF; }

.floating-label-my-select.disabled { color: #FFFFFF; }

.floating-label-my-top { top: -1rem; left: 0; padding-left: 0; }

@media (max-width: 575.98px) { .floating-label-my-top { font-size: 1rem; } }

@media (max-width: 328px) { .floating-label-my-top { font-size: .9rem; } }

.floating-label-input-my { height: 100%; width: 100%; border: none; font-size: 1.2rem; font-weight: 600; padding-left: 1rem; padding-top: .6rem; }

.floating-label-input-my-select::placeholder { color: transparent !important; }

.floating-label-input-my-select:focus ~ .floating-label-my, .floating-label-input-my-select:hover ~ .floating-label-my { color: #FFFFFF; }

.floating-label-input-my-select:not(:placeholder-shown) ~ .floating-label-my { opacity: 0; pointer-events: none; }

.floating-label-input-my-select:not(:placeholder-shown) ~ .floating-label-my button { pointer-events: none; }

.floating-label-input-my-top { padding-top: 0; }

.floating-label-input-my::placeholder { color: transparent; }

.floating-label-input-my:focus, .floating-label-input-my:not(:placeholder-shown) { box-shadow: none; outline: none; }

.floating-label-input-my:focus + .floating-label-my, .floating-label-input-my:not(:placeholder-shown) + .floating-label-my { top: 25%; font-size: .8rem; }

.floating-label-input-my:focus + .floating-label-my .font-red, .floating-label-input-my:not(:placeholder-shown) + .floating-label-my .font-red { margin-right: -.8rem; }

.floating-label-input-my:focus + .floating-label-my .font-red, .floating-label-input-my:focus + .floating-label-my sup, .floating-label-input-my:not(:placeholder-shown) + .floating-label-my .font-red, .floating-label-input-my:not(:placeholder-shown) + .floating-label-my sup { opacity: 0; }

.floating-label-input-my:focus + .floating-label-my button, .floating-label-input-my:not(:placeholder-shown) + .floating-label-my button { pointer-events: none; }

.floating-label-input-my:focus + .floating-label-my-top, .floating-label-input-my:not(:placeholder-shown) + .floating-label-my-top { top: -1rem; font-size: 1.2rem; }

.floating-label-input-my:focus + .floating-label-my-top .font-red, .floating-label-input-my:not(:placeholder-shown) + .floating-label-my-top .font-red { margin-right: 0rem; }

.floating-label-input-my:focus + .floating-label-my-top .font-red, .floating-label-input-my:focus + .floating-label-my-top sup, .floating-label-input-my:not(:placeholder-shown) + .floating-label-my-top .font-red, .floating-label-input-my:not(:placeholder-shown) + .floating-label-my-top sup { opacity: 1; }

.info-box { position: absolute; top: 0; left: 0; width: 100%; min-height: 35rem; background-color: #233E91; padding: 2.8rem; display: flex; align-items: center; font-size: 1.8rem; line-height: 160%; color: #FFFFFF; font-weight: 300; z-index: 1000; transition: opacity .25s, background .25s; opacity: 0; pointer-events: none; }

@media (max-width: 991.98px) { .info-box { width: 40rem; left: initial; right: 0; min-height: 25rem; border-radius: 1.5rem; } }

@media (max-width: 575.98px) { .info-box { width: 90%; max-width: 30rem; left: 50%; transform: translateX(-50%); } }

.info-box-icon { position: absolute; top: 1.2rem; left: 1.2rem; width: 3.8rem; }

@media (max-width: 575.98px) { .info-box-icon { width: 2.7rem; } }

.info-box-close { position: absolute; top: 1.2rem; right: 1.2rem; width: 3rem; cursor: pointer; }

@media (max-width: 575.98px) { .info-box-close { width: 2.2rem; } }

.info-box-invalid { background-color: #FF9431; }

.input-clean { border: 0; outline: 0; box-shadow: none; font-size: inherit; font-family: inherit; }

.input-clean:focus { border: 0; outline: 0; box-shadow: none; }

.google-map { width: 100%; height: 40rem; }

.nav-height { width: 100%; height: 9.2rem; opacity: 0; }

.offers-left { height: 43rem; flex: 1 0 35rem; margin-right: 1.5rem; background: url("../img/offers-left.png"); }

@media (max-width: 1199.98px) { .offers-left { height: 43rem; flex: 1 0 30%; } }

@media (max-width: 991.98px) { .offers-left { height: 33rem; } }

@media (max-width: 767.98px) { .offers-left { width: 100%; flex: 1 0 15.5rem; margin-right: 0; margin-bottom: 1.5rem; background: url("../img/offers-left-mobile.png"); background-size: 110% !important; background-position: 50% 80% !important; } }

@media (max-width: 575.98px) { .offers-left { margin-bottom: 1rem; background-size: 125% !important; } }

.offers-container { width: 110rem; margin-top: 10rem; }

@media (max-width: 1199.98px) { .offers-container { margin-top: 5rem; width: 96rem; } }

@media (max-width: 991.98px) { .offers-container { width: 72rem; } }

@media (max-width: 767.98px) { .offers-container { width: 54rem; flex-direction: column; } }

@media (max-width: 991.98px) { .offers-container { width: 90%; } }

.offers-right { flex-wrap: wrap; flex: 1 0 66%; align-content: space-between; }

.offers-right-parts { background: url("../img/offers-parts.png"); }

.offers-right-finance { background: url("../img/offers-finance.png"); }

.offers-right-sell { background: url("../img/offers-sell.png"); }

.offers-right-rent { background: url("../img/offers-rent.png"); }

.offers-right > * { flex: 1 0 35rem; height: 20.7rem; text-align: center; }

.offers-right > *:nth-child(2n+1) { margin-right: 1.5rem; }

@media (max-width: 1199.98px) { .offers-right > * { height: 48.3%; flex: 1 0 45%; } }

@media (max-width: 767.98px) { .offers-right > * { margin-bottom: 1.5rem; height: 15.5rem; } }

@media (max-width: 575.98px) { .offers-right > * { margin-bottom: 1rem; } }

.offers-left, .offers-right > * { color: #fff; transition: all .25s ease-out; background-size: 104%; background-position: 50% 50%; display: flex; align-items: center; justify-content: center; color: #FFFFFF; font-size: 2.4rem; text-transform: uppercase; font-weight: 800; cursor: pointer; }

.offers-left:hover, .offers-right > *:hover { background-size: 130%; color: #fff; }

@media (max-width: 1199.98px) { .offers-left, .offers-right > * { background-size: 125%; }
  .offers-left:hover, .offers-right > *:hover { background-size: 140%; } }

@media (max-width: 991.98px) { .offers-left, .offers-right > * { background-size: 160%; font-size: 2rem; }
  .offers-left:hover, .offers-right > *:hover { background-size: 180%; } }

@media (max-width: 575.98px) { .offers-left, .offers-right > * { background-size: 230%; font-size: 1.6rem; } }

@media (max-width: 320px) { .offers-left, .offers-right > * { font-size: 1.5rem; } }

.podium { display: flex; justify-content: space-around; align-items: center; }

@media (max-width: 767.98px) { .podium { flex-direction: column; } }

.podium > * { display: flex; flex-direction: column; justify-content: flex-start; align-items: center; text-align: center; position: relative; overflow: hidden; }

.podium > * > a { position: absolute; left: 50%; transform: translateX(-50%); z-index: 20; width: 22rem; }

@media (max-width: 991.98px) { .podium > * > a { width: 20rem; } }

.podium > * > a button { width: 100%; padding: 1rem 0; border-radius: 0; }

@media (max-width: 767.98px) { .podium > * { width: 70% !important; height: 40rem !important; margin-bottom: 3.5rem; padding: 3.5rem !important; padding-bottom: 1.6rem !important; }
  .podium > *:nth-child(2) { order: -1; } }

@media (max-width: 575.98px) { .podium > * { width: 70% !important; height: 36rem !important; margin-bottom: 3.5rem; padding: 2.5rem !important; padding-bottom: 1.3rem !important; }
  .podium > *:nth-child(2) { height: 45rem !important; } }

@media (max-width: 470px) { .podium > * { width: 100% !important; height: 40rem !important; } }

.podium > :first-child, .podium > :last-child { width: 25.5rem; height: 46rem; padding: 1.7rem; padding-top: 3.5rem; }

@media (max-width: 991.98px) { .podium > :first-child, .podium > :last-child { width: 20rem; padding: 1.1rem; padding-top: 3.5rem; } }

.podium > :first-child > a, .podium > :last-child > a { bottom: 1.7rem; }

@media (max-width: 991.98px) { .podium > :first-child > a, .podium > :last-child > a { width: 18rem; } }

.podium > :first-child img:first-child, .podium > :last-child img:first-child { width: 9rem; }

@media (max-width: 991.98px) { .podium > :first-child img:first-child, .podium > :last-child img:first-child { width: 6rem; } }

.podium > :nth-child(2) { width: 29rem; height: 52rem; padding: 3rem 3.5rem 1.6rem 3.5rem; }

@media (max-width: 991.98px) { .podium > :nth-child(2) { width: 23rem; padding: 3rem 1.5rem 1.6rem 1.5rem; z-index: 100; } }

.podium > :nth-child(2) > a { bottom: 1.6rem; }

.podium > :nth-child(2) img:first-child { width: 10rem; }

@media (max-width: 991.98px) { .podium > :nth-child(2) img:first-child { width: 9rem; } }

.search-item { flex: 1 0 20rem; height: 4rem; display: flex; justify-content: flex-start; align-items: center; }

@media (max-width: 991.98px) { .search-item { flex: 1 0 30%; } }

@media (max-width: 767.98px) { .search-item { flex: 1 0 45%; } }

@media (max-width: 575.98px) { .search-item { flex: 1 0 100%; } }

.search-item img { pointer-events: none; }

.search-item .dropdown-el-container { position: absolute; right: 0; top: 0; height: 100%; width: 6.6rem; border-left: 1px solid rgba(0, 0, 0, 0.25); }

.search-item .dropdown-el-container label { background-color: #FFFFFF; color: #000000; padding-top: 1rem; height: 3.8rem; }

.search-item .dropdown-el { min-height: 100%; max-height: 100%; background-color: #FFFFFF; }

.search-item .dropdown-el.expanded { border: 1px solid rgba(0, 0, 0, 0.25); }

.search-item .dropdown-el.expanded label:hover { background-color: #e6e6e6; }

.search-item .dropdown-el label:nth-child(2) { margin-top: 3.8rem; }

.search-item .dropdown-el::before { content: ""; position: absolute; right: 0.3rem; top: 0.9rem; border: 0.3em solid #000000; border-color: #000000 transparent transparent transparent; transition: .4s all ease-in-out; transform-origin: 50% .2rem; }

.search-item .dropdown-el::after { top: 1.7rem; border-color: #000000 transparent transparent transparent; }

.search-item-spacing { margin-right: 2.5rem; margin-bottom: 2.5rem; }

@media (max-width: 1199.98px) { .search-item-spacing { margin-right: 1.8rem; margin-bottom: 1.8rem; } }

.search-item-spacing:nth-child(4n) { margin-right: 0; }

.search-item-spacing-2:nth-child(2n) { margin-right: 0; }

.search-item-spacing-2:nth-last-child(1), .search-item-spacing-2:nth-last-child(2) { margin-bottom: 0; }

@media (max-width: 991.98px) { .search-item-spacing-2:nth-child(4n) { margin-right: 0 !important; }
  .search-item-spacing-2:nth-child(3n) { margin-right: 1.8rem !important; }
  .search-item-spacing-2:nth-child(2n) { margin-right: 0 !important; } }

@media (max-width: 575.98px) { .search-item-spacing-2 { margin-right: 0 !important; flex: 1 0 100% !important; }
  .search-item-spacing-2:nth-last-child(2) { margin-bottom: 1.8rem; } }

@media (max-width: 991.98px) { .search-item-spacing:nth-child(4n) { margin-right: 1.8rem; }
  .search-item-spacing:nth-child(3n) { margin-right: 0; } }

@media (max-width: 767.98px) { .search-item-spacing:nth-child(even) { margin-right: 0; }
  .search-item-spacing:nth-child(odd) { margin-right: 1.8rem; } }

@media (max-width: 575.98px) { .search-item-spacing { margin-right: 0; } }

.search-item-split > * { padding: 1rem; width: 50%; }

.search-item-split > *:first-child { border-right: 1px solid rgba(0, 0, 0, 0.25); }

.search-item-year.search-item-split .floating-label-wrap input { font-weight: 700; padding-top: 0; }

.search-item-year.search-item-split .floating-label-wrap input::placeholder { font-weight: 400; color: #909294; }

.search-item-year.search-item-split .floating-label-wrap .filterable-select-value-list li { font-weight: 600; margin-right: .5rem; margin-left: .5rem; padding: 0 1rem; }

@media (max-width: 1199.98px) { .search-item-year.search-item-split .floating-label-wrap .filterable-select-value-list li { padding: 0 .5rem; } }

.search-item-select { position: relative; }

.search-item-select.open img { filter: brightness(0) invert(1) !important; }

.search-item-select.filled > input { background-color: #e6e9f2; }

.search-item-select .filterable-select:disabled { background-color: #838383; color: #FFFFFF; cursor: not-allowed; }

.search-item-select .filterable-select:disabled::-webkit-input-placeholder { color: #FFFFFF; }

.search-item-select .filterable-select:disabled ~ img { filter: brightness(0) invert(1); }

.search-item-select:hover .filterable-select:not(:disabled) { background-color: #233E91; color: #FFFFFF; cursor: pointer; }

.search-item-select:hover .filterable-select:not(:disabled)::-webkit-input-placeholder { color: transparent; }

.search-item-select:hover .filterable-select:disabled { background-color: #aaaaaa; }

.search-item-select:hover .floating-label-my-select { color: #FFFFFF; }

.search-item-select:hover img { filter: brightness(0) invert(1); }

.search-item-select-icon { position: absolute; left: 1rem; top: 50%; transform: translateY(-50%); z-index: 5; }

.search-item-select-arrow { width: .7rem; height: .4rem; position: absolute; right: 1rem; top: 50%; transform: translateY(-50%); /*HIDE ARROW*/ }

.search-item-select.simple-select { font-weight: bold; text-transform: uppercase; padding: 0 2rem; cursor: pointer; }

.search-item-select.simple-select img { margin-right: 1rem; }

.search-item-clear { display: flex; justify-content: center; align-items: center; width: 4.8rem; height: 4rem; border: 2px solid #233E91; border-radius: 3px; margin-right: 2.5rem; }

@media (max-width: 1199.98px) { .search-item-clear { margin-right: 1.8rem; } }

.search-item-clear:hover { background-color: #233E91; }

.search-item-clear:hover path { fill: #FFFFFF; }

.search-item-search { display: flex; justify-content: center; align-items: center; flex: 1 0 auto; background-color: #233E91; color: #FFFFFF; font-weight: 600; font-size: inherit; height: 100%; border: 2px solid #233E91; }

.search-item-search:hover { background-color: #FFFFFF; color: #233E91; }

.search-item-search:hover path { fill: #233E91; }

.search-item-search > div { position: relative; }

.search-item-search .svg-conv { width: 2rem; margin-left: .3rem; }

.search-item:not(.search-item-controls) { border: 1px solid rgba(0, 0, 0, 0.25); border-radius: 3px; }

.search-item:not(.search-item-controls) input:not(.filterable-select) { border-radius: 3px; }

.search-item-container { display: flex; flex-wrap: wrap; width: 110rem; padding: 3.6rem; padding-bottom: 1.1rem; box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.15); margin-bottom: 6rem; }

.search-item-container .fake-element { flex: 1 0 20rem; height: 4rem; display: none; }

@media (max-width: 991.98px) { .search-item-container .fake-element { flex: 1 0 30%; } }

@media (max-width: 767.98px) { .search-item-container .fake-element { flex: 1 0 45%; } }

@media (max-width: 575.98px) { .search-item-container .fake-element { flex: 1 0 100%; } }

@media (max-width: 1199.98px) { .search-item-container { width: 96rem; } }

@media (max-width: 991.98px) { .search-item-container { width: 72rem; } }

@media (max-width: 767.98px) { .search-item-container { width: 54rem; } }

@media (max-width: 575.98px) { .search-item-container { width: 100%; max-width: 34.6rem; padding-bottom: 3rem; } }

@media (max-width: 991.98px) { .search-item-container .search-item-select-arrow { right: 1rem; } }

.search-item-container .floating-label-wrap { padding: 0; height: 100%; }

.search-item-container .floating-label-wrap.filled { border-radius: 0; }

.search-item-container .floating-label-wrap.filled:not(.open):not(:hover) > input { background-color: #e6e9f2; }

.search-item-container .floating-label-wrap input { height: 100%; padding: 0; padding-left: 1.5rem; padding-top: .6rem; }

@media (max-width: 1199.98px) { .search-item-container .floating-label-wrap input { padding-left: 1rem; } }

.search-item-container .floating-label-wrap input:focus + .floating-label.floating-label-search, .search-item-container .floating-label-wrap input:not(:placeholder-shown) + .floating-label.floating-label-search { transform: translate(0, -100%) scale(0.8); }

.search-item-container .floating-label-wrap input:disabled { background-color: #838383; cursor: not-allowed; }

.search-item-container .floating-label-wrap .floating-label { padding-left: 1.6rem; font-weight: 600; }

@media (max-width: 1199.98px) { .search-item-container .floating-label-wrap .floating-label { padding-left: 1rem; } }

.search-item-container .floating-label-wrap .floating-label.disabled { color: #FFFFFF; opacity: 1; }

.section-belt { position: absolute; top: 0; left: 50%; transform: translate(-50%, -50%); width: 92rem; height: 9rem; justify-self: center; text-align: center; z-index: 100; font-weight: 600; background-color: #FFFFFF; box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.38); }

@media (max-width: 1199.98px) { .section-belt { width: 85rem; } }

@media (max-width: 991.98px) { .section-belt { width: 68rem; } }

@media (max-width: 767.98px) { .section-belt { position: static; transform: none; width: 34rem; flex-direction: column; height: auto; padding: 1.7rem 3.8rem; } }

@media (max-width: 350px) { .section-belt { width: 30rem; padding: 1.7rem 2.5rem; } }

@media (max-width: 310px) { .section-belt { width: 28rem; } }

.section-belt .simple-select { border: none; }

.section-belt > * { display: flex; justify-content: center; align-items: center; }

.section-belt > *:not(:last-child):not(:nth-last-child(2)) { border-right: 1px solid rgba(0, 0, 0, 0.25); }

@media (max-width: 767.98px) { .section-belt > *:not(:last-child):not(:nth-last-child(2)) { border: none; } }

@media (max-width: 767.98px) { .section-belt > * { flex: 0 0 4.5rem !important; margin-bottom: 1.5rem; } }

@media (max-width: 991.98px) { .section-belt .search-item-select-arrow { right: 0rem; } }

.section-belt .search-item { margin: 0; max-width: 100%; height: 100%; border: none; border-radius: 0 !important; }

@media (max-width: 767.98px) { .section-belt .search-item { height: 4.5rem; } }

.section-belt .search-item .search-item-select-icon { left: 2.5rem; }

@media (max-width: 991.98px) { .section-belt .search-item .search-item-select-icon { left: 1.2rem; } }

.section-belt .search-item input { font-size: 1.4rem; padding-left: 3rem; padding-right: 3rem; }

@media (max-width: 991.98px) { .section-belt .search-item input { font-size: 1.2rem; padding-left: 2rem; padding-right: 2rem; } }

@media (max-width: 767.98px) { .section-belt .search-item input { border: 1px solid rgba(0, 0, 0, 0.25); height: 100%; border-radius: 3px; } }

.section-belt .search-item .filterable-select-value-list { margin-top: 9rem; }

@media (max-width: 767.98px) { .section-belt .search-item .filterable-select-value-list { margin-top: 4.5rem; } }

.section-belt-brand .search-item .search-item-select-icon { left: 2rem; }

@media (max-width: 991.98px) { .section-belt-brand .search-item .search-item-select-icon { left: 1rem; } }

@media (max-width: 767.98px) { .section-belt-brand .search-item .search-item-select-icon { left: 2rem; } }

.section-belt-brand .search-item input { font-size: 1.4rem; padding-left: 3rem; padding-right: 3rem; }

@media (max-width: 991.98px) { .section-belt-brand .search-item input { font-size: 1.2rem; padding-left: 2rem; padding-right: 2rem; } }

.section-belt-machine-type { text-transform: uppercase; flex: 1 0 21rem; }

.section-belt-machine-type img { margin-right: 1rem; }

@media (max-width: 1199.98px) { .section-belt-machine-type { flex: 1 0 18rem; } }

@media (max-width: 991.98px) { .section-belt-machine-type { flex: 1 0 15.5rem; } }

.section-belt-brand { text-transform: uppercase; flex: 1 0 15rem; }

.section-belt-brand img { margin-right: 1rem; }

@media (max-width: 991.98px) { .section-belt-brand { flex: 1 0 11.4rem; } }

.section-belt-year.search-item-year { flex: 1 0 28.3rem; }

@media (max-width: 767.98px) { .section-belt-year.search-item-year { border: 1px solid rgba(0, 0, 0, 0.25) !important; } }

@media (max-width: 767.98px) { .section-belt-year.search-item-year .floating-label-wrap { height: 4.3rem; } }

.section-belt-year.search-item-year .floating-label-wrap input { padding: 1rem .3rem 1rem 1.5rem; font-size: 1.4rem; }

.section-belt-year.search-item-year .floating-label-wrap input::placeholder { color: rgba(42, 42, 42, 0.55); }

@media (max-width: 991.98px) { .section-belt-year.search-item-year .floating-label-wrap input { padding: 1rem .3rem 1rem .7rem; font-size: 1.2rem; } }

@media (max-width: 767.98px) { .section-belt-year.search-item-year .floating-label-wrap input { border: none; padding: 1rem .3rem 1rem 1.5rem; } }

.section-belt-year.search-item-year .floating-label-wrap input:focus { border: 1px solid #233E91; }

.section-belt-year.search-item-year .floating-label-wrap .filterable-select-value-list { margin-top: 9rem; }

@media (max-width: 767.98px) { .section-belt-year.search-item-year .floating-label-wrap .filterable-select-value-list { margin-top: 4.5rem; } }

.section-belt-year.search-item-year > :first-child { border-right: none; position: relative; }

@media (max-width: 767.98px) { .section-belt-year.search-item-year > :first-child { border-right: 1px solid rgba(0, 0, 0, 0.25) !important; } }

.section-belt-year.search-item-year > :first-child::after { content: ""; display: block; position: absolute; right: 0; top: 50%; transform: translateY(-50%); height: 3rem; border-right: 1px solid rgba(0, 0, 0, 0.25); }

@media (max-width: 767.98px) { .section-belt-year.search-item-year > :first-child::after { display: none; } }

@media (max-width: 767.98px) { .section-belt-year.search-item-year > :first-child { border-right: none; } }

.section-belt-year.search-item-year > * { padding: 0; height: 100%; }

.section-belt-year.search-item-year > * input { height: 100%; }

@media (max-width: 1199.98px) { .section-belt-year.search-item-year { flex: 1 0 25rem; } }

@media (max-width: 991.98px) { .section-belt-year.search-item-year { font-size: 1.2rem; flex: 1 0 20.5rem; } }

.section-belt-advanced { flex: 1 0 17.1rem; transition: all .25s; }

.section-belt-advanced:hover { background-color: #233E91; color: #FFFFFF; }

@media (max-width: 1199.98px) { .section-belt-advanced { flex: 1 0 14rem; } }

@media (max-width: 991.98px) { .section-belt-advanced { font-size: 1.2rem; flex: 1 0 11rem; } }

@media (max-width: 767.98px) { .section-belt-advanced { order: 5; color: #233E91; font-weight: 600; margin-bottom: 0; } }

.section-belt-search { flex: 1 0 10.6rem; background-color: #233E91; transition: all .25s; border-radius: 0; }

.section-belt-search:hover { background-color: #FFFFFF; border: 2px solid #233E91; }

.section-belt-search:hover path { fill: #233E91; }

@media (max-width: 991.98px) { .section-belt-search { flex: 1 0 9rem; } }

@media (max-width: 767.98px) { .section-belt-search { border-radius: 3px; margin-bottom: 0; }
  .section-belt-search .svg-conv { width: 2rem; } }

.filterable-select, .filterable-select-value-list { position: absolute; top: 0; left: 0; width: 100%; }

.filterable-select { text-transform: uppercase; font-weight: 700; height: 100%; font-size: 1.2rem; padding: 1rem 2rem 1rem 4.5rem; background-color: #FFFFFF; transition: .3s ease-in-out; border: none; }

.filterable-select::-webkit-input-placeholder { color: #353B40; }

.filterable-select:hover:not(:disabled), .filterable-select:focus, .filterable-select.open { outline: 0; background-color: #233E91; color: #FFFFFF; }

.filterable-select:hover:not(:disabled):not(.floating-label-input-my-select)::-webkit-input-placeholder, .filterable-select:focus:not(.floating-label-input-my-select)::-webkit-input-placeholder, .filterable-select.open:not(.floating-label-input-my-select)::-webkit-input-placeholder { color: #ffffffbb !important; }

.filterable-select:hover:not(:disabled) ~ img, .filterable-select:focus ~ img, .filterable-select.open ~ img { filter: brightness(0) invert(1); }

.filterable-select-value-list { list-style: none; margin-top: 4rem; box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2); overflow: hidden; max-height: 0; transition: all .3s ease-in-out; background-color: #FFFFFF; z-index: 30; }

.filterable-select-value-list.open { z-index: 10; max-height: 22rem; overflow: auto; overflow-x: hidden; }

.filterable-select-value-list.open::-webkit-scrollbar-track { background-color: #E6E6E6; }

.filterable-select-value-list.open::-webkit-scrollbar { background-color: #CACACA; width: .8rem; margin-right: 2rem; }

.filterable-select-value-list.open::-webkit-scrollbar-thumb { background-color: #CACACA; }

.filterable-select-value-list li { text-align: left; margin-left: 1.3rem; margin-right: 1.3rem; text-transform: uppercase; position: relative; background-color: transparent; padding: 0 2rem; height: 4rem; font-size: 1.2rem; font-weight: 700; display: flex; align-items: center; cursor: pointer; opacity: 1; color: #838383; }

@media (max-width: 991.98px) { .filterable-select-value-list li { padding: 1rem 1.5rem; margin: 0 .5rem; } }

.filterable-select-value-list li:not(:last-child) { border-bottom: 1px solid rgba(0, 0, 0, 0.15); }

.filterable-select-value-list li:hover { color: #FFFFFF; }

.filterable-select-value-list li:hover::after { content: ""; display: block; position: absolute; top: 0; left: -50%; z-index: -1; width: 250%; height: 100%; background-color: #233E91; color: #FFFFFF; }

.filterable-select-value-list li.closed { max-height: 0; display: none; padding: 0; opacity: 0; }

/*NAV SELECT*/
.dropdown-el { width: 100%; position: absolute; top: 0; left: 0; display: inline-block; min-height: 2rem; max-height: 2rem; overflow: hidden; cursor: pointer; text-align: left; white-space: nowrap; color: #FFFFFF; z-index: 10; outline: none; background-color: #233E91; transition: 0.25s all ease-in-out; }

.dropdown-el img { pointer-events: none; }

.dropdown-el-container { width: 5.5rem; height: 2rem; }

.dropdown-el-container-lang { width: 11rem; }

.dropdown-el input:focus + label { background: #def; }

.dropdown-el input { width: 1px; height: 1px; display: inline-block; position: absolute; opacity: 0.01; }

.dropdown-el label { display: block; height: 2em; padding-left: 1rem; cursor: pointer; position: relative; transition: 0.25s color ease-in-out; margin-bottom: 0; }

.dropdown-el label:nth-child(2) { margin-top: 2em; }

.dropdown-el input:checked + label { display: block; border-top: none; position: absolute; top: 0; width: 100%; }

.dropdown-el input:checked + label:nth-child(2) { margin-top: 0; position: relative; }

.dropdown-el::after { content: ""; position: absolute; right: 0.3rem; top: 0.9rem; border: 0.3em solid #FFFFFF; border-color: #FFFFFF transparent transparent transparent; transition: .4s all ease-in-out; transform-origin: 50% .2rem; }

.dropdown-el.expanded { padding: 0; box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 5px 0px; max-height: 15em; }

.dropdown-el.expanded label:hover { background-color: #566fbb; }

.dropdown-el.expanded::after { transform: rotate(-180deg); }

/*SORTING SELECT*/
.select-box { position: relative; display: block; width: 17rem; font-family: 'Open Sans', sans-serif; color: black; border: 1px solid rgba(0, 0, 0, 0.15); border-radius: 3px; margin-left: .5rem; z-index: 1; }

.select-box__current { position: relative; box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1); cursor: pointer; outline: none; }

.select-box__current:focus + .select-box__list { opacity: 1; animation-name: none; }

.select-box__current:focus + .select-box__list .select-box__option { cursor: pointer; }

.select-box__current:focus .select-box__icon { transform: translateY(-50%) rotate(180deg); }

.select-box__icon { position: absolute; top: 50%; right: .5rem; transform: translateY(-50%); width: 7px; transition: 0.2s ease; }

.select-box__value { display: flex; }

.select-box__input { display: none; }

.select-box__input:checked + .select-box__input-text { display: block; }

.select-box__input-text { display: none; width: 100%; margin: 0; padding: 5px 5px 5px 10px; background-color: #fff; }

.select-box__list { position: absolute; width: 100%; padding: 0; list-style: none; opacity: 0; animation-name: HideList; animation-duration: 0.5s; animation-delay: 0.5s; animation-fill-mode: forwards; animation-timing-function: step-start; box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1); }

.select-box__option { display: block; padding: 5px 5px 5px 10px; background-color: #fff; margin: 0; border: 1px solid rgba(0, 0, 0, 0.15); border-top: 0; }

.select-box__option:hover, .select-box__option:focus { color: #FFFFFF; background-color: #233E91; }

@keyframes HideList { from { transform: scaleY(1); }
  to { transform: scaleY(0); } }

.selected-cars-dropdown { position: fixed; right: 0; top: 20%; height: 6rem; min-width: 8rem; max-width: 8rem; display: flex; justify-content: flex-start; align-items: center; background-color: #233E91; color: #fff; font-weight: 600; cursor: pointer; transition: all .35s; z-index: 90; font-size: 1.6rem; }

@media (max-width: 1199.98px) { .selected-cars-dropdown { height: 5rem; min-width: 7rem; max-width: 7rem; } }

@media (max-width: 991.98px) { .selected-cars-dropdown { height: 4.5rem; min-width: 6.5rem; max-width: 6.5rem; font-size: 1.4rem; } }

.selected-cars-dropdown-icon { width: 2.5rem; height: 2.5rem; margin-right: 1rem; }

@media (max-width: 1199.98px) { .selected-cars-dropdown-icon { width: 2rem; height: 2rem; } }

.selected-cars-dropdown-go { transition: all .35s; position: absolute; background-color: #FFFFFF; color: #233E91; height: 100%; width: 100%; overflow: hidden; font-weight: 700; box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25); top: 0; left: 0; z-index: -1; display: flex; justify-content: center; align-items: center; }

.selected-cars-dropdown-go:hover { color: #233E91bb; }

.selected-cars-dropdown-title { min-width: 25.5rem; transition: all .35s; background-color: #233E91; height: 100%; padding: 1rem; display: flex; align-items: center; }

.selected-cars-dropdown-num { position: absolute; right: 0; padding-right: 1rem; display: flex; align-items: center; top: 50%; transform: translateY(-50%); height: 100%; width: 4rem; background-color: #233E91; justify-content: center; }

@media (max-width: 991.98px) { .selected-cars-dropdown-num { width: 2.5rem; } }

.selected-cars-dropdown.open { max-width: 40rem; }

.selected-cars-dropdown.open .selected-cars-dropdown-go { animation: drop .35s .35s; animation-fill-mode: forwards; }

.selected-cars-dropdown.open .selected-cars-dropdown-title { max-width: 30rem; }

@keyframes drop { 0% { top: 0; }
  100% { top: 100%; } }

/* The side navigation menu */
.sidenav { height: 100%; /* 100% Full-height */ max-height: 100%; width: 25rem; position: fixed; /* Stay in place */ z-index: 2000; /* Stay on top */ top: 0; /* Stay at the top */ left: -27.2rem; background-color: #FFFFFF; /* Black*/ overflow-x: hidden; /* Disable horizontal scroll */ transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */ box-shadow: 7px 0px 10px rgba(0, 0, 0, 0.25); padding-top: 4rem; display: flex; flex-direction: column; align-items: center; /*     @include media-breakpoint-down(lg){ width: 35rem; } @include media-breakpoint-down(md){ width: 30rem; padding-top: 6rem;  }*/ }

.sidenav > *#nav-menu-item-sell { margin-bottom: 2rem; }

.sidenav > *#nav-menu-item-sell .btn { width: 21.5rem; justify-content: center; }

.sidenav > *.stuffed-flex-bar { width: 100%; min-height: 12rem; overflow-x: scroll; align-self: flex-start; /*
        &::-webkit-scrollbar-track{
            background-color: #E6E6E6;
        }
    
        &::-webkit-scrollbar{
            background-color: #CACACA;
            width: .6rem;
        }
    
        &::-webkit-scrollbar-thumb{
            background-color: #CACACA;
        }
*/ }

.sidenav > *.stuffed-flex-bar > * { flex: 0 0 13rem; }

.sidenav > *.nav-menu-item-container { margin-top: 2.3rem; flex-direction: column; width: 100%; height: auto; top: 0; }

.sidenav > *.nav-menu-item-container .current { background-color: #233E91; color: #FFFFFF; }

.sidenav > *.nav-menu-item-container .current:hover a { color: #FFFFFF; }

.sidenav > *.nav-menu-item-container > *, .sidenav > *.nav-menu-item-container > *.nav-menu-item-expandable { width: 100%; margin: 0; height: 4rem; }

.sidenav > *.nav-menu-item-container > * a, .sidenav > *.nav-menu-item-container > *.nav-menu-item-expandable a { justify-content: center; padding: 1.5rem 0; }

.sidenav > *.nav-menu-item-container > *:hover, .sidenav > *.nav-menu-item-container > *.nav-menu-item-expandable:hover { box-shadow: none; }

.sidenav > *.nav-menu-item-container > *.open, .sidenav > *.nav-menu-item-container > *.nav-menu-item-expandable.open { background-color: #233E91; }

.sidenav > *.nav-menu-item-container > *.open > a, .sidenav > *.nav-menu-item-container > *.nav-menu-item-expandable.open > a { color: #FFFFFF; }

.sidenav > *.nav-menu-item-container > *.open > a span::after, .sidenav > *.nav-menu-item-container > *.nav-menu-item-expandable.open > a span::after { border-color: #FFFFFF transparent transparent transparent; transform: rotate(-180deg); }

.sidenav > *.nav-menu-item-container .sub-menu li { text-align: center; }

.sidenav > *:last-child { margin-top: auto; margin-bottom: 2rem; }

.sidenav-open { display: none; z-index: 100; width: 4rem; height: 3rem; position: absolute; top: 50%; left: 1rem; transition: all .25s; -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: translateY(-50%) rotate(0deg); -webkit-transition: .5s ease-in-out; -moz-transition: .5s ease-in-out; -o-transition: .5s ease-in-out; transition: .5s ease-in-out; cursor: pointer; }

@media (max-width: 991.98px) { .sidenav-open { width: 3rem; height: 2rem; display: block; } }

.sidenav-open span { display: block; position: absolute; height: .6rem; width: 100%; background: #233E91; border-radius: 0; opacity: 1; left: 0; -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -o-transform: rotate(0deg); transform: rotate(0deg); -webkit-transition: .25s ease-in-out; -moz-transition: .25s ease-in-out; -o-transition: .25s ease-in-out; transition: .25s ease-in-out; }

@media (max-width: 991.98px) { .sidenav-open span { height: .4rem; border-radius: 0; } }

.sidenav-open span:nth-child(1) { top: 0rem; -webkit-transform-origin: left center; -moz-transform-origin: left center; -o-transform-origin: left center; transform-origin: left center; }

.sidenav-open span:nth-child(2) { top: 1.2rem; -webkit-transform-origin: left center; -moz-transform-origin: left center; -o-transform-origin: left center; transform-origin: left center; }

@media (max-width: 991.98px) { .sidenav-open span:nth-child(2) { top: 1rem; } }

.sidenav-open span:nth-child(3) { top: 2.4rem; -webkit-transform-origin: left center; -moz-transform-origin: left center; -o-transform-origin: left center; transform-origin: left center; }

@media (max-width: 991.98px) { .sidenav-open span:nth-child(3) { top: 2rem; } }

.sidenav-open.open { position: absolute; right: 5rem; }

@media (max-width: 991.98px) { .sidenav-open.open { right: 3rem; } }

.sidenav-open.open span { position: absolute; right: 5rem; }

.sidenav-open.open span:nth-child(1) { -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -o-transform: rotate(45deg); transform: rotate(45deg); top: -.3rem; }

@media (max-width: 991.98px) { .sidenav-open.open span:nth-child(1) { top: -.3rem; } }

.sidenav-open.open span:nth-child(2) { width: 0%; opacity: 0; }

.sidenav-open.open span:nth-child(3) { -webkit-transform: rotate(-45deg); -moz-transform: rotate(-45deg); -o-transform: rotate(-45deg); transform: rotate(-45deg); top: 2.6rem; }

@media (max-width: 991.98px) { .sidenav-open.open span:nth-child(3) { top: 1.8rem; } }

.stuffed-flex-bar { display: flex; box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.25); width: 110rem; font-weight: 400; }

@media (max-width: 1199.98px) { .stuffed-flex-bar:not(.sidenav-flex-bar) { width: 96rem; }
  .stuffed-flex-bar:not(.sidenav-flex-bar) > div > * { justify-content: flex-end; } }

.stuffed-flex-bar img { max-height: 6.2rem; max-width: 100%; }

.stuffed-flex-bar span { display: flex; height: 2rem; line-height: 110%; justify-content: center; align-items: center; text-align: center; }

@media (max-width: 991.98px) { .stuffed-flex-bar:not(.sidenav-flex-bar) { width: 72rem; }
  .stuffed-flex-bar:not(.sidenav-flex-bar) img { max-height: 5rem; max-width: 90%; } }

@media (max-width: 767.98px) { .stuffed-flex-bar:not(.sidenav-flex-bar) { display: none; } }

.stuffed-flex-bar-8 > div { display: flex; flex: 1 0 12.5%; cursor: pointer; transition: all .25s; }

.stuffed-flex-bar-8 > div > * { width: 100%; display: flex; flex-direction: column; padding: 2rem .1rem 1.3rem .1rem; align-items: center; justify-content: flex-end; text-align: center; }

@media (max-width: 991.98px) { .stuffed-flex-bar-8 > div > * { padding: 1.5rem .4rem 1rem .4rem; } }

.stuffed-flex-bar-8 > div:hover, .stuffed-flex-bar-8 > div.active { background-color: #233E91; color: #FFFFFF; }

.stuffed-flex-bar-8 > div:hover img, .stuffed-flex-bar-8 > div.active img { filter: brightness(0) invert(1); }

.stuffed-flex-bar-8 > div img { margin-bottom: 1rem; }

.stuffed-flex-bar-8 > div:not(:last-child) { border-right: 1px solid rgba(0, 0, 0, 0.25); }

.stuffed-flex-bar.sidenav-flex-bar a { padding-top: 0; }

.svg { transition: all .25s; }

.svg-white { filter: brightness(0) invert(1); }

.svg-conv { transition: all .25s; }

.svg-conv-blue path { fill: #233E91; }

.svg-conv-white path { fill: #FFFFFF; }

.svg-conv-black path { fill: #000000; }

.svg-heart-box { position: relative; width: 2.5rem; flex: 0 0 2.5rem !important; margin-right: 1rem; transition: all .25s; }

.svg-heart-box:hover { transform: translateY(-10%); }

.svg-heart-empty, .svg-heart-fill { width: 2.5rem; height: 2.5rem; margin: 0; }

.svg-heart-fill { position: absolute; left: 0; top: 3px; opacity: 0; pointer-events: none; }

.svg-heart-empty.filled + .svg-heart-fill { opacity: 1; pointer-events: all; }

.cars-list-grid .svg-heart-fill { top: 2px; }

.oferta-section-main-contact-item .svg-heart-fill { top: 6px; }

.twin { width: 50%; height: 25rem; padding: 2.6rem 3rem; box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.25); line-height: 2.4rem; }

.twin-container { width: 100%; display: flex; flex-direction: row; }

@media (max-width: 767.98px) { .twin-container { flex-direction: column; } }

.twin:first-child { margin-right: 3rem; }

@media (max-width: 767.98px) { .twin:first-child { margin-right: 0; margin-bottom: 3rem; } }

@media (max-width: 1199.98px) { .twin { height: auto; } }

@media (max-width: 767.98px) { .twin { width: 100%; } }

.view-and-sort-bar { width: 110rem; margin-bottom: 5rem; padding: 1rem; }

@media (max-width: 991.98px) { .view-and-sort-bar { width: 100%; max-width: 96rem; } }

@media (max-width: 575.98px) { .view-and-sort-bar { flex-direction: column; align-items: center; } }

.view-and-sort-bar-title { text-transform: uppercase; font-weight: bold; font-size: 1.6rem; padding-left: 2rem; }

@media (max-width: 575.98px) { .view-and-sort-bar-title { font-size: 2rem; padding: 0; margin-bottom: 2rem; } }

.view-and-sort-bar-view { margin-right: 7rem; }

.view-and-sort-bar-view .svg-conv { width: 2.5rem; height: 2.5rem; margin-left: 2rem; cursor: pointer; }

@media (max-width: 991.98px) { .view-and-sort-bar-view { margin-right: 5rem; } }

@media (max-width: 767.98px) { .view-and-sort-bar-view { display: none !important; } }

.view-and-sort-bar-sort .simple-select { width: 14.5rem; margin-left: 1rem; }

.white-line { background-color: #FFFFFF; height: .2rem; width: 30%; }

/*======================================
=                Layout                =
======================================*/
.ask-for-vehicle { margin-top: 12.5rem; }

.ask-for-vehicle-container { box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.15); }

@media (max-width: 767.98px) { .ask-for-vehicle-container { flex-direction: column; align-items: center; } }

.ask-for-vehicle-container > img { min-width: 57rem; width: 57rem; height: 50rem; }

@media (max-width: 1199.98px) { .ask-for-vehicle-container > img { min-width: 50rem; width: 50rem; height: auto; } }

@media (max-width: 991.98px) { .ask-for-vehicle-container > img { min-width: 40rem; width: 40rem; } }

@media (max-width: 575.98px) { .ask-for-vehicle-container > img { min-width: 80%; width: 80%; } }

.ask-for-vehicle-form { padding: 3rem 9.5rem 3rem 0; font-weight: normal; }

@media (max-width: 1199.98px) { .ask-for-vehicle-form { padding: 3rem 5rem 3rem 0; } }

@media (max-width: 991.98px) { .ask-for-vehicle-form { padding: 2rem; } }

.ask-for-vehicle-form .floating-label-field { border: 1px solid rgba(0, 0, 0, 0.25); }

.ask-for-vehicle-form * { opacity: 1; }

.ask-for-vehicle-form > *:not(:last-child) { margin-bottom: 1.5rem; }

.ask-for-vehicle-form .floating-label { opacity: 1; }

@media (max-width: 575.98px) { .ask-for-vehicle-form .floating-label { top: 55% !important; } }

.ask-for-vehicle-form .floating-label-wrap-textarea { height: 200px; min-height: 200px; }

@media (max-width: 1199.98px) { .ask-for-vehicle-form .floating-label-wrap-textarea { height: 150px; min-height: 150px; } }

@media (max-width: 991.98px) { .ask-for-vehicle-form .floating-label-wrap-textarea { height: 100px; min-height: 100px; } }

.ask-for-vehicle-form .floating-label-wrap-textarea textarea { height: 100%; min-height: 100%; }

@media (max-width: 1199.98px) { .ask-for-vehicle-form .floating-label-wrap-textarea textarea:focus + .floating-label { top: 2rem !important; } }

.ask-for-vehicle-form .floating-label-wrap-textarea .floating-label { top: 2.2rem !important; }

.ask-for-vehicle-form .button-rect { margin-top: auto; width: 16rem; font-size: 1.6rem; padding: 0 2rem; height: 4.5rem; }

@media (max-width: 767.98px) { .ask-for-vehicle-form .button-rect { width: 100%; border-radius: 3px; margin-top: -.5rem; } }

@media (max-width: 767.98px) { .ask-for-vehicle-form .newsletter-desc-agree-container { margin-top: 1rem; order: 5; } }

.brands-row { position: relative; height: 10.7rem; margin-top: 5rem; }

@media (max-width: 991.98px) { .brands-row { overflow-x: auto; } }

@media (max-width: 767.98px) { .brands-row { justify-content: flex-start !important; } }

@media (max-width: 575.98px) { .brands-row { margin-top: 2rem; } }

.brands-row-clear::after { display: none; }

.brands-row img { width: 16rem; height: 100%; }

@media (max-width: 1199.98px) { .brands-row img { width: 13rem; } }

.brands-row > * { position: relative; }

.brands-row > *::after { content: ""; display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 10; background: #ffffff55; pointer-events: none; }

.brands-row a:not(:last-child) { margin-right: 1rem; }

@media (max-width: 991.98px) { .brands-row a:first-child { margin-left: 12rem; } }

@media (max-width: 767.98px) { .brands-row a:first-child { margin-left: 0; } }

.brands-row > :last-child { margin-left: -3rem; }

.cars-list { display: flex; flex-direction: column; width: 92rem; }

.cars-list-container { margin-bottom: 10rem; }

.cars-list-pagination { align-self: flex-end; width: 44.5rem; display: flex; justify-content: flex-end; }

@media (max-width: 767.98px) { .cars-list-pagination { width: 100%; justify-content: space-between; } }

.cars-list-pagination .fake-element { width: 0; }

@media (max-width: 767.98px) { .cars-list-pagination .fake-element { width: 4rem; } }

@media (max-width: 575.98px) { .cars-list-pagination .fake-element { width: 3.2rem; } }

.cars-list-pagination-container { width: 92rem; display: flex; justify-content: flex-end; }

@media (max-width: 1199.98px) { .cars-list-pagination-container { width: 80rem; } }

@media (max-width: 991.98px) { .cars-list-pagination-container { width: 72rem; } }

@media (max-width: 767.98px) { .cars-list-pagination-container { width: 34.6rem; }
  .cars-list-pagination-container > * { width: 100%; } }

@media (max-width: 575.98px) { .cars-list-pagination-container { width: 90%; max-width: 34.6rem; } }

.cars-list-pagination .active .page-link { background-color: #233E91; }

.cars-list-pagination-previous { margin-right: 7rem; }

@media (max-width: 767.98px) { .cars-list-pagination-previous { margin-right: 0; } }

.cars-list-pagination-next { margin-left: 7rem; }

@media (max-width: 767.98px) { .cars-list-pagination-next { margin-left: 0; } }

.cars-list-pagination-arrow, .cars-list-pagination .page-item { width: 4rem; height: 4rem; font-size: 1.2rem; font-weight: normal; }

@media (max-width: 575.98px) { .cars-list-pagination-arrow, .cars-list-pagination .page-item { width: 3.2rem; height: 3.2rem; } }

.cars-list-pagination-arrow a, .cars-list-pagination-arrow span, .cars-list-pagination .page-item a, .cars-list-pagination .page-item span { color: #353B40; display: flex; justify-content: center; align-items: center; height: 100%; }

.cars-list-pagination-arrow a:hover, .cars-list-pagination-arrow span:hover, .cars-list-pagination .page-item a:hover, .cars-list-pagination .page-item span:hover { background-color: #233E91; color: #FFFFFF; }

.cars-list-pagination-arrow a:hover path, .cars-list-pagination-arrow span:hover path, .cars-list-pagination .page-item a:hover path, .cars-list-pagination .page-item span:hover path { fill: #FFFFFF; }

@media (max-width: 1199.98px) { .cars-list { width: 80rem; } }

@media (max-width: 991.98px) { .cars-list { width: 72rem; } }

@media (max-width: 767.98px) { .cars-list { width: 34.6rem; } }

@media (max-width: 575.98px) { .cars-list { width: 90%; max-width: 34.6rem; } }

.cars-list-item { height: 25rem; width: 100%; background-color: #FFFFFF; box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.15); display: flex; margin-bottom: 4rem; }

@media (max-width: 991.98px) { .cars-list-item { height: 22rem; margin-bottom: 3rem; } }

@media (max-width: 767.98px) { .cars-list-item { flex-direction: column; height: auto; } }

.cars-list-item-img { width: 100%; height: 100%; object-fit: cover; }

.cars-list-item-img-container { flex: 0 0 38rem; height: 100%; position: relative; }

@media (max-width: 991.98px) { .cars-list-item-img-container { flex: 0 0 30rem; } }

@media (max-width: 767.98px) { .cars-list-item-img-container { flex: 0 0 27rem; } }

@media (max-width: 767.98px) { .cars-list-item-img { height: 27rem; } }

.cars-list-item-img-label { position: absolute; top: 1rem; left: 0; padding: .4rem 1.5rem .4rem 3rem; font-size: 1.2rem; font-weight: bold; text-transform: uppercase; }

.cars-list-item-img-label-blue { background-color: #233E91; color: #FFFFFF; }

.cars-list-item-img-label-red { background-color: #ED2024; color: #FFFFFF; }

.cars-list-item-img-label-white { background-color: #FFFFFF; color: #233E91; }

.cars-list-item-info { display: flex; flex-direction: column; justify-content: space-between; flex: 1; padding: 1.5rem 2.9rem; }

.cars-list-item-info-container { flex-grow: 1; height: 100%; display: flex; flex-direction: column; }

@media (max-width: 1199.98px) { .cars-list-item-info { padding: 1.5rem 2rem; } }

.cars-list-item-info-title { color: #233E91; font-size: 2.2rem; font-weight: bold; max-width: 40rem; }

.cars-list-item-info-title:hover { text-decoration: underline; }

@media (max-width: 767.98px) { .cars-list-item-info-title { font-size: 1.6rem; margin-bottom: 2rem; } }

.cars-list-item-info-desc { font-weight: 600; }

.cars-list-item-info-desc-year, .cars-list-item-info-desc-mileage { margin-right: 2rem; }

@media (max-width: 1199.98px) { .cars-list-item-info-desc-year, .cars-list-item-info-desc-mileage { margin-right: 1.5rem; } }

.cars-list-item-info-desc-leasing { color: #233E91; font-weight: bold; margin-top: 1.3rem; }

@media (max-width: 1199.98px) { .cars-list-item-info-desc-leasing { margin-top: .9rem; } }

.cars-list-item-info-actions { width: 100%; height: 7.5rem; display: flex; font-weight: bold; cursor: pointer; }

@media (max-width: 1199.98px) { .cars-list-item-info-actions { height: 6.5rem; font-size: 1.4rem; }
  .cars-list-item-info-actions .svg-conv.svg-conv-blue { width: 1.4rem; } }

@media (max-width: 767.98px) { .cars-list-item-info-actions { flex-direction: column; height: auto; } }

.cars-list-item-info-actions-1 { display: flex; color: #233E91; flex: 1 0 40%; border-top: 1px solid rgba(0, 0, 0, 0.25); }

.cars-list-item-info-actions-1 .svg-conv { width: 2rem; height: 2rem; margin-right: .5rem; }

.cars-list-item-info-actions-1 > * { width: 100%; display: flex; justify-content: center; align-items: center; padding: 2.3rem .8rem; }

@media (max-width: 1199.98px) { .cars-list-item-info-actions-1 > * { padding: 2.3rem 0rem; } }

@media (max-width: 767.98px) { .cars-list-item-info-actions-1 > * { padding: 1.4rem; } }

.cars-list-item-info-actions-1 > *:not(:last-child) { border-right: 1px solid rgba(0, 0, 0, 0.25); text-align: center; line-height: 110%; }

@media (min-width: 768px) { .cars-list-item-info-actions-1 > *:not(:last-child) svg { position: relative; left: 1rem; } }

.cars-list-item-info-actions-order { background: #233E91; color: #FFFFFF; flex: 1 0 25rem; display: flex; align-items: center; padding: 0 2rem; font-size: 2rem; font-weight: bold; border-radius: 0; border: 2px solid #233E91; }

@media (max-width: 1199.98px) { .cars-list-item-info-actions-order { flex: 1 0 20rem; font-size: 1.8rem; } }

@media (max-width: 767.98px) { .cars-list-item-info-actions-order { flex: 0 0 7.3rem; padding: 0 6rem; } }

@media (max-width: 350px) { .cars-list-item-info-actions-order { padding: 0 3rem; } }

.cars-list-item-info-actions-order div { flex: 1 0 auto; text-align: right; }

.cars-list-item-info-actions-order:hover { color: #FFFFFF; }

.cars-list-item-info-actions-order .svg-conv:hover path { fill: #FFFFFF; }

.cars-list-grid { flex-direction: row; flex-wrap: wrap; width: 110rem; }

@media (max-width: 1199.98px) { .cars-list-grid { width: 72rem; } }

@media (max-width: 767.98px) { .cars-list-grid { width: 34.6rem; } }

@media (max-width: 575.98px) { .cars-list-grid { width: 90%; max-width: 34.6rem; } }

.cars-list-grid .cars-list-item { width: 26rem; height: auto; flex-direction: column; margin-bottom: 4.5rem; margin-right: 2rem; }

.cars-list-grid .cars-list-item:nth-child(4n) { margin-right: 0; }

@media (max-width: 1199.98px) { .cars-list-grid .cars-list-item { width: 23rem; margin-right: 1.5rem; margin-bottom: 3rem; }
  .cars-list-grid .cars-list-item:nth-child(4n) { margin-right: 1.5rem; }
  .cars-list-grid .cars-list-item:nth-child(3n) { margin-right: 0; } }

@media (max-width: 767.98px) { .cars-list-grid .cars-list-item { width: 100%; height: auto; } }

.cars-list-grid .cars-list-item-img-container { width: 100%; max-height: 20rem; position: relative; }

@media (max-width: 767.98px) { .cars-list-grid .cars-list-item-img-container { max-height: 27rem; flex: 0 0 27rem; } }

.cars-list-grid .cars-list-item-info { padding: .9rem; padding-bottom: 2rem; }

.cars-list-grid .cars-list-item-info-title { font-size: 1.6rem; max-width: 100%; margin-bottom: .5rem; }

.cars-list-grid .cars-list-item-info-desc-year { margin-right: 1.3rem; }

.cars-list-grid .cars-list-item-info-desc-leasing { display: none; }

.cars-list-grid .cars-list-item-info-actions { flex-direction: column; height: auto; }

.cars-list-grid .cars-list-item-info-actions-1 .svg-conv { width: 1.4rem; height: 1.4rem; margin-right: .5rem; }

.cars-list-grid .cars-list-item-info-actions-1 > * { padding: 1rem; }

.cars-list-grid .cars-list-item-info-actions-order { width: 100%; flex: 0 0 5.5rem; font-size: 1.6rem; display: flex; justify-content: center; }

.cars-list-grid .cars-list-item-info-actions-order div { flex: 0 0 auto; width: max-content; text-align: center; margin-left: 1.5rem; }

.footer { position: relative; color: #353B40; font-weight: 400; padding-top: 9rem; padding-bottom: 6rem; }

@media (max-width: 575.98px) { .footer { font-size: 1.2rem; } }

.footer a:hover { text-decoration: underline; }

@media (max-width: 575.98px) { .footer .d-flex { flex-wrap: wrap; } }

.footer .footer-facebook { transition: all .25s; position: relative; left: -1.5rem; padding: .5rem 1.3rem; width: max-content; }

.footer .footer-facebook:hover { background-color: #233E91; }

.footer .footer-facebook:hover .svg-conv path { fill: #FFFFFF; }

@media (max-width: 575.98px) { .footer .footer-facebook { position: static; } }

.footer-column { width: 24rem; }

@media (max-width: 991.98px) { .footer-column { width: 19rem; }
  .footer-column:nth-child(2) { width: 17rem; } }

@media (max-width: 767.98px) { .footer-column { width: 14rem; }
  .footer-column:nth-child(2) { width: 12rem; } }

@media (max-width: 575.98px) { .footer-column, .footer-column:nth-child(2) { width: 50%; align-items: center; text-align: center; margin-bottom: 2rem; }
  .footer-column > * { width: 90%; } }

.footer-column > div:not(:last-child):not(.footer-column-head) { margin-bottom: .6rem; }

.footer-column-head { line-height: 190%; text-transform: uppercase; margin-bottom: 1.2rem; }

.footer-copyright { position: absolute; bottom: .5rem; left: 50%; color: #838383; transform: translateX(-50%); }

@media (max-width: 575.98px) { .footer-copyright { width: 95%; font-size: 1.2rem; text-align: center; } }

@media (max-width: 991.98px) { .footer .logo-container { width: 16rem; }
  .footer .logo-container img { width: 16rem; } }

@media (max-width: 767.98px) { .footer .logo-container { display: none; } }

.footer > .logo-container { display: none; }

@media (max-width: 767.98px) { .footer > .logo-container { display: block; } }

.footer__besite { margin-top: 20px; }

.footer__besite img { width: 100px; }

.cc-link { margin-left: -2px; }

.grecaptcha-badge { visibility: collapse !important; }

.header { width: 100%; height: 63rem; background-image: url("../img/home-baner.png"); background-size: cover; background-position: 50% 50%; padding-top: 12rem; position: relative; }

@media (max-width: 1400px) { .header { padding-top: 6rem; height: 48rem; } }

@media (max-width: 991.98px) { .header { padding-top: 4rem; height: 45rem; background: linear-gradient(#00000060, #00000060), url("../img/home-baner.png"); background-size: cover; background-position: 50% 50%; } }

@media (max-width: 767.98px) { .header { height: 42rem; }
  .header .container { display: flex; justify-content: center; } }

@media (max-width: 575.98px) { .header { height: 40rem; } }

.header-items { color: #FFFFFF; max-width: 26rem; }

@media (max-width: 767.98px) { .header-items { align-items: center !important; text-align: center; } }

@media (max-width: 575.98px) { .header-items { max-width: 65%; } }

@media (max-width: 335px) { .header-items { max-width: 80%; } }

.header-items > *:not(:last-child) { margin-bottom: 3rem; }

@media (max-width: 1400px) { .header-items > *:not(:last-child) { margin-bottom: 2rem; } }

@media (max-width: 991.98px) { .header-items > *:not(:last-child) { margin-bottom: 2rem; } }

.header-items .header-desc { line-height: 2.2rem; }

@media (max-width: 575.98px) { .header-items .header-desc { font-size: 1.4rem; } }

.header-pages { position: relative; }

@media (max-width: 991.98px) { .header-pages { height: 43rem; } }

@media (max-width: 767.98px) { .header-pages { height: 38rem; } }

@media (max-width: 575.98px) { .header-pages:not(.header-sprzedaj) { margin-top: 9.2rem; height: 20rem; }
  .header-pages:not(.header-sprzedaj):not(.header-finansowanie) { margin-bottom: 9rem; } }

.header-pages .header-items { max-width: 56rem; margin-bottom: 3rem; }

@media (max-width: 767.98px) { .header-pages .header-items { margin-bottom: 1rem; } }

.header-pages .header-items .heading-1 { font-size: 6rem; margin-bottom: 1rem; }

@media (max-width: 991.98px) { .header-pages .header-items .heading-1 { font-size: 5rem; } }

@media (max-width: 767.98px) { .header-pages .header-items .heading-1 { font-size: 4rem; } }

@media (max-width: 575.98px) { .header-pages .header-items .heading-1 { font-size: 3.4rem; } }

.header-pages .header-items .header-desc { line-height: 2.2rem; }

@media (max-width: 991.98px) { .header-pages .header-items .header-desc { font-size: 1.4rem; line-height: 1.8rem; } }

@media (max-width: 575.98px) { .header-pages:not(.header-sprzedaj) .header-items { position: absolute; top: 77%; } }

@media (max-width: 575.98px) { .header-pages:not(.header-sprzedaj) .header-desc { color: #000000; height: 20rem; } }

.header-wynajem { background-image: url("../img/wynajem-header.png"); }

.header-o-firmie { background-image: url("../img/o-firmie-header.png"); }

.header-sprzedaj { background: linear-gradient(#00000055, #00000055), url("../img/sprzedaj-header.png"); background-size: cover; background-position: 50% 50%; }

.header-sprzedaj .header-items { display: flex; flex-direction: column; align-items: center; text-align: center; max-width: 100%; font-weight: 800; margin-bottom: 8rem; }

@media (max-width: 1199.98px) { .header-sprzedaj .header-items { margin-bottom: 6rem; } }

@media (max-width: 1199.98px) and (max-width: 991.98px) { .header-sprzedaj .header-items { margin-bottom: 4rem; } }

@media (max-width: 1199.98px) and (max-width: 991.98px) and (max-width: 575.98px) { .header-sprzedaj .header-items { margin-bottom: 3rem; } }

@media (max-width: 575.98px) { .header-sprzedaj .header-items .heading-1 { line-height: 110%; margin-bottom: .4rem; } }

.header-sprzedaj .header-items .header-desc { font-size: 38px; margin-top: 1rem; text-transform: uppercase; line-height: 110%; }

@media (max-width: 767.98px) { .header-sprzedaj .header-items .header-desc { font-size: 2.8rem; margin-top: 0; } }

@media (max-width: 767.98px) and (max-width: 575.98px) { .header-sprzedaj .header-items .header-desc { font-size: 2.4rem; } }

.header-finansowanie { background-image: url("../img/finance-header.png"); }

.machines-section { position: relative; padding-top: 12rem; margin-bottom: 5rem; }

@media (max-width: 1400px) { .machines-section { padding-top: 8rem; } }

@media (max-width: 767.98px) { .machines-section { padding-top: 3rem; } }

.nav .simple-select { padding: 0; border: 0; }

.nav-top-belt { padding: .3rem 0; background-color: #233E91; color: #FFFFFF; height: 3rem; display: flex; align-items: center; }

@media (max-width: 767.98px) { .nav-top-belt { padding: .3rem 1rem; } }

.nav-top-belt-left, .nav-top-belt-right { display: flex; }

.nav-top-belt-left .svg, .nav-top-belt-right .svg { margin-right: .5rem; }

.nav-top-belt-left a, .nav-top-belt-right a { margin-right: 5rem; }

@media (max-width: 991.98px) { .nav-top-belt-left a, .nav-top-belt-right a { margin-right: 2rem; } }

.nav-top-belt-left > :first-child { display: none; }

@media (max-width: 767.98px) { .nav-top-belt-left > :first-child { display: block; }
  .nav-top-belt-left > :first-child img { width: 1rem; height: 1.7rem; } }

@media (max-width: 767.98px) { .nav-top-belt-left :not(:first-child) { display: none; } }

.nav-top-belt-right > div:not(:last-child) { margin-right: 7rem; }

@media (max-width: 991.98px) { .nav-top-belt-right > div:not(:last-child) { margin-right: 2rem; } }

.nav-menu { background-color: #FFFFFF; box-shadow: 0px 0.6rem 1.5rem rgba(0, 0, 0, 0.25); }

.nav-menu a { color: #000000; }

@media (max-width: 991.98px) { .nav-menu-container { height: 6.2rem; justify-content: center !important; } }

.nav-menu-container .logo-container img { width: 18.3rem; }

@media (max-width: 991.98px) { .nav-menu-container .logo-container img { width: 12rem; } }

@media (max-width: 767.98px) { .nav-menu-container .logo-container img { width: 13.5rem; } }

.nav-menu-item { height: 100%; display: flex; align-items: center; text-transform: uppercase; font-weight: 600; cursor: pointer; margin-right: 2.5rem; }

@media (max-width: 1199.98px) { .nav-menu-item { margin-right: 1.5rem; } }

.nav-menu-item > a { display: flex; justify-content: center; align-items: center; height: 100%; width: 100%; }

.nav-menu-item.nav-menu-item-expandable { position: relative; width: 9.5rem; margin-right: 1rem; }

@media (max-width: 1199.98px) { .nav-menu-item.nav-menu-item-expandable { width: 8.5rem; } }

@media (max-width: 991.98px) { .nav-menu-item.nav-menu-item-expandable { width: auto; margin-right: 2rem; } }

.nav-menu-item.nav-menu-item-expandable > .sub-menu { position: absolute; top: 100%; left: 0; min-width: 100%; width: max-content; list-style-type: none; max-height: 0; background-color: #FFFFFF; transition: all .25s; opacity: 0; z-index: 100; pointer-events: none; }

.nav-menu-item.nav-menu-item-expandable > .sub-menu > li { max-height: 0; transition: all .25s; padding: 0rem 1rem; }

.nav-menu-item.nav-menu-item-expandable > .sub-menu > li a { color: #000000; }

.nav-menu-item.nav-menu-item-expandable > .sub-menu > li:hover { background-color: #233E91; }

.nav-menu-item.nav-menu-item-expandable > .sub-menu > li:hover a { color: #FFFFFF; }

.nav-menu-item.nav-menu-item-expandable > a { justify-content: flex-start; padding-left: 1rem; }

.nav-menu-item.nav-menu-item-expandable > a > span { position: relative; }

.nav-menu-item.nav-menu-item-expandable > a > span::after { content: ""; position: absolute; right: -1.5rem; top: 45%; border: 0.3em solid #000000; border-color: #000000 transparent transparent transparent; transition: .25s all ease-in-out; transform-origin: 50% .2rem; }

@media (max-width: 991.98px) { .nav-menu-item.nav-menu-item-expandable > a > span::after { right: -1.2rem; } }

.nav-menu-item.nav-menu-item-expandable:not(.sub-menu-opener):hover > .sub-menu, .nav-menu-item.nav-menu-item-expandable.sub-menu-opener.open > .sub-menu { max-height: 100rem; opacity: 1; pointer-events: all; }

.nav-menu-item.nav-menu-item-expandable:not(.sub-menu-opener):hover > .sub-menu > li, .nav-menu-item.nav-menu-item-expandable.sub-menu-opener.open > .sub-menu > li { max-height: 10rem; padding: .5rem 1rem; }

.nav-menu-item.nav-menu-item-expandable:not(.sub-menu-opener):hover span::after, .nav-menu-item.nav-menu-item-expandable.sub-menu-opener.open span::after { border-color: #233E91 transparent transparent transparent; transform: rotate(-180deg); }

.nav-menu-item:hover { color: #233E91; box-shadow: inset 0 -0.4rem 0 0 #233E91; }

.nav-menu-item:hover a { color: #233E91; }

.nav-menu-item-container { height: 6.2rem; position: relative; top: .2rem; }

@media (max-width: 991.98px) { .nav-menu-item-container { top: 0; }
  .nav-menu-item-container > a[href="/sprzedaj"] .cta { font-size: 1.2rem; }
  .nav-menu-item-container > a[href="/sprzedaj"] .cta span { margin-right: .3rem; } }

@media (max-width: 991.98px) { .nav-menu-container > .nav-menu-item-container { display: none !important; } }

.newsletter-container { display: flex; box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.25); margin-bottom: 5rem; }

@media (max-width: 991.98px) { .newsletter-container { flex-direction: column; } }

.newsletter-img-container { width: 50%; }

@media (max-width: 991.98px) { .newsletter-img-container { width: 100%; } }

.newsletter-img-container img { width: 100%; height: 100%; object-fit: cover; }

.newsletter-desc-container { display: flex; flex-direction: column; justify-content: space-between; width: 50%; padding: 7.5rem 6rem 5.8rem 3rem; text-align: center; }

@media (max-width: 991.98px) { .newsletter-desc-container { padding: 0 3rem 4rem 3rem; width: 100%; } }

@media (max-width: 575.98px) { .newsletter-desc-container { padding: 0 2rem 2rem 2rem; } }

.newsletter-desc-title { font-size: 2.4rem; line-height: 131%; font-weight: bold; margin-bottom: 1rem; }

.newsletter-desc-act { margin-top: 5rem; color: #233E91; margin-bottom: 3rem; font-weight: bold; }

@media (max-width: 1199.98px) { .newsletter-desc-act { margin-top: 4rem; margin-bottom: 2rem; } }

@media (max-width: 575.98px) { .newsletter-desc-act { font-size: 1.4rem; } }

.newsletter-desc-input-container button { position: absolute; right: .4rem; top: 50%; transform: translateY(-50%); font-size: 1.4rem; padding: 0; height: 3.7rem; width: 14.8rem; }

@media (max-width: 575.98px) { .newsletter-desc-input-container button { position: static; transform: none; margin-top: 2rem; width: 100%; border-radius: 3px; height: 4.5rem; } }

.newsletter-desc-input-container input { border: 1px solid #838383; height: 4.5rem; padding: .8em 1.4em 0; }

.newsletter-desc-input-container input:focus { border: 1px solid #838383; }

@media (max-width: 575.98px) { .newsletter-desc-input-container input { font-size: 1.4rem; }
  .newsletter-desc-input-container input:focus + .floating-label, .newsletter-desc-input-container input:not(:placeholder-shown) + .floating-label { transform: translate(0, -75%) scale(0.8); pointer-events: none; } }

@media (max-width: 575.98px) { .newsletter-desc-input-container .floating-label { top: 25%; transform: translate(0, -64%); font-size: 1.4rem; } }

.newsletter-desc-agree-more { color: #000000; font-weight: bold; }

.newsletter-desc-agree-container { font-size: 1rem; display: flex; color: rgba(0, 0, 0, 0.5); text-align: left; }

.newsletter-desc-agree-container > div { flex: 1 0; }

.newsletter-short { width: 98rem; }

.newsletter-short .newsletter-container { background-image: url("../img/newsletter-short-image.png"); background-size: 104%; background-position: 100% 30%; height: 45rem; display: flex; align-items: flex-start; justify-content: flex-end; }

.newsletter-short .newsletter-desc-container { width: 39rem; padding: 9rem 3rem 0 0; }

.newsletter-short .newsletter-desc-container .button-rect { width: 15rem; }

/*======================================
=                Pages                 =
======================================*/
@media (max-width: 991.98px) { .about-us-section { display: flex; flex-direction: column-reverse; } }

.about-us-section > :nth-child(2) { width: min-content; }

@media (max-width: 991.98px) { .about-us-section > :nth-child(2) { display: flex; width: 100%; } }

@media (max-width: 575.98px) { .about-us-section > :nth-child(2) { flex-direction: column; } }

.about-us-section img { width: 43rem; }

@media (max-width: 991.98px) { .about-us-section img { width: 50%; height: 29rem; max-width: 48%; }
  .about-us-section img:first-child { margin-right: 4%; } }

@media (max-width: 767.98px) { .about-us-section img { height: 23rem; } }

@media (max-width: 575.98px) { .about-us-section img { min-width: 100%; height: auto; margin-right: 0; }
  .about-us-section img:nth-child(2) { display: none; } }

.about-us-section > :first-child { box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.15); }

@media (max-width: 991.98px) { .about-us-section > :first-child { margin-right: 0 !important; } }

.about-us-carousel { width: 100%; overflow: hidden; }

.about-us-carousel .carousel { width: 100%; height: 34rem; }

.about-us-carousel .carousel-inner { height: 100%; }

.about-us-carousel .carousel-item { height: 100%; text-align: center; background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url("../img/wynajem-header.png"); background-size: cover; background-position: 50% 50%; color: #FFFFFF; padding: 9rem 0; }

.about-us-carousel .carousel-item h3 { font-weight: bold; line-height: 190%; margin-bottom: 3rem; }

@media (max-width: 575.98px) { .about-us-carousel .carousel-item h3 { margin-bottom: 1rem; } }

.about-us-carousel .carousel-item p { margin: 0 auto; max-width: 50rem; font-size: 1.2rem; line-height: 190%; }

@media (max-width: 767.98px) { .about-us-carousel .carousel-item p { max-width: 40rem; } }

.about-us-carousel .carousel-indicators li { width: 5rem; height: .6rem; background-color: #FFFFFF; opacity: 1; }

@media (max-width: 575.98px) { .about-us-carousel .carousel-indicators li { width: 4rem; height: .5rem; } }

.about-us-carousel .carousel-indicators li.active { background-color: #233E91; }

.about-us-carousel .carousel-control-prev { left: 20%; }

@media (max-width: 1199.98px) { .about-us-carousel .carousel-control-prev { left: 10%; } }

@media (max-width: 991.98px) { .about-us-carousel .carousel-control-prev { left: 5%; } }

@media (max-width: 767.98px) { .about-us-carousel .carousel-control-prev { left: 0; } }

.about-us-carousel .carousel-control-prev-icon::after { background-image: url("../img/icons/arrow-simple-left.svg"); }

.about-us-carousel .carousel-control-next { right: 20%; }

@media (max-width: 1199.98px) { .about-us-carousel .carousel-control-next { right: 10%; } }

@media (max-width: 991.98px) { .about-us-carousel .carousel-control-next { right: 5%; } }

@media (max-width: 767.98px) { .about-us-carousel .carousel-control-next { right: 0; } }

.about-us-carousel .carousel-control-next-icon::after { background-image: url("../img/icons/arrow-simple-right.svg"); }

@media (max-width: 575.98px) { .about-us-carousel .carousel-control-prev, .about-us-carousel .carousel-control-next { top: initial; bottom: 1rem; } }

.about-us-carousel .carousel-control-prev-icon, .about-us-carousel .carousel-control-next-icon { padding: 2rem; background-color: #FFFFFF; background-image: none; position: relative; }

@media (max-width: 575.98px) { .about-us-carousel .carousel-control-prev-icon, .about-us-carousel .carousel-control-next-icon { padding: 1.5rem; } }

.about-us-carousel .carousel-control-prev-icon::after, .about-us-carousel .carousel-control-next-icon::after { content: ""; display: block; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); width: 2rem; height: 2rem; background-repeat: no-repeat; background-position: 50% 50%; }

.contact-item { height: auto; width: 100%; display: flex; justify-content: space-between; align-items: center; padding-right: 3rem; }

@media (max-width: 991.98px) { .contact-item.mb-n5 { margin-bottom: -1rem !important; } }

@media (max-width: 767.98px) { .contact-item.mb-n5 { margin-bottom: 6.5rem !important; } }

@media (max-width: 1199.98px) { .contact-item { padding-right: 0rem; } }

@media (max-width: 767.98px) { .contact-item { box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15); flex-direction: column; width: 100%; max-width: 34.6rem; }
  .contact-item:nth-child(even) { flex-direction: column-reverse; }
  .contact-item:nth-child(even) .shadow { flex-direction: column-reverse; } }

@media (max-width: 991.98px) { .contact-item-container { width: 100%; max-width: 100%; } }

.contact-item.contact-item-margin:not(:last-child) { margin-bottom: 6.5rem; }

.contact-item > .shadow { background-color: #FFFFFF; align-items: center; }

@media (max-width: 767.98px) { .contact-item > .shadow { flex-direction: column; width: 100%; } }

.contact-item > .shadow > div { padding: 5rem 6rem 5rem 7.5rem; }

@media (max-width: 1199.98px) { .contact-item > .shadow > div { padding: 0 5rem; display: flex; align-items: center; } }

@media (max-width: 991.98px) { .contact-item > .shadow > div { padding: 0 4rem; } }

@media (max-width: 767.98px) { .contact-item > .shadow > div { padding: 0; }
  .contact-item > .shadow > div > .d-flex { text-align: center !important; }
  .contact-item > .shadow > div .mb-5 { margin-bottom: 1rem !important; } }

@media (max-width: 1199.98px) { .contact-item > .shadow:nth-child(odd) { margin-right: 3rem; }
  .contact-item > .shadow:nth-child(even) { margin-left: 3rem; } }

@media (max-width: 991.98px) { .contact-item > .shadow:nth-child(odd) { margin-right: 2rem; }
  .contact-item > .shadow:nth-child(even) { margin-left: 2rem; } }

@media (max-width: 767.98px) { .contact-item > .shadow { box-shadow: none !important; margin: 0 !important; } }

.contact-item img { height: 23rem; }

@media (max-width: 1199.98px) { .contact-item img { width: 23rem; height: 21rem; } }

@media (max-width: 991.98px) { .contact-item img { width: 20rem; height: auto; } }

@media (max-width: 767.98px) { .contact-item img { width: 100%; margin-bottom: 1rem; } }

.contact-item button { height: 4rem; padding: 0 4rem; background-color: transparent; color: #233E91; border-color: #233E91; }

@media (max-width: 1199.98px) { .contact-item button { padding: 0 2rem; } }

@media (max-width: 991.98px) { .contact-item button { font-size: 1.2rem; padding: 0 1rem; } }

@media (max-width: 767.98px) { .contact-item button { margin-top: 1rem; margin-bottom: 1rem; padding: 0 4rem; }
  .contact-item button svg { display: none; } }

.contact-item button:hover { color: #FFFFFF; background-color: #233E91; }

.contact-item button:hover path { fill: #FFFFFF; }

.selected-cars-list-item { box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15); height: 19rem; width: 80%; justify-content: space-between; }

@media (max-width: 767.98px) { .selected-cars-list-item { flex-direction: column; height: auto; }
  .selected-cars-list-item::before { position: absolute; content: ""; display: block; top: 0; left: 0; width: 100%; height: 5rem; background-color: #fff; } }

@media (max-width: 440px) { .selected-cars-list-item { width: 100%; } }

.selected-cars-list-item-container { align-items: center; width: 100%; margin-bottom: 4.4rem; }

@media (max-width: 767.98px) { .selected-cars-list-item-container { position: relative; justify-content: center; } }

.selected-cars-list-item-index, .selected-cars-list-item-close { width: 10%; height: 100%; display: flex; justify-content: center; align-items: center; }

@media (max-width: 767.98px) { .selected-cars-list-item-index, .selected-cars-list-item-close { position: absolute; background-color: #fff; width: 5rem; height: 5rem; top: 0; z-index: 30; } }

.selected-cars-list-item-index { font-size: 3.6rem; font-weight: bold; color: #233E91; }

@media (max-width: 1199.98px) { .selected-cars-list-item-index { font-size: 3rem; } }

@media (max-width: 767.98px) { .selected-cars-list-item-index { left: 10%; } }

.selected-cars-list-item-close img { width: 3rem; }

@media (max-width: 1199.98px) { .selected-cars-list-item-close img { width: 2.5rem; } }

@media (max-width: 767.98px) { .selected-cars-list-item-close { right: 10%; } }

.selected-cars-list-item-img { height: 100%; width: 30%; }

.selected-cars-list-item-img img { height: 100%; width: 100%; object-fit: cover; }

@media (max-width: 767.98px) { .selected-cars-list-item-img { width: 100%; height: 27rem; } }

@media (max-width: 575.98px) { .selected-cars-list-item-img { height: 25rem; } }

.selected-cars-list-item-name, .selected-cars-list-item-price { color: #233E91; font-weight: bold; font-size: 2.2rem; display: flex; align-items: center; }

@media (max-width: 1199.98px) { .selected-cars-list-item-name, .selected-cars-list-item-price { font-size: 2rem; } }

@media (max-width: 991.98px) { .selected-cars-list-item-name, .selected-cars-list-item-price { font-size: 1.8rem; } }

@media (max-width: 767.98px) { .selected-cars-list-item-name, .selected-cars-list-item-price { text-align: center; } }

.selected-cars-list-item-name { width: 40%; padding: 1rem 2rem; }

.selected-cars-list-item-name:hover { text-decoration: underline; }

@media (max-width: 767.98px) { .selected-cars-list-item-name { width: 100%; padding: 2rem; } }

.selected-cars-list-item-price { width: 30%; justify-content: center; text-align: center; border-left: 1px solid rgba(0, 0, 0, 0.25); }

@media (max-width: 767.98px) { .selected-cars-list-item-price { width: 100%; border: none; border-top: 1px solid rgba(0, 0, 0, 0.25); padding: 2rem; } }

@media (max-width: 991.98px) { .oferta-section { max-width: 95%; } }

@media (max-width: 767.98px) { .oferta-section { max-width: 54rem; } }

.oferta-section-top { width: 100%; }

.oferta-section-top-nav { font-weight: normal; margin-bottom: 3rem; }

@media (max-width: 767.98px) { .oferta-section-top-nav { flex-direction: column; align-items: center; margin-bottom: 1rem; }
  .oferta-section-top-nav > :first-child { margin-bottom: .5rem; }
  .oferta-section-top-nav-controls { width: 90%; justify-content: space-between; } }

.oferta-section-top-nav a { transition: all .25s; color: #353B40; background-color: #FFFFFF; border-radius: 3rem; padding: .5rem 1rem; display: flex; align-items: center; }

.oferta-section-top-nav a:hover { background-color: #233E91; color: #FFFFFF; }

.oferta-section-top-nav a:hover path { fill: #FFFFFF; }

.oferta-section-title { box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.15); padding: 1.5rem 2rem 1.5rem 5.3rem; margin-bottom: 1.5rem; }

@media (max-width: 991.98px) { .oferta-section-title { max-width: 100%; } }

@media (max-width: 767.98px) { .oferta-section-title { display: none !important; } }

.oferta-section-title p { font-weight: 600; font-size: 2.2rem; line-height: 3rem; }

@media (max-width: 991.98px) { .oferta-section-title p { font-size: 1.8rem; } }

.oferta-section-title-price { text-transform: uppercase; color: #233E91; font-size: 1.4rem; font-weight: bold; }

.oferta-section-title-left { flex: 1 0 auto; margin-right: 1rem; }

.oferta-section-title-cta { width: 22.7rem; height: 4.5rem; font-size: 2.2rem; font-weight: bold; text-transform: initial; padding: 0; }

@media (max-width: 991.98px) { .oferta-section-title-cta { width: 18rem; height: 3.5rem; font-size: 1.8rem; } }

.oferta-section-title-cta .svg-conv { margin-right: 1rem; }

.oferta-section-title-cta:hover path { fill: #233E91; }

@media (max-width: 767.98px) { .oferta-section-main { flex-direction: column; } }

.oferta-section-main-image-gallery { width: 73rem; margin-right: 3rem; }

@media (max-width: 991.98px) { .oferta-section-main-image-gallery { margin-right: 1.5rem; } }

@media (max-width: 767.98px) { .oferta-section-main-image-gallery { width: 100%; } }

.oferta-section-main-image-gallery .carousel, .oferta-section-main-image-gallery .carousel-inner { height: 43rem; margin-bottom: 2rem; }

@media (max-width: 991.98px) { .oferta-section-main-image-gallery .carousel, .oferta-section-main-image-gallery .carousel-inner { height: 50.5rem; margin-bottom: 1.5rem; } }

@media (max-width: 767.98px) { .oferta-section-main-image-gallery .carousel, .oferta-section-main-image-gallery .carousel-inner { height: 30rem; } }

@media (max-width: 575.98px) { .oferta-section-main-image-gallery .carousel, .oferta-section-main-image-gallery .carousel-inner { height: 25rem; } }

.oferta-section-main-image-gallery .carousel-item { height: 100%; }

.oferta-section-main-image-gallery .carousel-item img { height: 100%; object-fit: cover; }

.oferta-section-main-contact { width: 35rem; height: 55rem; display: flex; flex-direction: column; background-color: #fff; }

@media (max-width: 991.98px) { .oferta-section-main-contact { height: 62rem; } }

@media (max-width: 767.98px) { .oferta-section-main-contact { height: auto; margin-top: 1rem; width: 70%; align-self: center; } }

@media (max-width: 575.98px) { .oferta-section-main-contact { width: 100%; } }

.oferta-section-main-contact-item { padding: 1.6rem 1rem; border-bottom: 1px solid rgba(0, 0, 0, 0.25); background-color: #FFFFFF; }

@media (max-width: 1199.98px) { .oferta-section-main-contact-item { padding: 1.5rem 1.2rem; } }

@media (max-width: 991.98px) { .oferta-section-main-contact-item { flex-direction: column; align-items: center; text-align: center; } }

@media (max-width: 767.98px) { .oferta-section-main-contact-item { flex-direction: row; justify-content: center; text-align: left; } }

.oferta-section-main-contact-item:nth-last-child(1), .oferta-section-main-contact-item:nth-last-child(2) { border-bottom: 0; }

@media (max-width: 767.98px) { .oferta-section-main-contact-item:first-child { text-align: center; } }

.oferta-section-main-contact-item h1 { color: #233E91; font-size: 1.8rem; font-weight: bold; }

.oferta-section-main-contact-item-left { display: flex; justify-content: center; width: 16rem; }

@media (max-width: 1199.98px) { .oferta-section-main-contact-item-left { min-width: 13rem; max-width: 13rem; } }

@media (max-width: 991.98px) { .oferta-section-main-contact-item-left { min-width: 15rem; max-width: 15rem; } }

@media (max-width: 991.98px) { .oferta-section-main-contact-item-left > img { width: 2rem; } }

@media (max-width: 767.98px) { .oferta-section-main-contact-item-left > img { width: auto; } }

.oferta-section-main-contact-item-right { display: flex; flex-direction: column; }

.oferta-section-main-contact-item-right > * { margin-bottom: .3rem; }

.oferta-section-main-contact-item-head { font-weight: 600; line-height: 1.9rem; }

.oferta-section-main-contact-item-location { color: #838383; }

.oferta-section-main-contact-item-langs > :not(:last-child) { margin-right: .5rem; }

.oferta-section-main-contact-item .cars-list-item-info-desc-leasing { margin-top: 0; }

.oferta-section-main-contact-item .cars-list-item-info-desc-leasing img { margin-right: 1rem; }

@media (max-width: 1199.98px) { .oferta-section-main-contact-item .cars-list-item-info-desc-leasing { font-size: 1.2rem; } }

.oferta-section-main-contact-item-message { flex: 1 0 auto; position: relative; padding-bottom: 2.8rem; }

@media (max-width: 767.98px) { .oferta-section-main-contact-item-message { padding-bottom: 3rem; } }

.oferta-section-main-contact-item-message-also { position: absolute; bottom: 0; left: 0; display: flex; justify-content: space-between; padding: 0 7.8rem 0 2.4rem; color: rgba(146, 146, 146, 0.75); font-size: 1.2rem; }

@media (max-width: 1199.98px) { .oferta-section-main-contact-item-message-also { padding: 0 5rem 0 1.2rem; } }

@media (max-width: 1199.98px) and (max-width: 991.98px) { .oferta-section-main-contact-item-message-also { padding: 0 1.2rem; font-size: 1.1rem; } }

.oferta-section-main-contact-item-message-also div { display: flex; }

.oferta-section-main-contact-item-message-also div img { height: 2.2rem; width: 2.2rem; }

.oferta-section-main-contact-item-message-also div img:not(:last-child) { margin-right: 1.8rem; }

.oferta-section-main-contact-item-message-1 { height: 100%; justify-content: center; width: 100%; }

.oferta-section-main-contact-item-message-1 > * { margin-bottom: 1rem; width: 100%; justify-content: space-between; align-items: center; }

.oferta-section-main-contact-item-message-1 > * .oferta-section-main-contact-item-head { font-size: 1.4rem; }

@media (max-width: 1199.98px) { .oferta-section-main-contact-item-message-1 > * .oferta-section-main-contact-item-head { font-size: 1.2rem; } }

.oferta-section-main-contact-item-message-1 > * .contact-opener { transition: all .3s; padding: .5 1rem; width: 16rem; font-size: 1.2rem; text-align: center; background-color: #fff; color: #233E91; font-weight: 600; position: relative; }

@media (max-width: 1199.98px) { .oferta-section-main-contact-item-message-1 > * .contact-opener { width: 13rem; } }

@media (max-width: 991.98px) { .oferta-section-main-contact-item-message-1 > * .contact-opener { width: 11rem; font-size: 1.1rem; } }

@media (max-width: 767.98px) { .oferta-section-main-contact-item-message-1 > * .contact-opener { width: 16rem; font-size: 1.2rem; } }

@media (max-width: 350px) { .oferta-section-main-contact-item-message-1 > * .contact-opener { padding: .8rem 1rem; width: 14rem; } }

.oferta-section-main-contact-item-message-1 > * .contact-opener:hover { background-color: #fff; color: #233E91; }

.oferta-section-main-contact-item-message-1 > * .contact-opener .contact-opener-open { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); display: flex; justify-content: center; align-items: center; text-align: center; padding: .8rem 0; height: 100%; width: 90%; background-color: #233E91; color: #fff; opacity: 1; transition: all .3s; }

.oferta-section-main-contact-item-message-1 > * .contact-opener .contact-opener-open:hover { text-decoration: underline; }

.oferta-section-main-contact-item-message-1 > * .contact-opener.open { background-color: #fff; color: #233E91; }

.oferta-section-main-contact-item-message-1 > * .contact-opener.open .contact-opener-open { opacity: 0; pointer-events: none; }

.oferta-section-main-contact-item-message-1 > * .contact-opener.open .contact-opener-number { display: flex; justify-content: center; text-align: center; }

.oferta-section-main-contact-item-message-1 > * .oferta-section-main-contact-item-left { margin-bottom: 0; flex-direction: column; }

.oferta-section-main-contact-item-message-1 .oferta-section-main-contact-item-head { text-align: left; }

.oferta-section-main-contact-item-message-1 .oferta-section-main-contact-item-left { justify-content: left; }

.oferta-section-main-contact-item-message-2 { width: 100%; height: 100%; background-color: #fff; }

.oferta-section-main-contact-item-message-2-container { position: absolute !important; z-index: 100; top: 0; left: 0; background-color: #fff; width: 100%; height: 100%; opacity: 0; pointer-events: none; transition: opacity .3s; }

.oferta-section-main-contact-item-message-2-container.open { opacity: 1; pointer-events: all; }

.oferta-section-main-contact-item-message-2-container .carousel-inner { height: 100%; }

.oferta-section-main-contact-item-message-2-container .carousel-inner .carousel-item { height: 100%; }

.oferta-section-main-contact-item-message-2-close { position: absolute; top: 1rem; right: 1rem; cursor: pointer; }

.oferta-section-main-contact-item-message-2-nav { position: absolute; bottom: 1rem; left: 50%; transform: translateX(-50%); width: 90%; justify-content: space-between; }

.oferta-section-main-contact-item-message-2-nav a:hover { text-decoration: underline; }

.oferta-section-main-contact-item-message .oferta-section-main-contact-item-right { justify-content: center; }

.oferta-section-main-contact-item-message-option { display: flex; font-size: 1.2rem; color: #233E91; font-weight: 600; }

.oferta-section-main-contact-item-message-option:not(:last-child) { margin-bottom: 1rem; }

.oferta-section-main-contact-item-message-option:hover { text-decoration: underline; color: #233E91; }

.oferta-section-main-contact-item-message-option img, .oferta-section-main-contact-item-message-option svg { margin-right: .5rem; }

.oferta-section-main-contact-item .cta-blue.w-100 { padding: 1.5rem 0; font-size: 2.2rem; font-weight: bold; border-radius: 0; border: 0; }

@media (max-width: 991.98px) { .oferta-section-main-contact-item .cta-blue.w-100 { font-size: 1.6rem; } }

.oferta-section-main-contact-item .cta-blue.w-100 .svg-conv { margin-right: 1rem; }

.oferta-section-main-contact-item .cta-blue.w-100:hover { background-color: #233E91; color: #FFFFFF; }

.oferta-section-main-contact-item .cta { border: 2px solid #233E91; text-transform: none; color: #233E91; transition: all .25s; font-weight: bold; }

.oferta-section-main-contact-item .cta img { transition: all .25s; }

.oferta-section-main-contact-item .cta:hover { color: #FFFFFF; background-color: #233E91; }

.oferta-section-main-contact-item .cta:hover img { filter: brightness(0) invert(1); }

.oferta-section-main-contact-item-rules { color: #838383; }

.oferta-section-main-contact-item-rules:hover { color: #838383; text-decoration: underline; }

@media (max-width: 1199.98px) { .oferta-section-cars-list > .cars-list.cars-list-grid { width: 100%; flex-wrap: nowrap; overflow-x: scroll; }
  .oferta-section-cars-list > .cars-list.cars-list-grid .cars-list-item { min-width: 26rem !important; margin-right: 2rem; } }

.oferta-info-section { margin-top: 4rem; }

.oferta-info-section .title-s-left { color: #233E91; text-transform: none; font-weight: bold; }

.oferta-desc-section { margin-top: 5rem; }

@media (max-width: 767.98px) { .oferta-desc-section { flex-direction: column; } }

.oferta-desc-section .offers-right { margin-left: 3rem; flex: 1 0 auto; align-self: flex-start; }

@media (max-width: 991.98px) { .oferta-desc-section .offers-right { margin-left: 1rem; flex: 1 0 35%; } }

@media (max-width: 767.98px) { .oferta-desc-section .offers-right { margin-left: 0; width: 100%; margin-top: 1rem; } }

.oferta-desc-section .offers-right > * { margin-right: 0; height: 17rem; background-size: 103%; }

@media (max-width: 1199.98px) { .oferta-desc-section .offers-right > * { flex: 1 0 100%; } }

@media (max-width: 991.98px) { .oferta-desc-section .offers-right > * { background-size: 130%; } }

@media (max-width: 767.98px) { .oferta-desc-section .offers-right > * { flex: 1 0 45%; }
  .oferta-desc-section .offers-right > *:not(:last-child) { margin-right: 1rem; } }

@media (max-width: 575.98px) { .oferta-desc-section .offers-right > * { height: 15rem; background-size: 180%; } }

@media (max-width: 330px) { .oferta-desc-section .offers-right > * { background-size: 200%; } }

.oferta-desc-section .offers-right > *:first-child { margin-bottom: 1rem; }

.oferta-desc-section .offers-right > *:hover { background-size: 110%; }

.oferta-desc-section-desc-container { width: 56rem; max-height: 35rem; overflow: hidden; box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.15); padding: 2rem 3.7rem; position: relative; transition: all 0.5s cubic-bezier(0, 1, 0, 1); }

@media (max-width: 991.98px) { .oferta-desc-section-desc-container { flex: 1 0 60%; } }

@media (max-width: 767.98px) { .oferta-desc-section-desc-container { width: 100%; } }

.oferta-desc-section-desc-container.expanded { max-height: 300rem; }

.oferta-desc-section-desc-container.expanded > .oferta-desc-section-desc-more-overlay { opacity: 0; }

.oferta-desc-section-desc-title { font-size: 2.4rem; font-weight: bold; color: #353B40; margin-bottom: 2rem; }

.oferta-desc-section-desc-more { color: #233E91; font-size: 1.2rem; position: absolute; bottom: .5rem; left: 50%; z-index: 20; transform: translateX(-50%); }

.oferta-desc-section-desc-more:hover { color: #233E91; text-decoration: underline; }

.oferta-desc-section-desc-more-overlay { position: absolute; bottom: -.1rem; left: 0; width: 100%; height: 48%; z-index: 10; pointer-events: none; background: linear-gradient(to top, #FFF, #FFFFFFDD 40%, #FFFFFF00); transition: all .5s; border: none; }

.offers-list-controls { margin-top: 6rem; }

@media (max-width: 767.98px) { .offers-list-controls { margin-top: 2rem; } }

.offers-list-controls .stuffed-flex-bar { margin-bottom: 6rem; }

@media (max-width: 991.98px) { .offers-list-controls .stuffed-flex-bar { margin-bottom: 4rem; } }

@media (max-width: 767.98px) { .offers-list-controls > :first-child { display: none !important; } }

.page-404-container { margin-top: 5rem; padding: 10rem 0 6rem 0; }

.page-404-container a { color: #FFFFFF; background-color: #233E91; border-radius: 3rem; padding: 1rem 2rem; display: flex; align-items: center; transition: all .25s; border: 1px solid #233E91; }

.page-404-container a:hover { background-color: #FFFFFF; color: #233E91; }

.page-404-container a:hover path { fill: #233E91; }

@media (max-width: 767.98px) { .page-404-container > img { width: 95%; max-width: 54rem; } }

@media (max-width: 575.98px) { .page-404-container > img { width: 95%; max-width: 34.6rem; max-height: 7.3rem; } }

.sell-car > .bar-with-triangle > .container > .search-item { flex: 0 0 45%; }

@media (max-width: 991.98px) { .sell-car-form { flex-direction: column-reverse; } }

@media (max-width: 575.98px) { .sell-car-form .shadow { padding: 2rem !important; padding-top: 2.5rem !important; } }

@media (max-width: 340px) { .sell-car-form .shadow { padding: 1.5rem !important; padding-top: 2.5rem !important; } }

.sell-car-form-left { flex: 0 0 65%; }

.sell-car-form-left .search-item { flex: 1 0 40%; }

@media (max-width: 991.98px) { .sell-car-form-left { flex: 0 0 100%; } }

.sell-car-form-right { flex: 0 0 32%; }

@media (max-width: 991.98px) { .sell-car-form-right { flex: 0 0 100%; max-height: 1rem; overflow: hidden; position: static !important; } }

.sell-car-form-right .oferta-section-title-cta:hover { border: 1px solid #233E91; }

@media (max-width: 767.98px) { .sell-car-end { font-size: 1.4rem; flex-direction: column; text-align: center; }
  .sell-car-end button { margin-left: 0 !important; margin-top: 1rem; } }

@media (max-width: 767.98px) { .sell-car-cena-container { flex-direction: column; }
  .sell-car-cena-container > :first-child { margin-bottom: 1.8rem; }
  .sell-car-cena-container > :nth-child(2) { width: 100%; justify-content: flex-start; }
  .sell-car-cena-container > :nth-child(2) .d-flex:first-child > label:first-of-type { margin-left: 0 !important; } }

.empty { border: 2px solid #ED2024 !important; }

.invalid { border: 2px solid #FF9431 !important; }

.valid { border: 2px solid #233E91 !important; }

.sell-warn { position: absolute; right: 1rem; top: 50%; transform: translateY(-50%); width: 2.2rem; cursor: pointer; background-size: cover; }

.sell-warn-empty { background-image: url("../img/icons/warning-red-icon.svg"); height: 2.2rem; }

.sell-warn-invalid { background-image: url("../img/icons/warning-orange-icon.svg"); height: 2rem; }

.search-item-select .sell-warn { right: 2.3rem; }

.dropdown-el-container ~ .sell-warn { right: 7rem; }

.sell-empty-message { color: #ED2024; font-size: .9rem; position: absolute; top: 110%; left: 2rem; font-weight: 600; text-transform: lowercase !important; }

@media (max-width: 1199.98px) { #sell-submit { padding: 1rem 5rem; } }

@media (max-width: 1199.98px) and (max-width: 767.98px) { #sell-submit { margin: 1rem 0 0 0 !important; } }

.sprzedaj-desc { margin-top: 5rem; color: #2a2a2ad4; column-count: 2; column-gap: 27px; font-size: 10px; padding: 27px 40px; }

@media (max-width: 575.98px) { .sprzedaj-desc { column-count: initial; column-gap: initial; padding: 15px; } }

.sprzedaj-steps { width: 92rem; display: flex; flex-direction: column; margin-top: 3.5rem; }

@media (max-width: 991.98px) { .sprzedaj-steps { width: 100%; } }

.sprzedaj-steps__desc { background-color: #233E91; padding: 2.6rem 4.5rem; display: flex; justify-content: space-between; color: #ffffffd4; }

.sprzedaj-steps__desc__step { flex: 1 0 auto; display: flex; align-items: center; }

@media (max-width: 991.98px) { .sprzedaj-steps__desc__step { font-size: 1.4rem; } }

@media (max-width: 767.98px) { .sprzedaj-steps__desc__step { text-align: center; justify-content: center; } }

.sprzedaj-steps__desc__step__num { display: flex; justify-content: center; align-items: center; font-size: 3rem; height: 6rem; width: 6rem; border-radius: 50%; background-color: #102A7A; margin-right: 1.2rem; font-weight: bold; position: relative; }

@media (max-width: 991.98px) { .sprzedaj-steps__desc__step__num { font-size: 2.4rem; height: 4.5rem; width: 4.5rem; margin-right: .8rem; } }

@media (max-width: 767.98px) { .sprzedaj-steps__desc__step > :last-child { display: none; } }

.sprzedaj-steps__desc__step.active { color: #fff; }

.sprzedaj-steps__desc__step.active .sprzedaj-steps__desc__step__num { color: #233E91; background-color: #fff; }

.sprzedaj-steps__desc__step.done { color: #fff; }

.sprzedaj-steps__desc__step.done .sprzedaj-steps__desc__step__num::after { content: ""; display: block; position: absolute; width: 100%; height: 100%; border-radius: 50%; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: #2EB272; }

.sprzedaj-steps__desc__step.done .sprzedaj-steps__desc__step__num::before { content: ""; position: absolute; top: 20%; left: 40%; transform: translate(-50%, -50%); width: 1.4rem; height: 3.2rem; z-index: 50; border: solid #FFFFFF; border-width: 0 4px 4px 0; border-radius: 2px; -ms-transform: rotate(45deg); transform: rotate(45deg); }

@media (max-width: 991.98px) { .sprzedaj-steps__desc__step.done .sprzedaj-steps__desc__step__num::before { top: 15%; width: 1.2rem; height: 2.7rem; } }

.sprzedaj-steps__progress { padding: 1rem 3rem; display: flex; justify-content: space-between; }

.sprzedaj-steps__progress > * { flex: 1 0 30%; height: 1rem; }

@media (max-width: 991.98px) { .sprzedaj-steps__progress > * { height: .7rem; } }

.sprzedaj-steps__progress > *.active { background-color: #233E91; }

.sprzedaj-steps__progress > *:nth-child(2) { margin: 0 1rem; }

.sprzedaj-header { display: flex; justify-content: space-between; align-items: center; margin-top: 4rem; width: 92rem; }

@media (max-width: 991.98px) { .sprzedaj-header { width: 100%; } }

@media (max-width: 991.98px) and (max-width: 767.98px) { .sprzedaj-header { margin-top: 1rem; } }

.sprzedaj-header__left { display: flex; align-items: center; }

.sprzedaj-header__left__step { font-size: 6rem; font-weight: bold; margin-right: 1rem; color: #353B40; }

@media (max-width: 991.98px) { .sprzedaj-header__left__step { font-size: 5rem; } }

.sprzedaj-header__left__desc { display: flex; flex-direction: column; font-size: 1.4rem; font-weight: 600; color: #353b4099; }

@media (max-width: 991.98px) { .sprzedaj-header__left__desc { font-size: 1.2rem; } }

.sprzedaj-header__left__desc__head { font-weight: 800; font-size: 2.4rem; color: #353B40; }

@media (max-width: 991.98px) { .sprzedaj-header__left__desc__head { font-size: 2rem; } }

.sprzedaj-header__right img { height: 4.5rem; width: 4.2rem; }

.sprzedaj-header__right img:not(:last-child) { margin-right: 1rem; }

@media (max-width: 991.98px) { .sprzedaj-header__right img:nth-last-child(1), .sprzedaj-header__right img:nth-last-child(2), .sprzedaj-header__right img:nth-last-child(3) { display: none; } }

@media (max-width: 991.98px) and (max-width: 767.98px) { .sprzedaj-header__right img:nth-last-child(4), .sprzedaj-header__right img:nth-last-child(5) { display: none; } }

@media (max-width: 991.98px) and (max-width: 767.98px) and (max-width: 575.98px) { .sprzedaj-header__right img { display: none; } }

.sprzedaj-typy { margin-top: 6.7rem; display: flex; justify-content: space-between; }

@media (max-width: 991.98px) { .sprzedaj-typy { flex-direction: column; align-items: center; } }

@media (max-width: 991.98px) and (max-width: 767.98px) { .sprzedaj-typy { margin-top: 4rem; } }

@media (max-width: 991.98px) and (max-width: 767.98px) and (max-width: 575.98px) { .sprzedaj-typy { margin-top: 2rem; } }

.sprzedaj-typy-info { position: absolute; left: 105%; top: 50%; transform: translateY(-50%); font-size: 1.4rem; font-weight: 600; width: 20rem; opacity: 0; pointer-events: none; transition: all .3s; }

@media (max-width: 767.98px) { .sprzedaj-typy-info { top: 110%; left: 50%; transform: translateX(-50%); } }

.sprzedaj-typy-info.open { opacity: 1; pointer-events: all; }

.sprzedaj-typy__main { flex: 0 0 100%; display: flex; flex-direction: column; }

@media (max-width: 991.98px) { .sprzedaj-typy__main { flex: 0 0 100%; align-items: center; } }

@media (max-width: 991.98px) and (max-width: 575.98px) { .sprzedaj-typy__main .sell-car-end { max-width: 100%; } }

.sprzedaj-typy__typy { display: flex; justify-content: center; flex-wrap: wrap; }

@media (max-width: 767.98px) { .sprzedaj-typy__typy { max-width: 80%; } }

@media (max-width: 767.98px) and (max-width: 575.98px) { .sprzedaj-typy__typy { max-width: 100%; } }

.sprzedaj-typy__typy input[type="radio"] { display: none; }

.sprzedaj-typy__typy input[type="radio"]:checked + label { background-color: #233E91; color: #fff; }

.sprzedaj-typy__typy input[type="radio"]:checked + label img { filter: brightness(0) invert(1); }

.sprzedaj-typy__typ { flex: 0 0 11%; height: 14rem; display: flex; flex-direction: column; align-items: center; justify-content: center; border: 1px solid #D6D6D6; margin-right: .8rem; cursor: pointer; transition: all .3s; }

@media (max-width: 991.98px) { .sprzedaj-typy__typ { flex: 0 0 23%; } }

@media (max-width: 991.98px) and (max-width: 575.98px) { .sprzedaj-typy__typ { flex: 0 0 30%; } }

@media (max-width: 991.98px) and (max-width: 575.98px) and (max-width: 450px) { .sprzedaj-typy__typ { flex: 0 0 12rem; } }

.sprzedaj-typy__typ:hover { background-color: #233E91; color: #fff; }

.sprzedaj-typy__typ:hover img { filter: brightness(0) invert(1); }

.sprzedaj-typy__typ img { height: 100%; max-height: 6rem; max-width: 70%; margin-bottom: 1.5rem; transition: all .3s; }

.sprzedaj-typy__typ div { font-size: 1.2rem; line-height: 1.9rem; text-align: center; }

.sprzedaj-typy__podtypy { display: flex; flex-wrap: wrap; justify-content: center; }

.sprzedaj-typy__podtypy__container { height: auto; max-height: 0; overflow: hidden; transition: all .3s; }

.sprzedaj-typy__podtypy__container > *:not(h3) { display: none; }

.sprzedaj-typy__podtypy__container.open { max-height: 100rem; }

.sprzedaj-typy__podtypy__container.open .open { display: flex; }

.sprzedaj-typy__podtypy input[type="radio"] { display: none; }

.sprzedaj-typy__podtypy input[type="radio"]:checked + label { background-color: #233E91; color: #fff; }

.sprzedaj-typy__podtypy input[type="radio"]:checked + label img { filter: brightness(0) invert(1); }

.sprzedaj-typy__podtypy label { padding: 1rem 2rem; border: 1px solid #D6D6D6; margin-right: 1.4rem; margin-bottom: 1.4rem; cursor: pointer; }

@media (max-width: 575.98px) { .sprzedaj-podsumowanie { margin-top: 6rem; } }

.sprzedaj-podsumowanie img { width: 61rem; }

@media (max-width: 991.98px) { .sprzedaj-podsumowanie img { width: 54rem; } }

@media (max-width: 767.98px) { .sprzedaj-podsumowanie img { width: 46rem; } }

@media (max-width: 575.98px) { .sprzedaj-podsumowanie img { max-width: 98%; } }

.sprzedaj-podsumowanie h3 { font-weight: 800; font-size: 2.4rem; color: #353B40; }

.sprzedaj-podsumowanie p { font-size: 1.4rem; font-weight: 700; color: #353b40cc; }

.sprzedaj-back { display: flex; align-items: center; color: #233E91; font-size: 1.4rem; font-weight: 600; padding: .5rem 1rem; background-color: #fff; border-radius: 2rem; transition: all .3s; position: absolute; left: 0; top: 50%; transform: translateY(-50%); }

.sprzedaj-back img { width: 1.4rem; }

@media (max-width: 575.98px) { .sprzedaj-back:not(.sprzedaj-back--top) { top: 110%; left: 50%; transform: translateX(-50%); } }

.sprzedaj-back:hover { background-color: #233E91; color: #fff; }

.sprzedaj-back:hover img { filter: brightness(0) invert(1); transition: all .3s; }

.sprzedaj-back--top { top: 0; transform: translateY(0); }

@media (max-width: 767.98px) { .sprzedaj-back--top { top: -3rem; } }

@media (max-width: 767.98px) and (max-width: 575.98px) { .sprzedaj-back--top { top: -4.5rem; left: 50%; transform: translateX(-50%); } }

.sprzedaj-button { padding: 1rem 3rem; }

.sprzedaj-file-drop-area { position: relative; display: flex; align-items: center; max-width: 100%; padding: 25px; border: 1px dashed #233E91; border-radius: 3px; transition: 0.2s; }

.sprzedaj-file-drop-area.is-active { background-color: #e6e9f2; }

.sprzedaj-file-drop-area .fake-btn { flex-shrink: 0; background-color: rgba(255, 255, 255, 0.04); border: 1px solid rgba(255, 255, 255, 0.1); border-radius: 3px; padding: 8px 15px; margin-right: 10px; font-size: 12px; text-transform: uppercase; }

.sprzedaj-file-drop-area .file-msg { font-size: small; font-weight: 300; line-height: 1.4; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

.sprzedaj-file-drop-area .file-input { position: absolute; left: 0; top: 0; height: 100%; width: 100%; cursor: pointer; opacity: 0; }

.sprzedaj-file-drop-area .file-input:focus { outline: none; }

@media (max-width: 991.98px) { .wynajem-desc { flex-direction: column-reverse; }
  .wynajem-desc > :first-child { margin-right: 0 !important; } }

.wynajem-desc > :nth-child(2) { min-width: 37%; height: 100%; }

.wynajem-desc > :nth-child(2) img { width: 100%; object-fit: cover; object-position: 37%; height: 50rem; }

@media (max-width: 1199.98px) { .wynajem-desc > :nth-child(2) { min-width: 33rem; max-width: 33rem; }
  .wynajem-desc > :nth-child(2) img { width: 100%; } }

@media (max-width: 991.98px) { .wynajem-desc > :nth-child(2) { min-width: 100%; height: 40rem !important; display: flex; justify-content: center; margin-bottom: 2rem; }
  .wynajem-desc > :nth-child(2) img { height: 40rem !important; } }

@media (max-width: 575.98px) { .wynajem-desc > :nth-child(2) { margin-top: 5rem; } }

.wynajem-desc-sprzedaj > :nth-child(2) { height: 36.4rem !important; }

.wynajem-desc-sprzedaj > :nth-child(2) img { height: 36.4rem; }

@media (max-width: 1199.98px) { .wynajem-desc-sprzedaj > :nth-child(2) { height: 39rem !important; }
  .wynajem-desc-sprzedaj > :nth-child(2) img { height: 39rem; } }

.pliki-umow li { clear: left; margin-bottom: 20px; }

.pliki-umow li a img { width: 30px; height: 30px; }

/*======================================
=               Vendors                =
======================================*/
/*CONTAINER*/
@media (max-width: 767.98px) { .container { padding-left: 1rem !important; padding-right: 1rem !important; } }

/*btn*/
.btn, .btn:focus { box-shadow: none; transition: all .25s; }

.shadow { box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15) !important; }

.my-lightbox-container { display: flex; }

.my-lightbox-container a { display: none; max-width: 16rem; margin-right: 3rem; height: 10rem; position: relative; }

.my-lightbox-container a:nth-child(1), .my-lightbox-container a:nth-child(2), .my-lightbox-container a:nth-child(3), .my-lightbox-container a:nth-child(4) { display: block; }

.my-lightbox-container a:nth-child(4) { margin-right: 0; }

.my-lightbox-container a img { width: 100%; height: 100%; object-fit: cover; }

@media (max-width: 991.98px) { .my-lightbox-container a { max-width: 24%; margin-right: 1.5rem; } }

@media (max-width: 575.98px) { .my-lightbox-container a { max-width: 33%; height: 12rem; }
  .my-lightbox-container a:nth-child(1) { display: none; } }

@media (max-width: 420px) { .my-lightbox-container a { max-width: 49%; }
  .my-lightbox-container a:nth-child(2) { display: none; } }

.my-lightbox-container-overlay { position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; color: #FFFFFF; z-index: 10; font-size: 2.4rem; font-weight: 600; }

.my-lightbox-container-overlay-red { background: linear-gradient(0deg, rgba(237, 32, 36, 0.7), rgba(237, 32, 36, 0.7)); }

.my-lightbox-container-overlay-blue { background: linear-gradient(0deg, rgba(35, 62, 145, 0.7), rgba(35, 62, 145, 0.7)); }

.my-lightbox-container-overlay-black { background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)); }

.my-lightbox-container-overlay img { width: 3.4rem !important; height: 3.4rem !important; }

.my-lightbox-container-over { display: flex; position: relative; }

.my-lightbox-container-links { width: 100%; height: 100%; display: flex; position: absolute; top: 0; right: 0; z-index: 10; pointer-events: none; }

.my-lightbox-container-links .fake-element { width: 100%; max-width: 16rem; margin-right: 3rem; }

@media (max-width: 991.98px) { .my-lightbox-container-links .fake-element { max-width: 24%; margin-right: 1.5rem; } }

@media (max-width: 575.98px) { .my-lightbox-container-links .fake-element { max-width: 33%; height: 12rem; }
  .my-lightbox-container-links .fake-element:nth-child(1) { display: none; } }

@media (max-width: 420px) { .my-lightbox-container-links .fake-element { max-width: 49%; }
  .my-lightbox-container-links .fake-element:nth-child(2) { display: none; } }

.my-lightbox-container-links a { width: 100%; max-width: 16rem; height: 100%; position: relative; pointer-events: all; margin-right: 3rem; }

.my-lightbox-container-links a.my-lightbox-link-360 { cursor: pointer; }

@media (max-width: 991.98px) { .my-lightbox-container-links a { max-width: 24%; } }

@media (max-width: 575.98px) { .my-lightbox-container-links a { max-width: 33%; height: 12rem; } }

@media (max-width: 420px) { .my-lightbox-container-links a { max-width: 49%; } }

.my-lightbox-container-links a img { width: 100%; height: 100%; object-fit: cover; }

.my-lightbox-container-links a span { font-size: 14px; }

.my-lightbox-container-links a:last-child { margin-right: 0; }

.lg-backdrop.in { opacity: .85; }
