.filterable-select,
.filterable-select-value-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.filterable-select {
    text-transform: uppercase;
    font-weight: 700;
    height: 100%;
    font-size: 1.2rem;
    padding: 1rem 2rem 1rem 4.5rem;
    background-color: $white;
    transition: .3s ease-in-out;
    border: none;
  
    &::-webkit-input-placeholder {
        color: $grey-dark;
    }
  
    &:hover:not(:disabled),
    &:focus,
    &.open {
        outline: 0;
        background-color: $accent-blue;
        color: $white;
        
        &:not(.floating-label-input-my-select)::-webkit-input-placeholder {
            color: #ffffffbb !important;
        }

        & ~ img{
            filter: brightness(0) invert(1);
        }
    }
}

.filterable-select-value-list {
    list-style: none;
    margin-top: 4rem;
    box-shadow: 0px 5px 8px 0px rgba(0,0,0,0.2);
    overflow: hidden;
    max-height: 0;
    transition: all .3s ease-in-out;
    background-color: $white;
    z-index: 30;
    
    &.open {
        z-index: 10;
        max-height: 22rem;
        overflow: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar-track{
            background-color: #E6E6E6;
        }
    
        &::-webkit-scrollbar{
            background-color: #CACACA;
            width: .8rem;
            margin-right: 2rem;
        }
    
        &::-webkit-scrollbar-thumb{
            background-color: #CACACA;
        }
    }
    
    li {
        text-align: left;
        margin-left: 1.3rem;
        margin-right: 1.3rem;
        text-transform: uppercase;
        position: relative;
        background-color: transparent;
        padding: 0 2rem;
        height: 4rem;
        font-size: 1.2rem;
        font-weight: 700;
        display: flex;
        align-items: center;
        cursor: pointer;
        opacity: 1;
        color: $grey-bright;

        @include media-breakpoint-down(md){
            padding: 1rem 1.5rem;
            margin: 0 .5rem;
        }

        &:not(:last-child){
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        }
        
        &:hover {
            color: $white;

            &::after{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: -50%;
                z-index: -1;
                width: 250%;
                height: 100%;
                background-color: $accent-blue;
                color: $white;

            }
        }
        
        &.closed {
        max-height: 0;
        // overflow: hidden;
        display: none;
        padding: 0;
        opacity: 0;
        }
    }
}



/*NAV SELECT*/

.dropdown-el {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    min-height: 2rem;
    max-height:2rem;
    overflow:hidden;
    cursor: pointer;
    text-align: left;
    white-space: nowrap;
    color: $white;
    z-index: 10;
    
    outline: none;
    background-color: $accent-blue;
    
    transition: 0.25s all ease-in-out;

    //LABEL FIX
    & img{
        pointer-events: none;
    }

    &-container{
        width: 5.5rem;
        height: 2rem;
    
        &-lang{
            width: 11rem;
        }
    }

    input:focus + label {
      background: #def;
    }
    input {
      width: 1px;
      height: 1px;
      display: inline-block;
      position: absolute;
      opacity: 0.01;
    }
    label {
      display:block;
      height: 2em;
      padding-left: 1rem;
      cursor: pointer;
      position: relative;
      transition: 0.25s color ease-in-out;  
      margin-bottom: 0;
      &:nth-child(2) {
        margin-top: 2em;
      }
    }
    input:checked + label {
      display:block;
      border-top: none;
      position: absolute;
      top: 0;
      width: 100%;
  
      &:nth-child(2) {
        margin-top: 0;
        position: relative;
      }
    }
    
    &::after {
      content:"";
      position: absolute;
      right: 0.3rem;
      top: 0.9rem;
      border: .3em solid $white;
      border-color: $white transparent transparent transparent;
      transition: .4s all ease-in-out;
      transform-origin: 50% .2rem;
    }
    &.expanded {
      padding: 0;
      box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 5px 0px;
      max-height:15em;
      
      label {
        &:hover {
          background-color: $accent-blue-bright;
        }
      }
      
      &::after {
        transform: rotate(-180deg);
      }
    }
  }

  /*SORTING SELECT*/

  .select-box {
    position: relative;
    display: block;
    width: 17rem;
    font-family: 'Open Sans', sans-serif;
    color: black;
    border:1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    margin-left: .5rem;
    z-index: 1;
    
    &__current {
      position: relative;
      box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
      cursor: pointer;
      outline: none;
      
      &:focus {
        & + .select-box__list {
          opacity: 1;
  
          // We have to set "animation-name: none;" to make the list visible (read below how it works)
  
          animation-name: none;
          
          .select-box__option {
            cursor: pointer;
          }
        }
        
        .select-box__icon {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
    
    &__icon {
      position: absolute;
      top: 50%;
      right: .5rem;
      transform: translateY(-50%);
      width: 7px;
      transition: 0.2s ease;
    }
    
    &__value {
      display: flex;
    }
    
    &__input {
      display: none;
      
      &:checked + .select-box__input-text {
        display: block;
      }
    }
    
    &__input-text {
      display: none;
      width: 100%;
      margin: 0;
      padding: 5px 5px 5px 10px;
      background-color: #fff;
    }
    
    &__list {
      position: absolute;
      width: 100%;
      padding: 0;
      list-style: none;
      opacity: 0;
      
      // We need to use animation with delay.
      // Otherwise the click event will not have time to run on label, because this element disapears immediately when .select-box__current element loses the focus.
      // This delay will not be noticed because we set "opacity" to "0".
      // We also use "animation-fill-mode: forwards" to make the list stay hidden.
      
      animation-name: HideList;
      animation-duration: 0.5s;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: step-start;
      box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
    }
    
    &__option {
        display: block;
        padding: 5px 5px 5px 10px;
        background-color: #fff;
        margin: 0;
        border: 1px solid rgba(0, 0, 0, 0.15);
            border-top: 0;
        
        &:hover,
        &:focus {
            color: $white;
            background-color: $accent-blue;
        }
    }
  }
  
  @keyframes HideList {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }