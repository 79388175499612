.bar-with-triangle{
    background-color: $accent-blue;
    padding: 2rem 0;
    position: relative;

    &::after{
        content: "";
        display: block;
        position: absolute;
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        border-top: 25px solid $accent-blue;
        left: 40%;
        top: 100%;

        @include media-breakpoint-down(md){
            left: 50%;
            transform: translateX(-50%);
        }
    }

    & .search-item:not(.search-item-controls), .search-item:not(.search-item-controls).open{
        border: 1px solid $white;

        &:hover{
            border: 1px solid $white;
        }
    }

    @include media-breakpoint-down(md){
        & > .container{
            flex-direction: column;

            & > :first-child{
                margin-bottom: 1rem;
            }

            & > .search-item{
                flex: 0 0 4rem !important;
                width: 90%;
                max-width: 80rem;
                margin-left: 0 !important;
            }

            & > :last-child{
                display: none !important;
            }
        }
    }
}