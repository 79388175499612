.mt-8{
    margin-top: 8rem;
    @include media-breakpoint-down(sm){
        margin-top: 7rem;
    }
    @include media-breakpoint-down(xs){
        margin-top: 5rem;
    }
}

.mt-11{
    margin-top: 11rem;
    @include media-breakpoint-down(sm){
        margin-top: 8rem;
    }
    @include media-breakpoint-down(xs){
        margin-top: 4rem;
    }
}

.mt-my-5{
    margin-top: 5rem;

    @include media-breakpoint-down(sm){
        margin-top: 2rem;
    }
}

.mb-8{
    margin-bottom: 8rem;
}

.w-60{
    min-width: 60%;
}

.flex-50{
    flex: 1 0 50%;
}

.lh-190{
    line-height: 190%;
}

.img-m{
    width: 44rem;
}

.z-100{
    z-index: 100;
}

input.hide-arrows[type=number]::-webkit-inner-spin-button, 
input.hide-arrows[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.max-xs{
    max-width: 47rem;
    @include media-breakpoint-down(sm){
        width: 100%;
        max-width: 32.6rem;
    }
}

.hover-under{
    &:hover{
        text-decoration: underline;
    }
}

.hide{
    &-sm{
        @include media-breakpoint-down(sm){
            display: none !important;
        }
    }
    &-md{
        @include media-breakpoint-down(md){
            display: none !important;
        }
    }
}

.blue-bar{
    background-color: $accent-blue;
    padding: 1.5rem 0;
}