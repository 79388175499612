.search-item{
    // background: rgba(246, 245, 255, 0.5);
    // background: $blue-very-light;
    flex: 1 0 20rem;
    height: 4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;                 

    @include media-breakpoint-down(md){
        flex: 1 0 30%;
    }

    @include media-breakpoint-down(sm){
        flex: 1 0 45%;
    }

    @include media-breakpoint-down(xs){
        flex: 1 0 100%;
    }

    & img{
        pointer-events: none;
    }

    & .dropdown-el-container{
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 6.6rem;
        border-left: 1px solid rgba(0, 0, 0, 0.25);

        & label{
            background-color: $white;
            color: $black;
            padding-top: 1rem;
            height: 3.8rem;
        }
    }

    & .dropdown-el{  
        min-height: 100%;
        max-height: 100%;
        background-color: $white;

        &.expanded {
            border: 1px solid rgba(0, 0, 0, 0.25);

            & label:hover{
                background-color: rgba(230, 230, 230, 1);
            }
        }

        & label:nth-child(2){
            margin-top: 3.8rem;
        }

        &::before {
            content:"";
            position: absolute;
            right: 0.3rem;
            top: 0.9rem;
            border: .3em solid $black;
            border-color: $black transparent transparent transparent;
            transition: .4s all ease-in-out;
            transform-origin: 50% .2rem;
          }

        &::after {
            top: 1.7rem;
            border-color: $black transparent transparent transparent;
        }
    }

    &-spacing{
        margin-right: 2.5rem;
        margin-bottom: 2.5rem;

        @include media-breakpoint-down(lg){
            margin-right: 1.8rem;
            margin-bottom: 1.8rem;
        }

        &:nth-child(4n){
            margin-right: 0;
        }

        &-2{
            &:nth-child(2n){
                margin-right: 0;
            }

            &:nth-last-child(1), &:nth-last-child(2){
                margin-bottom: 0;
            }

            @include media-breakpoint-down(md){
                &:nth-child(4n){
                    margin-right: 0 !important;
                }
                &:nth-child(3n){
                    margin-right: 1.8rem !important;
                }
                &:nth-child(2n){
                    margin-right: 0 !important;
                }
            }

            @include media-breakpoint-down(xs){
                margin-right: 0 !important;
                flex: 1 0 100% !important;
                
                &:nth-last-child(2){
                    margin-bottom: 1.8rem;
                }
            }
        }

        @include media-breakpoint-down(md){
            &:nth-child(4n){
                margin-right: 1.8rem;
            }

            &:nth-child(3n){
                margin-right: 0;
            }
        }

        @include media-breakpoint-down(sm){
            &:nth-child(even){
                margin-right: 0;
            }

            &:nth-child(odd){
                margin-right: 1.8rem;
            }
        }

        @include media-breakpoint-down(xs){
            &{
                margin-right: 0;
            }
        }
    }

    &-split > *{
        padding: 1rem;
        width: 50%;

        &:first-child{
            border-right: 1px solid rgba(0,0,0,0.25);
        }
    }

    &-year.search-item-split{
        & .floating-label-wrap{
            & input{
                font-weight: 700;
                padding-top: 0;
    
                &::placeholder{
                    font-weight: 400;
                    color: #909294;
                }
            }

            & .filterable-select-value-list{
                & li{
                    font-weight: 600;
                    margin-right: .5rem;
                    margin-left: .5rem;
                    padding: 0 1rem;

                    @include media-breakpoint-down(lg){
                        padding: 0 .5rem;
                    }
                }
            }
        }
    }

    &-select{
        position: relative;

        &.open img{
            filter: brightness(0) invert(1) !important;
        }

        &.filled{
            //border: 1px solid $accent-blue !important;

            & > input{
                background-color: $blue-very-light;
                // background-color: #fafaff;
            }
        }

        & .filterable-select:disabled{
            background-color: $grey-bright;
            color: $white;
            cursor: not-allowed;

            &::-webkit-input-placeholder {
                color: $white;
            }
            
            & ~ img{
                filter: brightness(0) invert(1);
            }
        }

        &:hover{            
            
            & .filterable-select:not(:disabled) {
                background-color: $accent-blue;
                color: $white;
                cursor: pointer;                    
                
                &::-webkit-input-placeholder {
                    color: transparent;
                }      
            }

            & .filterable-select:disabled{
                background-color: #aaaaaa;
            }

            & .floating-label-my-select{
                color: $white;
            }

            & img{
                filter: brightness(0) invert(1);
            }
        }

        &-icon{
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
            z-index: 5;
        }

        &-arrow{
            width: .7rem;
            height: .4rem;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);

            /*HIDE ARROW*/
            // display: none;
        }

        &.simple-select{
            font-weight: bold;
            text-transform: uppercase;
            padding: 0 2rem;
            cursor: pointer;

            & img{
                margin-right: 1rem;
            }
        }
    }

    &-clear{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4.8rem;
        height: 4rem;
        border: 2px solid $accent-blue;
        border-radius: 3px;
        margin-right: 2.5rem;                        

        @include media-breakpoint-down(lg){
            margin-right: 1.8rem;  
        }

        &:hover{
            background-color: $accent-blue;

            & path{
                fill: $white;
            }
        }
    }

    &-search{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 0 auto;
        background-color: $accent-blue;
        color: $white;
        font-weight: 600;
        font-size: inherit;
        height: 100%;
        border: 2px solid $accent-blue;

        &:hover{
            background-color: $white;
            color: $accent-blue;

            & path{
                fill: $accent-blue;
            }
        }

        & > div{
            position: relative;
        }

        & .svg-conv{
            width: 2rem;
            margin-left: .3rem;
        }
    }

    &:not(.search-item-controls){
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 3px;

        & input:not(.filterable-select){
            border-radius: 3px;
        }
    }

    &-container{
        display: flex;
        flex-wrap: wrap;
        width: 110rem;
        padding: 3.6rem;
        padding-bottom: 1.1rem;
        box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.15);
        margin-bottom: 6rem;

        & .fake-element{
            flex: 1 0 20rem;
            height: 4rem;
            display: none;

            @include media-breakpoint-down(md){
                flex: 1 0 30%;
            }
        
            @include media-breakpoint-down(sm){
                flex: 1 0 45%;
            }
        
            @include media-breakpoint-down(xs){
                flex: 1 0 100%;
            }
        }

        @include media-breakpoint-down(lg){
            width: 96rem;
        }                      

        @include media-breakpoint-down(md){
            width: 72rem;
        }

        @include media-breakpoint-down(sm){
            width: 54rem;
        }

        @include media-breakpoint-down(xs){
            width: 100%;
            max-width: 34.6rem;
            padding-bottom: 3rem;
        }

        & .search-item-select-arrow{
            @include media-breakpoint-down(md){
                right: 1rem;
            }
        }

        & .floating-label-wrap{
            padding: 0;
            height: 100%;

            &.filled{
                //border: 1px solid $accent-blue;
                border-radius: 0;

                &:not(.open):not(:hover) > input{
                    background-color: $blue-very-light;
                    // background-color: #fafaff;
                }
            }

            & input{
                height: 100%;
                padding: 0;
                padding-left: 1.5rem;
                padding-top: .6rem;                

                @include media-breakpoint-down(lg){
                    padding-left: 1rem;
                }

                &:focus,
                &:not(:placeholder-shown) {
                    & + .floating-label.floating-label-search{
                        transform: translate(0, -100%) scale(.8);
                    }
                }
                &:disabled {
                    background-color: $grey-bright;
                    cursor: not-allowed;
                    // color: #fff;
                }
            }

            & .floating-label{
                padding-left: 1.6rem;
                font-weight: 600;                

                @include media-breakpoint-down(lg){
                    padding-left: 1rem;
                }

                &.disabled {
                    color: $white;
                    opacity: 1;
                }
            }
        }
    }
}