.oferta-section{
    
    @include media-breakpoint-down(md){
        max-width: 95%;
    }

    @include media-breakpoint-down(sm){
        max-width: 54rem;
    }

    &-top{
        width: 100%;

        &-nav{
            font-weight: normal;
            margin-bottom: 3rem;

            @include media-breakpoint-down(sm){
                flex-direction: column;
                align-items: center;
                margin-bottom: 1rem;

                & > :first-child{
                    margin-bottom: .5rem;
                }

                &-controls{
                    width: 90%;
                    justify-content: space-between;
                }
            }

            & a{
                transition: all .25s;
                color: $grey-dark;
                background-color: $white;
                border-radius: 3rem;
                padding: .5rem 1rem;
                display: flex;
                align-items: center;

                &:hover{
                    background-color: $accent-blue;
                    color: $white;

                    & path{
                        fill: $white;
                    }
                }
            }
        }
    }    

    &-title{
        box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.15);
        padding: 1.5rem 2rem 1.5rem 5.3rem;
        margin-bottom: 1.5rem;

        @include media-breakpoint-down(md){
            max-width: 100%;
        }

        @include media-breakpoint-down(sm){
            display: none !important;
        }

        & p{
            font-weight: 600;
            font-size: 2.2rem;
            line-height: 3rem;

            @include media-breakpoint-down(md){
                font-size: 1.8rem;
            }
        }

        &-price{
            text-transform: uppercase;
            color: $accent-blue;
            font-size: 1.4rem;
            font-weight: bold;
        }

        &-left{
            flex: 1 0 auto;
            margin-right: 1rem;
        }

        &-cta{
            width: 22.7rem;
            height: 4.5rem;
            font-size: 2.2rem;
            font-weight: bold;
            text-transform: initial;
            padding: 0;

            @include media-breakpoint-down(md){
                width: 18rem;
                height: 3.5rem;
                font-size: 1.8rem;
            }

            & .svg-conv{
                margin-right: 1rem;
            }

            &:hover path{
                fill: $accent-blue;
            }
        }
    }

    &-main{
        @include media-breakpoint-down(sm){
            flex-direction: column;
        }

        &-image-gallery{
            width: 73rem;
            margin-right: 3rem;

            @include media-breakpoint-down(md){
                margin-right: 1.5rem;
            }

            @include media-breakpoint-down(sm){
                width: 100%;
            }

            & .carousel, & .carousel-inner{
                height: 43rem;
                margin-bottom: 2rem;

                @include media-breakpoint-down(md){
                    height: 50.5rem;
                    margin-bottom: 1.5rem;
                }

                @include media-breakpoint-down(sm){
                    height: 30rem;
                }

                @include media-breakpoint-down(xs){
                    height: 25rem;
                }
            }

            & .carousel-item{
                height: 100%;
                & img{
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &-contact{
            width: 35rem;
            height: 55rem;
            //box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
            display: flex;
            flex-direction: column;
            background-color: #fff;

            @include media-breakpoint-down(md){
                height: 62rem;
            }
            
            @include media-breakpoint-down(sm){
                height: auto;
                margin-top: 1rem;
                width: 70%;
                align-self: center;
            }

            @include media-breakpoint-down(xs){
                width: 100%;
            }

            &-item{
                padding: 1.6rem 1rem;
                border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                background-color: $white;

                @include media-breakpoint-down(lg){
                    padding: 1.5rem 1.2rem;
                }

                @include media-breakpoint-down(md){
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                }

                @include media-breakpoint-down(sm){
                    flex-direction: row;
                    justify-content: center;
                    text-align: left;
                }

                &:nth-last-child(1), &:nth-last-child(2){
                    border-bottom: 0;
                }

                &:first-child{
                    @include media-breakpoint-down(sm){
                        text-align: center;
                    }
                }

                & h1{
                    color: $accent-blue;
                    font-size: 1.8rem;
                    font-weight: bold;
                }

                &-left{
                    display: flex;
                    justify-content: center;
                    width: 16rem;

                    @include media-breakpoint-down(lg){
                        min-width: 13rem;
                        max-width: 13rem;
                    }

                    @include media-breakpoint-down(md){
                        //min-width: 12rem;
                        //max-width: 12rem;
                        //margin-bottom: 1rem;
                    }

                    @include media-breakpoint-down(md){
                        min-width: 15rem;
                        max-width: 15rem;
                    }

                    //Lokalizacja
                    & > img{
                        @include media-breakpoint-down(md){
                            width: 2rem;
                        }

                        @include media-breakpoint-down(sm){
                            width: auto;
                        }
                    }
                }

                &-right{
                    display: flex;
                    flex-direction: column;

                    & > * {
                        margin-bottom: .3rem;
                    }
                }

                &-head{
                    font-weight: 600;
                    line-height: 1.9rem;
                }

                &-location{
                    color: $grey-bright;
                }

                &-langs{
                    & > :not(:last-child){
                        margin-right: .5rem;
                    }
                }

                & .cars-list-item-info-desc-leasing{
                    margin-top: 0;

                    & img{
                        margin-right: 1rem;
                    }

                    @include media-breakpoint-down(lg){
                        font-size: 1.2rem;
                    }
                }

                &-message{
                    flex: 1 0 auto;
                    position: relative;
                    padding-bottom: 2.8rem;
                    @include media-breakpoint-down(sm){
                        padding-bottom: 3rem;
                    }

                    &-also{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        display: flex;
                        justify-content: space-between;
                        padding: 0 7.8rem 0 2.4rem;

                        @include media-breakpoint-down(lg){
                            padding: 0 5rem 0 1.2rem;

                            @include media-breakpoint-down(md){
                                padding: 0 1.2rem;
                                font-size: 1.1rem;
                            }
                        }

                        color: rgba(146, 146, 146, 0.75);
                        font-size: 1.2rem;
                        & div{
                            display: flex;

                            & img{
                                height: 2.2rem;
                                width: 2.2rem;

                                &:not(:last-child){
                                    margin-right: 1.8rem;
                                }
                            }
                        }
                    }

                    &-1{
                        height: 100%;
                        justify-content: center;
                        width: 100%;

                        & > * {
                            margin-bottom: 1rem;
                            width: 100%;

                            justify-content: space-between;
                            align-items: center;

                            & .oferta-section-main-contact-item-head{
                                font-size: 1.4rem;
                                @include media-breakpoint-down(lg){
                                    font-size: 1.2rem;
                                }
                            }

                            & .contact-opener{
                                transition: all .3s;
                                padding: .5 1rem;
                                width: 16rem;
                                font-size: 1.2rem;
                                text-align: center;
                                background-color: #fff;
                                color: $accent-blue;
                                font-weight: 600;

                                position: relative;
                                
                                @include media-breakpoint-down(lg){
                                    width: 13rem;
                                }

                                @include media-breakpoint-down(md){
                                    width: 11rem;
                                    font-size: 1.1rem;
                                }
                                
                                @include media-breakpoint-down(sm){
                                    width: 16rem;
                                    font-size: 1.2rem;
                                }

                                @media(max-width: 350px){
                                    padding: .8rem 1rem;
                                    width: 14rem;
                                }

                                &:hover{
                                    background-color: #fff;
                                    color: $accent-blue;
                                }

                                & .contact-opener-open{
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%, -50%);

                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    text-align: center;
                                    padding: .8rem 0;
                                    height: 100%;
                                    width: 90%;
                                    background-color: $accent-blue;
                                    color: #fff;
                                    opacity: 1;
                                    transition: all .3s;

                                    &:hover{
                                        text-decoration: underline;
                                    }
                                }

                                &.open{
                                    & .contact-opener-open{
                                        opacity: 0;
                                        pointer-events: none;
                                    }
                                    & .contact-opener-number{
                                        display: flex;
                                        justify-content: center;
                                        text-align: center;
                                    }

                                    background-color: #fff;
                                    color: $accent-blue;
                                }
                            }

                            & .oferta-section-main-contact-item-left{
                                margin-bottom: 0;
                                flex-direction: column;
                            }
                        }

                        & .oferta-section-main-contact-item{
                            &-head{
                                text-align: left;                                
                            }

                            &-left{
                                justify-content: left;
                            }
                        }
                    }

                    &-2{
                        width: 100%;
                        height: 100%;
                        background-color: #fff;

                        &-container{
                            position: absolute !important;
                            z-index: 100;
                            top: 0;
                            left: 0;
                            background-color: #fff;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                            pointer-events: none;
                            transition: opacity .3s;

                            &.open{
                                opacity: 1;
                                pointer-events: all;
                            }

                            & .carousel-inner {
                                height: 100%;

                                & .carousel-item{
                                    height: 100%;
                                }
                            }
                        }

                        &-close{
                            position: absolute;
                            top: 1rem;
                            right: 1rem;
                            cursor: pointer;
                        }

                        &-nav{
                            position: absolute;
                            bottom: 1rem;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 90%;
                            justify-content: space-between;

                            & a{
                                &:hover{
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    & .oferta-section-main-contact-item-right{
                        justify-content: center;
                    }

                    &-option{
                        display: flex;
                        font-size: 1.2rem;
                        color: $accent-blue;
                        font-weight: 600;

                        &:not(:last-child){
                            margin-bottom: 1rem;
                        }

                        &:hover{
                            text-decoration: underline;
                            color: $accent-blue;
                        }

                        & img, & svg{
                            margin-right: .5rem;
                        }
                    }
                }

                & .cta-blue.w-100{
                    padding: 1.5rem 0;
                    font-size: 2.2rem;
                    font-weight: bold;
                    border-radius: 0;
                    border: 0;

                    @include media-breakpoint-down(md){
                        font-size: 1.6rem;
                    }

                    & .svg-conv{
                        margin-right: 1rem;
                    }

                    &:hover{
                        background-color: $accent-blue;
                        color: $white;
                    }
                }

                & .cta{
                    border: 2px solid $accent-blue;
                    text-transform: none;
                    color: $accent-blue;
                    transition: all .25s;
                    font-weight: bold;

                    & img {
                        transition: all .25s;
                    }
                    &:hover{
                        color: $white;
                        background-color: $accent-blue;
                        & img{
                            filter: brightness(0) invert(1);
                        }
                    } 
                }

                &-rules{
                    color: $grey-bright;

                    &:hover{
                        color: $grey-bright;
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &-cars-list{
        & > .cars-list.cars-list-grid{
            @include media-breakpoint-down(lg){
                width: 100%;
                flex-wrap: nowrap;
                overflow-x: scroll;

                & .cars-list-item{
                    min-width: 26rem !important;
                    margin-right: 2rem;
                }
            }
        }
    }
}

.oferta-info-section{
    margin-top: 4rem;
    & .title-s-left{
        color: $accent-blue;
        text-transform: none;
        font-weight: bold;
    }
}

.oferta-desc-section{
    margin-top: 5rem;

    @include media-breakpoint-down(sm){
        flex-direction: column;
    }

    & .offers-right{
        margin-left: 3rem;
        flex: 1 0 auto;
        align-self: flex-start;

        @include media-breakpoint-down(md){
            margin-left: 1rem;
            flex: 1 0 35%;
        }

        @include media-breakpoint-down(sm){
            margin-left: 0;
            width: 100%;
            margin-top: 1rem;
        }

        & > * {
            margin-right: 0;
            height: 17rem;
            background-size: 103%;

            @include media-breakpoint-down(lg){
                flex: 1 0 100%;
            }

            @include media-breakpoint-down(md){
                background-size: 130%;
            }

            @include media-breakpoint-down(sm){
                flex: 1 0 45%;
                &:not(:last-child){
                    margin-right: 1rem;
                }                
            }

            @include media-breakpoint-down(xs){
                height: 15rem;
                background-size: 180%;
            }

            @media(max-width: 330px){
                background-size: 200%;
            }

            &:first-child{
                margin-bottom: 1rem;
            }
            
            &:hover{
                background-size: 110%;
            }
            
        }
    }

    &-desc{
        &-container{
            width: 56rem;
            max-height: 35rem;
            overflow: hidden;
            box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.15);
            padding: 2rem 3.7rem;
            position: relative;
            transition: all .5s cubic-bezier(0, 1, 0, 1);

            @include media-breakpoint-down(md){
                flex: 1 0 60%;
            }

            @include media-breakpoint-down(sm){
                width: 100%;
            }

            &.expanded{
                max-height: 300rem;

                & > .oferta-desc-section-desc-more-overlay{
                    opacity: 0;
                }
            }
        }

        &-title{
            font-size: 2.4rem;
            font-weight: bold;
            color: $grey-dark;
            margin-bottom: 2rem;
        }

        &-more{
            color: $accent-blue;
            font-size: 1.2rem;
            position: absolute;
            bottom: .5rem;
            left: 50%;
            z-index: 20;
            transform: translateX(-50%);

            &:hover{
                color: $accent-blue;
                text-decoration: underline;
            }

            &-overlay{
                position: absolute;
                bottom: -.1rem;
                left: 0;
                width: 100%;
                height: 48%;
                z-index: 10;
                pointer-events: none;
                background: linear-gradient(to top, #FFF, #FFFFFFDD 40%, #FFFFFF00);
                transition: all .5s;
                border: none;
            }
        }
    }
}