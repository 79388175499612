.sprzedaj{
    &-desc{
        margin-top: 5rem;
        color: #2a2a2ad4;
        column-count: 2;
        column-gap: 27px;
        font-size: 10px;
        padding: 27px 40px;
        // box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
        @include media-breakpoint-down(xs){
            column-count: initial;
            column-gap: initial;
            padding: 15px;
        }
    }

    &-steps{
        width: 92rem;
        display: flex;
        flex-direction: column;
        margin-top: 3.5rem;
        @include media-breakpoint-down(md){
            width: 100%;
        }

        &__desc{
            background-color: $accent-blue;
            padding: 2.6rem 4.5rem;
            display: flex;
            justify-content: space-between;
            color: #ffffffd4;

            &__step{
                flex: 1 0 auto;
                display: flex;
                align-items: center;
                @include media-breakpoint-down(md){
                    font-size: 1.4rem;
                }
                @include media-breakpoint-down(sm){
                    text-align: center;
                    justify-content: center;
                }

                &__num{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 3rem;
                    height: 6rem;
                    width: 6rem;
                    border-radius: 50%;
                    background-color: #102A7A;
                    margin-right: 1.2rem;
                    font-weight: bold;
                    position: relative;
                    @include media-breakpoint-down(md){
                        font-size: 2.4rem;
                        height: 4.5rem;
                        width: 4.5rem;
                        margin-right: .8rem;
                    }
                }

                & > :last-child{
                    @include media-breakpoint-down(sm){
                        display: none;
                    }
                }

                &.active{
                    color: #fff;

                    & .sprzedaj-steps__desc__step__num{
                        color: $accent-blue;
                        background-color: #fff;
                    }
                }

                &.done{
                    color: #fff;

                    & .sprzedaj-steps__desc__step__num{
                        &::after{
                            content: "";
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            background-color: #2EB272;
                        }
                        &::before{
                            content: "";
                            position: absolute;
                            top: 20%;
                            left: 40%;
                            transform: translate(-50%, -50%);
                            width: 1.4rem;
                            height: 3.2rem;
                            z-index: 50;
                            border: solid #FFFFFF;
                            border-width: 0 4px 4px 0;
                            border-radius: 2px;
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);

                            @include media-breakpoint-down(md){
                                top: 15%;
                                width: 1.2rem;
                                height: 2.7rem;
                            }
                        }
                    }
                }
            }
        }

        &__progress{
            padding: 1rem 3rem;
            display: flex;
            justify-content: space-between;

            & > *{
                flex: 1 0 30%;
                height: 1rem;

                @include media-breakpoint-down(md){
                    height: .7rem;
                }

                &.active{
                    background-color: $accent-blue;
                }

                &:nth-child(2){
                    margin: 0 1rem;
                }
            }
        }
    }

    &-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 4rem;

        width: 92rem;
        @include media-breakpoint-down(md){
            width: 100%;

            @include media-breakpoint-down(sm){
                margin-top: 1rem;
            }
        }

        &__left{
            display: flex;
            align-items: center;

            &__step{
                font-size: 6rem;
                font-weight: bold;
                margin-right: 1rem;
                color: $grey-dark;
                
                @include media-breakpoint-down(md){
                    font-size: 5rem;
                }
            }

            &__desc{
                display: flex;
                flex-direction: column;
                font-size: 1.4rem;
                font-weight: 600;
                color: #353b4099;
                
                @include media-breakpoint-down(md){
                    font-size: 1.2rem;
                }

                &__head{
                    font-weight: 800;
                    font-size: 2.4rem;
                    color: $grey-dark;
                
                    @include media-breakpoint-down(md){
                        font-size: 2rem;
                    }
                }
            }
        }

        &__right{
            & img{
                height: 4.5rem;
                width: 4.2rem;

                &:not(:last-child){
                    margin-right: 1rem;
                }

                @include media-breakpoint-down(md){
                    &:nth-last-child(1), &:nth-last-child(2), &:nth-last-child(3){
                        display: none;
                    }
                    @include media-breakpoint-down(sm){
                        &:nth-last-child(4), &:nth-last-child(5){
                            display: none;
                        }
                        @include media-breakpoint-down(xs){
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &-typy{
        margin-top: 6.7rem;
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(md){
            flex-direction: column;
            align-items: center;

            @include media-breakpoint-down(sm){
                margin-top: 4rem;

                @include media-breakpoint-down(xs){
                    margin-top: 2rem;
                }
            }
        }

        &-info{
            position: absolute;
            left: 105%;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.4rem;
            font-weight: 600;
            width: 20rem;
            opacity: 0;
            pointer-events: none;
            transition: all .3s;

            @include media-breakpoint-down(sm){
                top: 110%;
                left: 50%;
                transform: translateX(-50%);
            }

            &.open{
                opacity: 1;
                pointer-events: all;
            }
        }

        &__main{
            flex: 0 0 100%;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-down(md){
                flex: 0 0 100%;
                align-items: center;
            }

            & .sell-car-end{
                @include media-breakpoint-down(md){
                    //max-width: 80%;
                    @include media-breakpoint-down(xs){
                        max-width: 100%;
                    }
                }
            }            
        }

        &__typy{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            @include media-breakpoint-down(sm){
                max-width: 80%;

                @include media-breakpoint-down(xs){
                    max-width: 100%;
                }
            }

            & input[type="radio"]{
                display: none;

                &:checked + label{
                    background-color: $accent-blue;
                    color: #fff;
                    & img{
                        filter: brightness(0) invert(1);
                    }
                }
            }
        }

        &__typ{
            flex: 0 0 11%;
            height: 14rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid #D6D6D6;
            margin-right: .8rem;
            cursor: pointer;
            transition: all .3s;

            @include media-breakpoint-down(md){
                flex: 0 0 23%;

                @include media-breakpoint-down(xs){
                    flex: 0 0 30%;
    
                    @media(max-width: 450px){
                        flex: 0 0 12rem;
                    }
                }
            }

            &:hover{
                background-color: $accent-blue;
                color: #fff;
                & img{
                    filter: brightness(0) invert(1);
                }
            }

            & img{
                height: 100%;
                max-height: 6rem;
                max-width: 70%;
                margin-bottom: 1.5rem;
                transition: all .3s;
            }

            & div{
                font-size: 1.2rem;
                line-height: 1.9rem;
                text-align: center;
            }
        }

        &__podtypy{
            &__container{
                height: auto;
                max-height: 0;
                overflow: hidden;
                transition: all .3s;

                & > *:not(h3){
                    display: none;
                }

                &.open{
                    max-height: 100rem;

                    & .open{
                        display: flex;
                    }
                }
            }

            & input[type="radio"]{
                display: none;

                &:checked + label{
                    background-color: $accent-blue;
                    color: #fff;
                    & img{
                        filter: brightness(0) invert(1);
                    }
                }
            }

            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            & label{
                padding: 1rem 2rem;
                border: 1px solid #D6D6D6;
                margin-right: 1.4rem;
                margin-bottom: 1.4rem;
                cursor: pointer;
            }
        }

        // &__right{
        //     flex: 0 0 32%;

        //     @include media-breakpoint-down(md){
        //         flex: 0 0 100%;
        //         justify-content: center;
        //         margin-top: 4rem;
        //     }
        // }
    }

    &-podsumowanie{
        @include media-breakpoint-down(xs){
            margin-top: 6rem;
        }
        & img{
            width: 61rem;

            @include media-breakpoint-down(md){
                width: 54rem;
            }

            @include media-breakpoint-down(sm){
                width: 46rem;
            }

            @include media-breakpoint-down(xs){
                max-width: 98%;
            }
        }

        & h3{
            font-weight: 800;
            font-size: 2.4rem;
            color: $grey-dark;
        }

        & p{
            font-size: 1.4rem;
            font-weight: 700;
            color: #353b40cc;        
        }
    }

    &-back{
        & img{
            width: 1.4rem;
        }
        display: flex;
        align-items: center;
        color: $accent-blue;
        font-size: 1.4rem;
        font-weight: 600;
        padding: .5rem 1rem;
        background-color: #fff;
        border-radius: 2rem;
        transition: all .3s;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        &:not(.sprzedaj-back--top){
            @include media-breakpoint-down(xs){
                top: 110%;
                left: 50%;
                transform: translateX(-50%);
            }
        }

        &:hover{
            background-color: $accent-blue;
            color: #fff;
            
            & img{
                filter: brightness(0) invert(1);
                transition: all .3s;
            }
        }

        &--top{
            top: 0;
            transform: translateY(0);

            @include media-breakpoint-down(sm){
                top: -3rem;

                @include media-breakpoint-down(xs){
                    top: -4.5rem;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    &-button{
        padding: 1rem 3rem;
    }




    &-file-drop-area {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 100%;
        padding: 25px;
        border: 1px dashed $accent-blue;
        border-radius: 3px;
        transition: 0.2s;
        &.is-active {
          background-color: $blue-very-light;
        }

        .fake-btn {
            flex-shrink: 0;
            background-color: rgba(255, 255, 255, 0.04);
            border: 1px solid rgba(255, 255, 255, 0.1);
            border-radius: 3px;
            padding: 8px 15px;
            margin-right: 10px;
            font-size: 12px;
            text-transform: uppercase;
          }
          
          .file-msg {
            font-size: small;
            font-weight: 300;
            line-height: 1.4;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          
          .file-input {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            cursor: pointer;
            opacity: 0;
            &:focus {
              outline: none;
            }
          }
      }
      
      
}