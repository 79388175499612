/*=============================================
=            Fonts, Variables, etc.           =
=============================================*/


// @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');

// Myriad Pro
@import url("https://use.typekit.net/ead4xiq.css");

//Open Sans
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');

@import url('https://unpkg.com/ionicons@4.5.10-0/dist/css/ionicons.min.css');

// font awesome
//@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
//@import "node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
//@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
//@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

/*=====  End of Fonts, Variables, etc. ======*/



/*=============================================
=                  Libraries                  =
=============================================*/

@import "node_modules/bootstrap/scss/bootstrap.scss";


//@import "node_modules/slick-carousel/slick/slick.scss";
//@import "node_modules/slick-carousel/slick/slick-theme.scss";
@import "node_modules/lightgallery/src/sass/lightgallery.scss";

/*======================================
=               Utility                =
======================================*/

@import "utility/variables";
@import "utility/utility";



/*======================================
=                 Base                 =
======================================*/

@import "base/mixins";
@import "base/resets";
@import "base/typography";

/*======================================
=               Components             =
======================================*/

@import "components/3-flex-column";
@import "components/bar-with-triangle";
@import "components/bottom-nav";
@import "components/button";
@import "components/country-icon";
@import "components/cta";
@import "components/custom-checkbox";
@import "components/floating-label";
@import "components/info-box";
@import "components/input-clean";
@import "components/google-map";
@import "components/nav-height";
@import "components/offers";
@import "components/podium";
@import "components/search-item";
@import "components/section-belt";
@import "components/select";
@import "components/selected-cars-dropdown";
@import "components/side-nav";
@import "components/stuffed-flex-bar";
@import "components/svg";
@import "components/twins";
@import "components/view-and-sort-bar";
@import "components/white-line";


/*======================================
=                Layout                =
======================================*/
@import "layout/ask-for-vehicle";
@import "layout/brands-row";
@import "layout/cars-list";
@import "layout/footer";
@import "layout/header";
@import "layout/machines-section";
@import "layout/nav";
@import "layout/newsletter";

/*======================================
=                Pages                 =
======================================*/

@import "pages/about-us";
@import "pages/contact";
@import "pages/lista";
@import "pages/oferta";
@import "pages/oferty";
@import "pages/page-404";
@import "pages/sell-car";
@import "pages/sprzedaj";
@import "pages/wynajem";
@import "pages/umowy";

/*======================================
=               Vendors                =
======================================*/

@import "vendors/bootstrap-override";
@import "vendors/lightbox-override";