.cta{
    padding: 1rem 2rem;
    font-size: 1.5rem;
    font-weight: inherit;
    text-transform: uppercase;
    border-radius: 5rem;

    @include media-breakpoint-down(md){
        padding: .7rem 1.5rem;
    }

    &-blue{
        background-color: $accent-blue;
        color: $white;

        &:hover{
            background-color: $white;
            color: $accent-blue;
            box-shadow: inset 0 0 0 1px $accent-blue;

            & .ion-md-add{
                border: 1px solid $accent-blue;
            }
        }

        &:focus{
            box-shadow: inset 0 0 0 1px $accent-blue;
        }
    }

    &-rect{
        border-radius: 3px;
    }

    & span{
        margin-right: .5rem;
    }

    & .ion-md-add{
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $white;
        border-radius: 50%;
        font-size: 1.2rem;
        width: 1.6rem;
        height: 1.6rem;
    }
}