.ask-for-vehicle{
    margin-top: 12.5rem;

    &-container{
        box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.15);

        @include media-breakpoint-down(sm){
            flex-direction: column;
            align-items: center;
        }

        & > img{
            min-width: 57rem;
            width: 57rem;
            height: 50rem;

            @include media-breakpoint-down(lg){
                min-width: 50rem;
                width: 50rem;
                height: auto;
            }

            @include media-breakpoint-down(md){
                min-width: 40rem;
                width: 40rem;
            }
            
            @include media-breakpoint-down(xs){
                min-width: 80%;
                width: 80%;
            }
        }
    }

    &-form{
        padding: 3rem 9.5rem 3rem 0;
        font-weight: normal;

        @include media-breakpoint-down(lg){
            padding: 3rem 5rem 3rem 0;
        }

        @include media-breakpoint-down(md){
            padding: 2rem;
        }

        & .floating-label-field{
            border: 1px solid rgba(0, 0, 0, 0.25);
        }

        & *{
            opacity: 1;
        }

        & > *:not(:last-child){
            margin-bottom: 1.5rem;
        }

        & .floating-label{
            opacity: 1;

            @include media-breakpoint-down(xs){
                top: 55% !important;
            }
        }

        & .floating-label-wrap-textarea{
            //flex: 0 0 150px;
            height: 200px;
            min-height: 200px;
            @include media-breakpoint-down(lg){
                height: 150px;
                min-height: 150px;
            }

            @include media-breakpoint-down(md){
                height: 100px;
                min-height: 100px;
            }

            & textarea{
                height: 100%;
                min-height: 100%;

                &:focus{
                    & + .floating-label{
                        @include media-breakpoint-down(lg){
                            top: 2rem !important;
                        }
                    }
                }
            }

            & .floating-label{
                top: 2.2rem !important;
            }
        }

        & .button-rect{
            margin-top: auto;
            width: 16rem;
            font-size: 1.6rem;
            padding: 0 2rem;
            height: 4.5rem;

            @include media-breakpoint-down(sm){
                width: 100%;
                border-radius: 3px;
                margin-top: -.5rem;
            }
        }

        & .newsletter-desc-agree-container{
            @include media-breakpoint-down(sm){
                margin-top: 1rem;
                order: 5;
            }
        }
    }
}