.bottom-nav{
    & .nav-item{
        margin-right: 4.5rem;
        height: 100%;
        position: relative;
        top: 2px;

        & .nav-link{
            display: flex;
            height: 100%;
            padding: 0.5rem 0;

            &.active{
                background-color: transparent;
                color: $accent-blue;
                font-weight: bold;
                border-bottom: 3px solid $accent-blue;
                border-radius: 0;
            }
        }
    }

    height: 4rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);

    &-content{
        & .tab-pane{
            padding: 0;
        }

        &-list li{
            padding-right: 1rem;
            position: relative;

            &:hover{
                text-decoration: underline;
            }

            &:not(:first-child){
                padding-left: 1rem;
                
                &::before{
                    display: block;
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 1.4rem;
                    width: .1rem;
                    background-color: $accent-blue;
                }
            }
        }
    }
}