.custom-checkbox{
    &-show{
        width: 2rem;
        height: 2rem;
        border: .1rem solid $grey-bright;
        border-radius: .4rem;
        cursor: pointer;

        position: relative;

        &-sell{
            border-radius: 0;
            border: .2rem solid $accent-blue;
        }

        &:hover{
            background-color: #00000011;
        }

        &:after {
            content: "";
            position: absolute;
            opacity: 0;
            left: .6rem;
            top: .1rem;
            width: .7rem;
            height: 1.4rem;
            border: solid $white;
            border-width: 0 2px 2px 0;
            border-radius: 2px;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
    }
    &-hide{
        display: none;

        &:checked ~ .custom-checkbox-show{
            background-color: $accent-blue;
            border: .1rem solid $accent-blue;

            &:after{
                opacity: 1;
            }
        }
    }
}