*, *::before, *::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    overflow-x: hidden;

    //iphone lul
    @media (hover: none) {
        cursor: pointer;
    }
}