.three-flex-column{
    padding: 2rem 3.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: 600;

    & > *{
        flex: 0 0 30%;
        margin-right: 5%;
        margin-bottom: 1.5rem;
        &:nth-child(3n){
            margin-right: 0;
        }

        display: flex;
    
        @include media-breakpoint-down(md){
            flex: 1 0 45%;

            &:nth-child(3n){
                margin-right: 3%;
            }
            
            &:nth-child(2n){
                margin-right: 0;
            }
        }

        @include media-breakpoint-down(sm){
            flex: 1 0 100%;
            margin-right: 0;
            margin-bottom: 1rem;
        }
    }

    &-left, &-right{
        width: 50%;
    }

    &-left{
        margin-right: .5rem;
    }

    & img{
        margin-right: 1rem;
    }

    @include media-breakpoint-down(lg){
        padding: 1.5rem 2rem;
        font-size: 1.4rem;
    }  
    
    &-about{
        @include media-breakpoint-down(md){
            width: 100% !important;
        }
        & > *{
            @include media-breakpoint-down(md){
                flex: 1 0 30%;
                margin-right: 3%;
                margin-bottom: 1.5rem;
                &:nth-child(3n){
                    margin-right: 0;
                }
                &:nth-child(2n){
                    margin-right: 3%;
                }
            }

            @include media-breakpoint-down(sm){
                flex: 1 0 100%;
                margin-right: 0 !important;
            }
        }
    }
}