/*COLORS*/
$accent-blue: #233E91;
$accent-blue-bright: #566fbb;
$blue-very-light: #e6e9f2;

$accent-red: #ED2024;

$accent-warning: #FF9431;

$white: #FFFFFF;
$grey-dark: #353B40;
$grey-bright: #838383;
$black: #000000;

/*FONTS*/
$regular-font: 'Open Sans', sans-serif;
$display-font: 'Avenir Next LT Pro Heavy';
$display-font-cond: 'Avenir Next LT Pro Heavy Condensed';
$display-font-i: 'Avenir Next LT Pro Heavy Italic';
$button-font: 'Avenir Next LT Pro Demi';
$subtitle-font: 'Avenir Next LT Pro Bold';
$lightbox-font: 'Caveat', cursive;