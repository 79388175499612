.input-clean{
    border: 0;
    outline: 0;
    box-shadow: none;
    font-size: inherit;
    font-family: inherit;

    &:focus{
        border: 0;
        outline: 0;
        box-shadow: none;
    }
}