/* The side navigation menu */
.sidenav {
    height: 100%; /* 100% Full-height */
    max-height: 100%;
    width: 25rem;
    position: fixed; /* Stay in place */
    z-index: 2000; /* Stay on top */
    top: 0; /* Stay at the top */
    left: -27.2rem;
    background-color: $white; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    box-shadow: 7px 0px 10px rgba(0, 0, 0, 0.25);

    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;

/*    
    @include media-breakpoint-down(lg){
        width: 35rem;
    }

    @include media-breakpoint-down(md){
        width: 30rem;
        padding-top: 6rem; 
    }*/
  }

.sidenav > *{
    &#nav-menu-item-sell{
        margin-bottom: 2rem;
        
        & .btn{
            width: 21.5rem;
            justify-content: center;
        }
    }

    &.stuffed-flex-bar{
        width: 100%;
        min-height: 12rem;
        overflow-x: scroll;
        align-self: flex-start;
/*
        &::-webkit-scrollbar-track{
            background-color: #E6E6E6;
        }
    
        &::-webkit-scrollbar{
            background-color: #CACACA;
            width: .6rem;
        }
    
        &::-webkit-scrollbar-thumb{
            background-color: #CACACA;
        }
*/    
        & > *{
            flex: 0 0 13rem;
        }
    }   

    &.nav-menu-item-container{
        margin-top: 2.3rem;
        flex-direction: column;
        width: 100%;
        height: auto;
        top: 0;


        & .current{
            background-color: $accent-blue;
            color: $white;

            &:hover a{
                color: $white;
            }
        }

        & > *, & > *.nav-menu-item-expandable{
            width: 100%;
            margin: 0;
            height: 4rem;
            & a{
                justify-content: center;
                padding: 1.5rem 0;
            }

            &:hover{
                box-shadow: none;
            }

            &.open{
                background-color: $accent-blue;
                & > a {
                    color: $white;

                    & span::after{
                        border-color: $white transparent transparent transparent;
                        transform: rotate(-180deg);
                    }
                }
            }
        }

        & .sub-menu li{
            text-align: center;
        }
    }

    &:last-child{
        margin-top: auto;
        margin-bottom: 2rem;
    }
}


.sidenav-open{
    display: none;
    z-index: 100;
    width: 4rem;
    height: 3rem;
    position: absolute;
    top: 50%;
    left: 1rem;
    transition: all .25s;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: translateY(-50%) rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    @include media-breakpoint-down(md){
        width: 3rem;
        height: 2rem;
        display: block;
    }

    & span {
        display: block;
        position: absolute;
        height: .6rem;
        width: 100%;
        background: $accent-blue;
        border-radius: 0;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;

        @include media-breakpoint-down(md){
            height: .4rem;
            border-radius: 0;
        }

        &:nth-child(1) {
            top: 0rem;
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
        }

        &:nth-child(2) {
            top: 1.2rem;
            @include media-breakpoint-down(md){
                top: 1rem;
            }
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
        }

        &:nth-child(3) {
            top: 2.4rem;
            @include media-breakpoint-down(md){
                top: 2rem;
            }
            -webkit-transform-origin: left center;
            -moz-transform-origin: left center;
            -o-transform-origin: left center;
            transform-origin: left center;
        }
      }

    &.open{
        position: absolute;
        right: 5rem;

        @include media-breakpoint-down(md){
            right: 3rem;
        }
    }

    &.open span{
        position: absolute;
        right: 5rem;
        
        &:nth-child(1) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            top: -.3rem;
            @include media-breakpoint-down(md){
                top: -.3rem;
            }
        }

        &:nth-child(2) {
            width: 0%;
            opacity: 0;
        }

        &:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 2.6rem;
            @include media-breakpoint-down(md){
                top: 1.8rem;
            }
        }
    }
  }