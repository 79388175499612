.my-lightbox-container{
    display: flex;

    & a{
        display: none;
        max-width: 16rem;
        margin-right: 3rem;
        height: 10rem;
        position: relative;

        &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4){
            display: block;
        }

        &:nth-child(4){
            margin-right: 0;
        }

        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include media-breakpoint-down(md){
            max-width: 24%;
            margin-right: 1.5rem;
        }

        @include media-breakpoint-down(xs){
            max-width: 33%;
            height: 12rem;

            &:nth-child(1){
                display: none;
            }
        }

        @media(max-width: 420px){
            max-width: 49%;

            &:nth-child(2){
                display: none;
            }
        }
    }

    &-overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        z-index: 10;
        font-size: 2.4rem;
        font-weight: 600;

        &-red{
            background: linear-gradient(0deg, rgba(237, 32, 36, 0.7), rgba(237, 32, 36, 0.7));
        }

        &-blue{
            background: linear-gradient(0deg, rgba(35, 62, 145, 0.7), rgba(35, 62, 145, 0.7));
        }

        &-black {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
        }

        & img{
            width: 3.4rem !important;
            height: 3.4rem !important;
        }
    }

    &-over{
        display: flex;
        position: relative;
    }

    &-links {
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        pointer-events: none;

        // flex-direction: row;
        // justify-content: flex-end;

        & .fake-element{
            width: 100%;
            max-width: 16rem;
            margin-right: 3rem;

            @include media-breakpoint-down(md){
                max-width: 24%;
                margin-right: 1.5rem;
            }
    
            @include media-breakpoint-down(xs){
                max-width: 33%;
                height: 12rem;
    
                &:nth-child(1){
                    display: none;
                }
            }
    
            @media(max-width: 420px){
                max-width: 49%;
    
                &:nth-child(2){
                    display: none;
                }
            }
        }

    
        & a{
            width: 100%;
            max-width: 16rem;
            height: 100%;
            position: relative;
            pointer-events: all;
            margin-right: 3rem;


            &.my-lightbox-link-360 {
                cursor: pointer;
            }

            @include media-breakpoint-down(md){
                max-width: 24%;
            }
    
            @include media-breakpoint-down(xs){
                max-width: 33%;
                height: 12rem;
            }

            @media(max-width: 420px){
                max-width: 49%;
            }
    
            & img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            span {
                font-size: 14px;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.lg-backdrop.in{
    opacity: .85;
}