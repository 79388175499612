.floating-label-field {
    border-width: 0;
    color: $grey-dark;
    &.floating-label-field-search{
        font-weight: 700;
        opacity: 1;
    }
    
    &:focus {
        outline: 0;
        box-shadow: 0;
    }
  
    &::placeholder {
        color: transparent;
    }

    &,
    & + .floating-label {
        line-height: 1;
        font: inherit;
        padding: 1.5em;
        height: 4em;
        position: relative;
        transition: transform .25s, opacity .25s ease-in-out;
        transform-origin: 0 0;
        opacity: .5;
    }

    & + .floating-label {
        cursor: text;
        position: absolute;
        top: 50%;
        transform: translate(0, -58%);
        left: 0;
        user-select: none;
    }

    & + .floating-label.floating-label-search{
        padding: 0;
        padding-left: 1rem;
        height: auto;
        transform: translate(0, -50%);
        opacity: 0.5;
    }

    &:focus,
    &:not(:placeholder-shown) {
        //border-color: rgba(white, .1);

        & + .floating-label {
            transform: translate(0, -68%) scale(.8);
            pointer-events: none;
        }

        & + .floating-label.floating-label-search{
            transform: translate(0, -120%) scale(.8);
            pointer-events: none;
        }
    }
}

.floating-label-wrap{
    position: relative;
    width: 100%;

    & .floating-label-field{
        width: 100%;
    }
}

.floating-label-my{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding-left: 1rem;
    font-size: 1.2rem;
    color: $grey-dark;
    cursor: text;
    transition: all .25s;

    font-weight: 600;
    pointer-events: none;

    & button{
        pointer-events: all;
    }

    &-select{
        top: 53%;
        text-transform: uppercase;
        font-weight: bold;
        padding-left: 4.5rem;
        
        &:hover{
            color: $white;
        }
        &.disabled {
            color: $white;
        }
    }

    &-top{
        top: -1rem;
        left: 0;
        padding-left: 0;

        @include media-breakpoint-down(xs){
            font-size: 1rem;
        }

        @media(max-width: 328px){
            font-size: .9rem;
        }
    }
}

.floating-label-input-my{
    height: 100%;
    width: 100%;
    border: none;
    font-size: 1.2rem;
    font-weight: 600;
    padding-left: 1rem;
    padding-top: .6rem;

    &-select{
        &::placeholder {
            color: transparent !important;
        }

        &:focus,
        &:hover{
            & ~ .floating-label-my {
                color: $white;
            }
        }

        &:not(:placeholder-shown) {
            & ~ .floating-label-my {
                opacity: 0;
                pointer-events: none;

                & button{
                    pointer-events: none;
                }
            }
        }
        
    }

    &-top{
        padding-top: 0;
    }

    &::placeholder {
        color: transparent;
    }

    &:focus,
    &:not(:placeholder-shown) {
        box-shadow: none;
        outline: none;
        & + .floating-label-my {
            top: 25%;
            font-size: .8rem;

            & .font-red{
                margin-right: -.8rem;
            }

            & .font-red, & sup{
                opacity: 0;
            }
            
            & button{
                pointer-events: none;
            }
        }

        & + .floating-label-my-top{
            top: -1rem;
            font-size: 1.2rem;

            & .font-red{
                margin-right: 0rem;
            }

            & .font-red, & sup{
                opacity: 1;
            }
        }
    }
}