.selected-cars-list{
    &-item{
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
        height: 19rem;
        width: 80%;
        justify-content: space-between;

        @include media-breakpoint-down(sm){
            flex-direction: column;
            height: auto;

            &::before{
                position: absolute;
                content: "";
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 5rem;
                background-color: #fff;
            }
        }

        @media(max-width: 440px){
            width: 100%;
        }

        &-container{
            align-items: center;
            width: 100%;
            margin-bottom: 4.4rem;

            @include media-breakpoint-down(sm){
                position: relative;
                justify-content: center;
            }
        }

        &-index, &-close{
            width: 10%;//9.5rem;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-down(sm){
                position: absolute;
                background-color: #fff;
                width: 5rem;
                height: 5rem;
                top: 0;
                z-index: 30;
            }
        }

        &-index{
            font-size: 3.6rem;
            font-weight: bold;
            color: $accent-blue;

            @include media-breakpoint-down(lg){
                font-size: 3rem;
            }

            @include media-breakpoint-down(sm){
                left: 10%;
            }
        }

        &-close{
            & img{
                width: 3rem;

                @include media-breakpoint-down(lg){
                    width: 2.5rem;
                }
            }

            @include media-breakpoint-down(sm){
                right: 10%;
            }
        }

        &-img{
            height: 100%;
            width: 30%;//28.5rem;

            & img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }

            @include media-breakpoint-down(sm){
                width: 100%;
                height: 27rem;
            }
            
            @include media-breakpoint-down(xs){
                height: 25rem;
            }
        }

        &-name, &-price{
            color: $accent-blue;
            font-weight: bold;
            font-size: 2.2rem;
            display: flex;
            align-items: center;

            @include media-breakpoint-down(lg){
                font-size: 2rem;
            }

            @include media-breakpoint-down(md){
                font-size: 1.8rem;
            }

            @include media-breakpoint-down(sm){
                text-align: center;
            }
        }

        &-name{
            width: 40%;//38rem;
            padding: 1rem 2rem;

            &:hover{
                text-decoration: underline;
            }

            @include media-breakpoint-down(sm){
                width: 100%;
                padding: 2rem;
            }
        }

        &-price{
            width: 30%;//20.5rem;
            justify-content: center;
            text-align: center;  
            border-left: 1px solid rgba(0, 0, 0, 0.25);

            @include media-breakpoint-down(sm){
                width: 100%;
                border: none;
                border-top: 1px solid rgba(0, 0, 0, 0.25);
                padding: 2rem;
            }
        }
    }
}