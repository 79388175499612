.twin{
    &-container{
        width: 100%;
        display: flex;
        flex-direction: row;

        @include media-breakpoint-down(sm){
            flex-direction: column;
        }
    }
    width: 50%;
    height: 25rem;
    padding: 2.6rem 3rem;
    box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.25);
    line-height: 2.4rem;

    &:first-child{
        margin-right: 3rem;

        @include media-breakpoint-down(sm){
            margin-right: 0;
            margin-bottom: 3rem;
        }
    }
    
    @include media-breakpoint-down(lg){
        height: auto;
    }

    @include media-breakpoint-down(sm){
        width: 100%;
    }
}