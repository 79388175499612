.machines-section{
    position: relative;

    padding-top: 12rem;
    margin-bottom: 5rem;

    @media(max-width: 1400px){
        padding-top: 8rem;
    }

    @include media-breakpoint-down(sm){
        padding-top: 3rem;
    }
}