.newsletter{

    &-container{
        display: flex;
        box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.25);
        margin-bottom: 5rem;
    
        @include media-breakpoint-down(md){
            flex-direction: column;
        }
    }

    &-img-container{
        width: 50%;

        @include media-breakpoint-down(md){
            width: 100%;
        }

        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-desc{
        &-container{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 50%;
            padding: 7.5rem 6rem 5.8rem 3rem;
            text-align: center;

            @include media-breakpoint-down(md){
                padding: 0 3rem 4rem 3rem;
                width: 100%;
            }

            @include media-breakpoint-down(xs){
                padding: 0 2rem 2rem 2rem;
            }
        }

        &-title{
            font-size: 2.4rem;
            line-height: 131%;
            font-weight: bold;
            margin-bottom: 1rem;
        }

        &-act{
            margin-top: 5rem;
            color: $accent-blue;
            margin-bottom: 3rem;
            font-weight: bold;

            @include media-breakpoint-down(lg){
                margin-top: 4rem;
                margin-bottom: 2rem;
            }

            @include media-breakpoint-down(xs){
                font-size: 1.4rem;
            }
        }

        &-input{
            &-container{
                & button{
                    position: absolute;
                    right: .4rem;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 1.4rem;
                    padding: 0;
                    height: 3.7rem;
                    width: 14.8rem;

                    @include media-breakpoint-down(xs){
                        position: static;
                        transform: none;
                        margin-top: 2rem;
                        width: 100%;
                        border-radius: 3px;
                        height: 4.5rem;
                    }
                }

                & input{
                    border: 1px solid $grey-bright;
                    height: 4.5rem;
                    padding: .8em 1.4em 0;

                    &:focus{
                        border: 1px solid $grey-bright;
                    }

                    @include media-breakpoint-down(xs){
                        font-size: 1.4rem;

                        &:focus,
                        &:not(:placeholder-shown) {
                            //border-color: rgba(white, .1);
                    
                            & + .floating-label {
                                transform: translate(0, -75%) scale(.8);
                                pointer-events: none;
                            }
                        }
                    }
                }

                @include media-breakpoint-down(xs){
                    & .floating-label{
                        top: 25%;
                        transform: translate(0, -64%);
                        font-size: 1.4rem;
                    }
                }
            }
        }

        &-agree{
            &-more{
                color: $black;
                font-weight: bold;
            }

            &-container{
                font-size: 1rem;
                display: flex;
                color: rgba(0, 0, 0, 0.5);
                text-align: left;

                & > div{
                    flex: 1 0;
                }
            }
        }
    }

    &-short{
        width: 98rem;
        & .newsletter-container{
            background-image: url('../img/newsletter-short-image.png');
            background-size: 104%;
            background-position: 100% 30%;
            height: 45rem;
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
        }

        & .newsletter-desc-container{
            width: 39rem;
            padding: 9rem 3rem 0 0;

            & .button-rect{
                width: 15rem;
            }
        }
    }
}