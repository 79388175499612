.svg{
    transition: all .25s;

    &-white {
        filter: brightness(0) invert(1);
    }

    &-conv{
        transition: all .25s;

        &-blue path{
            fill: $accent-blue;
        }
        
        &-white path{
            fill: $white;
        }

        &-black path{
            fill: $black;
        }
    }

    // &-black {
    //     filter: brightness(0) invert(0);
    // }

    &-heart{
        &-box{
            position: relative;
            width: 2.5rem;
            flex: 0 0 2.5rem !important;
            margin-right: 1rem;
            transition: all .25s;

            &:hover{
                transform: translateY(-10%);
            }
        }

        &-empty, &-fill{
            width: 2.5rem;
            height: 2.5rem;
            margin: 0;
        }

        &-fill{
            position: absolute;
            left: 0;
            top: 3px;
            opacity: 0;
            pointer-events: none;
        }

        &-empty{
            &.filled{
                & + .svg-heart-fill{
                    opacity: 1;
                    pointer-events: all;
                }
            }
        }
    }

}

.cars-list-grid{
    & .svg-heart-fill{
        top: 2px;
    }
}

.oferta-section-main-contact-item{
    & .svg-heart-fill{
        top: 6px;
    }
}