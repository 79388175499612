.sell-car{
    & > .bar-with-triangle > .container > .search-item{
        flex: 0 0 45%;
    }

    &-form{

        @include media-breakpoint-down(md){
            flex-direction: column-reverse;
        }

        & .shadow{
            @include media-breakpoint-down(xs){
                padding: 2rem !important;
                padding-top: 2.5rem !important;
            }

            @media(max-width: 340px){
                padding: 1.5rem !important;
                padding-top: 2.5rem !important;
            }
        }
        
        &-left{
            flex: 0 0 65%;

            & .search-item{
                flex: 1 0 40%;
            }

            @include media-breakpoint-down(md){
                flex: 0 0 100%;
            }
        }

        &-right{
            flex: 0 0 32%;

            @include media-breakpoint-down(md){
                flex: 0 0 100%;
                max-height: 1rem;
                overflow: hidden;
                position: static !important;
            }      
            
            & .oferta-section-title-cta{
                &:hover{
                    border: 1px solid $accent-blue;
                }
            }
        }
    }

    &-end{
        @include media-breakpoint-down(sm){
            font-size: 1.4rem;
            flex-direction: column;
            text-align: center;

            & button{
                margin-left: 0 !important;
                margin-top: 1rem;
            }
        }
    }

    &-cena-container{
        @include media-breakpoint-down(sm){
            flex-direction: column;

            & > :first-child{
                margin-bottom: 1.8rem;
            }

            & > :nth-child(2){
                width: 100%;
                justify-content: flex-start;

                & .d-flex:first-child > label:first-of-type{
                    margin-left: 0 !important;
                }
            }
        }
    }
}

.empty{
    border: 2px solid $accent-red !important;
}

.invalid{
    border: 2px solid $accent-warning !important;
}

.valid{
    border: 2px solid $accent-blue !important;
}

.sell-warn{
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 2.2rem;
    cursor: pointer;
    //z-index: 20;

    &-empty{
        background-image: url('../img/icons/warning-red-icon.svg');
        height: 2.2rem;
    }

    &-invalid{
        background-image: url('../img/icons/warning-orange-icon.svg');
        height: 2rem;
    }

    background-size: cover;
}

.search-item-select .sell-warn{
    right: 2.3rem;
}

.dropdown-el-container ~ .sell-warn{
    right: 7rem;
}

.sell-empty-message{
    color: $accent-red;
    font-size: .9rem;
    position: absolute;
    top: 110%;
    left: 2rem;
    font-weight: 600;
    text-transform: lowercase !important;
}

#sell-submit{    
    @include media-breakpoint-down(lg){
        padding: 1rem 5rem;
        @include media-breakpoint-down(sm){
            margin: 1rem 0 0 0 !important;
        }
    }
}