.offers-list-controls{
    margin-top: 6rem;

    @include media-breakpoint-down(sm){
        margin-top: 2rem;
    }

    & .stuffed-flex-bar{
        margin-bottom: 6rem;

        @include media-breakpoint-down(md){
            margin-bottom: 4rem;
        }
    }

    & > :first-child{
        @include hide-on-sm;
    }
}