.nav{
    & .simple-select{
        padding: 0;
        border: 0;
    }
    &-top-belt{
        padding: .3rem 0;
        background-color: $accent-blue;
        color: $white;
        height: 3rem;
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm){
            padding: .3rem 1rem;
        }

        &-left, &-right{
            display: flex;

            & .svg{
                margin-right: .5rem;
            }

            & a{
                margin-right: 5rem;
                @include media-breakpoint-down(md){
                    margin-right: 2rem;
                }
            }
        }

        &-left{
            & > :first-child{
                display: none;

                @include media-breakpoint-down(sm){
                    display: block;
                    
                    & img{
                        width: 1rem;
                        height: 1.7rem;
                    }
                }
            }

            @include media-breakpoint-down(sm){
                & :not(:first-child){
                    display: none;
                }
            }
        }

        &-right {
            & > div:not(:last-child){
                margin-right: 7rem;
                @include media-breakpoint-down(md){
                    margin-right: 2rem;
                }
            }
        }
    }

    &-menu{
        background-color: $white;
        box-shadow: 0px .6rem 1.5rem rgba(0, 0, 0, 0.25);

        & a{
            color: $black;
        }

        &-container{

            @include media-breakpoint-down(md){
                height: 6.2rem;
                justify-content: center !important;
            }

            & .logo-container{
                & img{
                    width: 18.3rem;

                    @include media-breakpoint-down(md){
                        width: 12rem;
                    }

                    @include media-breakpoint-down(sm){
                        width: 13.5rem;
                    }
                }
            }
        }

        &-item{
            height: 100%;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-weight: 600;
            cursor: pointer;
            margin-right: 2.5rem;

            @include media-breakpoint-down(lg){
                margin-right: 1.5rem;
            }

            & > a {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
            }

            &.nav-menu-item-expandable{
                position: relative;
                width: 9.5rem;
                margin-right: 1rem;

                @include media-breakpoint-down(lg){
                    width: 8.5rem;
                }

                @include media-breakpoint-down(md){
                    width: auto;
                    margin-right: 2rem;
                }

                & > .sub-menu{
                    position: absolute;
                    top: 100%;
                    left: 0;
                    min-width: 100%;
                    width: max-content;
                    list-style-type: none;
                    max-height: 0;
                    background-color: $white;
                    transition: all .25s;
                    opacity: 0;
                    z-index: 100;
                    pointer-events: none;

                    & > li{
                        max-height: 0;
                        transition: all .25s;
                        padding: 0rem 1rem;

                        & a{
                            color: $black;
                        }

                        &:hover{
                            background-color: $accent-blue;

                            & a{
                                color: $white;
                            }
                        }
                    }
                }

                & > a{
                    justify-content: flex-start;
                    padding-left: 1rem;

                    & > span{
                        position: relative;

                        &::after{
                            content:"";
                            position: absolute;
                            right: -1.5rem;
                            top: 45%;
                            border: .3em solid $black;
                            border-color: $black transparent transparent transparent;
                            transition: .25s all ease-in-out;
                            transform-origin: 50% .2rem;

                            @include media-breakpoint-down(md){
                                right: -1.2rem;
                            }
                        }
                    }
                }

                &:not(.sub-menu-opener):hover, &.sub-menu-opener.open{
                    & > .sub-menu{
                        max-height: 100rem;
                        opacity: 1;
                        pointer-events: all;

                        & > li{
                            max-height: 10rem;
                            padding: .5rem 1rem;
                        }
                    }

                    & span::after{
                        border-color: $accent-blue transparent transparent transparent;
                        transform: rotate(-180deg);
                    }
                }
            }

            &:hover{
                & a{
                    color: $accent-blue;
                }
                color: $accent-blue;
                box-shadow: inset 0 -.4rem 0 0 $accent-blue;
            }

            &-container{
                height: 6.2rem;
                position: relative;
                top: .2rem;

                @include media-breakpoint-down(md){
                    top: 0;

                    & > a[href="/sprzedaj"]{
                        & .cta{
                            font-size: 1.2rem;
    
                            & span{
                                margin-right: .3rem;
                            }
                        }
                    }

                }
            }
        }
    }
}

@include media-breakpoint-down(md){
    .nav-menu-container > .nav-menu-item-container{
        display: none !important;
    }
}