.selected-cars-dropdown{
    position: fixed;
    right: 0;
    top: 20%;
    height: 6rem;
    min-width: 8rem;
    max-width: 8rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: $accent-blue;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    transition: all .35s;
    z-index: 90;
    font-size: 1.6rem;

    @include media-breakpoint-down(lg){
        height: 5rem;
        min-width: 7rem;
        max-width: 7rem;
    }

    @include media-breakpoint-down(md){
        height: 4.5rem;
        min-width: 6.5rem;
        max-width: 6.5rem;
        font-size: 1.4rem;
    }

    &-icon{
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 1rem;

        @include media-breakpoint-down(lg){
            width: 2rem;
            height: 2rem;
        }
    }

    &-go{
        transition: all .35s;
        position: absolute;
        background-color: $white;
        color: $accent-blue;
        height: 100%;
        width: 100%;
        overflow: hidden;
        font-weight: 700;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        top: 0;
        left: 0;
        z-index: -1;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover{
            color: #233E91bb;
        }
    }

    &-title{
        min-width: 25.5rem;
        transition: all .35s;
        background-color: $accent-blue;
        height: 100%;
        padding: 1rem;
        display: flex;
        align-items: center;
    }

    &-num{        
        position: absolute;
        right: 0;
        padding-right: 1rem;
        display: flex;
        align-items: center;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
        width: 4rem;
        background-color: $accent-blue;
        justify-content: center;

        @include media-breakpoint-down(md){
            width: 2.5rem;
        }
    }

    &.open{
        max-width: 40rem;
        & .selected-cars-dropdown{
            &-go{
                //max-width: 100rem;
                animation: drop .35s .35s;
                animation-fill-mode: forwards;
            }

            &-title{
                max-width: 30rem;
            }
        }
    }
}

@keyframes drop{
    0%{
        top: 0;
    }

    100%{
        top: 100%;
    }
}