.section-belt{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 92rem;
    height: 9rem;
    justify-self: center;
    text-align: center;
    z-index: 100;

    @include media-breakpoint-down(lg){
        width: 85rem;
    }

    @include media-breakpoint-down(md){
        width: 68rem;
    }

    @include media-breakpoint-down(sm){
        position: static;
        transform: none;
        width: 34rem;
        flex-direction: column;
        height: auto;
        padding: 1.7rem 3.8rem;
    }

    @media(max-width: 350px){
        width: 30rem;
        padding: 1.7rem 2.5rem;
    }

    @media(max-width: 310px){
        width: 28rem;
    }

    font-weight: 600;

    background-color: $white;
    box-shadow: 0 0 1.5rem rgba(0,0,0,0.38);


    & .simple-select{
        border: none;
    }

    & > *{
        display: flex;
        justify-content: center;
        align-items: center;

        &:not(:last-child):not(:nth-last-child(2)){
            border-right: 1px solid rgba(0,0,0,0.25);
            @include media-breakpoint-down(sm){
                border: none;
            }
        }

        
        @include media-breakpoint-down(sm){
            flex: 0 0 4.5rem !important;
            margin-bottom: 1.5rem;
        }
    }

    & .search-item-select-arrow{
        @include media-breakpoint-down(md){
            right: 0rem;
        }
    }

    & .search-item{
        margin: 0;
        max-width: 100%;
        height: 100%;
        border: none;
        border-radius: 0 !important;

        @include media-breakpoint-down(sm){
            height: 4.5rem;
        }

        & .search-item-select-icon{
            left: 2.5rem;

            @include media-breakpoint-down(md){
                left: 1.2rem;
            }
        }

        & input{
            font-size: 1.4rem;
            // padding-left: 6rem;
            padding-left: 3rem;
            padding-right: 3rem;

            @include media-breakpoint-down(md){
                font-size: 1.2rem;
                // padding-left: 4.5rem;
                padding-left: 2rem;
                padding-right: 2rem;
            }

            @include media-breakpoint-down(sm){
                border: 1px solid rgba(0,0,0,0.25);
                height: 100%;
                // padding-left: 5rem !important;
                border-radius: 3px;
            }
        }

        & .filterable-select-value-list{
            margin-top: 9rem;

            @include media-breakpoint-down(sm){
                margin-top: 4.5rem;
            }
        }
    }

    &-brand .search-item{
        & .search-item-select-icon{
            left: 2rem;

            @include media-breakpoint-down(md){
                left: 1rem;
            }

            @include media-breakpoint-down(sm){
                left: 2rem;
            }
        }

        & input{
            font-size: 1.4rem;
            // padding-left: 5.5rem;
            padding-left: 3rem;
            padding-right: 3rem;

            @include media-breakpoint-down(md){
                font-size: 1.2rem;
                // padding-left: 3.5rem;
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }
    }

    &-machine-type{
        text-transform: uppercase;
        flex: 1 0 21rem;

        & img{
            margin-right: 1rem;
        }

        @include media-breakpoint-down(lg){
            flex: 1 0 18rem;
        }

        @include media-breakpoint-down(md){
            flex: 1 0 15.5rem;
        }
    }

    &-brand{
        text-transform: uppercase;
        flex: 1 0 15rem;
        
        & img{
            margin-right: 1rem;
        }

        @include media-breakpoint-down(md){
            flex: 1 0 11.4rem;
        }
    }

    &-year.search-item-year{
        flex: 1 0 28.3rem;
    
        @include media-breakpoint-down(sm){
            border: 1px solid rgba(0,0,0,0.25) !important;
        }

        & .floating-label-wrap{
            @include media-breakpoint-down(sm){
                height: 4.3rem;
            }
            & input{
                padding: 1rem .3rem 1rem 1.5rem;
                font-size: 1.4rem;

                &::placeholder{
                    color: rgba(42, 42, 42, 0.55);
                }
    
                @include media-breakpoint-down(md){
                    padding: 1rem .3rem 1rem .7rem;
                    font-size: 1.2rem;
                }
    
                @include media-breakpoint-down(sm){
                    border: none;
                    padding: 1rem .3rem 1rem 1.5rem;
                }
    
                &:focus{
                    border: 1px solid $accent-blue;
                }
            }

            & .filterable-select-value-list{
                margin-top: 9rem;
    
                @include media-breakpoint-down(sm){
                    margin-top: 4.5rem;
                }
            }
        }

        & > :first-child{
            border-right: none;
            position: relative;
    
            @include media-breakpoint-down(sm){
                border-right: 1px solid rgba(0,0,0,0.25) !important;
            }

            &::after{
                content: "";
                display: block;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 3rem;
                border-right: 1px solid rgba(0, 0, 0, 0.25);
    
                @include media-breakpoint-down(sm){
                    display: none;
                }
            }

            @include media-breakpoint-down(sm){
                border-right: none;
            }
        }

        & > *{
            padding: 0;
            height: 100%;

            & input{
                height: 100%;
            }
        }

        @include media-breakpoint-down(lg){
            flex: 1 0 25rem;
        }
        @include media-breakpoint-down(md){
            font-size: 1.2rem;
            flex: 1 0 20.5rem;
        }
    }

    &-advanced{
        flex: 1 0 17.1rem;
        transition: all .25s;

        &:hover{
            background-color: $accent-blue;
            color: $white;
        }

        @include media-breakpoint-down(lg){
            flex: 1 0 14rem;
        }
        
        @include media-breakpoint-down(md){
            font-size: 1.2rem;
            flex: 1 0 11rem;
        }

        @include media-breakpoint-down(sm){
            order: 5;
            color: $accent-blue;
            font-weight: 600;
            margin-bottom: 0;
        }
    }

    &-search{
        flex: 1 0 10.6rem;

        background-color: $accent-blue;
        transition: all .25s;
        border-radius: 0;

        &:hover{
            background-color: $white;
            border: 2px solid $accent-blue;

            & path{
                fill: $accent-blue;
            }
        }

        @include media-breakpoint-down(md){
            flex: 1 0 9rem;
        }

        @include media-breakpoint-down(sm){
            border-radius: 3px;

            & .svg-conv{
                width: 2rem;
            }
            margin-bottom: 0;
        }
    }
}