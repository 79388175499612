.footer{
    position: relative;
    color: $grey-dark;
    font-weight: 400;
    padding-top: 9rem;
    padding-bottom: 6rem;

    @include media-breakpoint-down(xs){
        font-size: 1.2rem;
    }

    & a:hover{
        text-decoration: underline;
    }

    & .d-flex{
        @include media-breakpoint-down(xs){
            flex-wrap: wrap;
        }
    }

    & .footer-facebook {    
        transition: all .25s;
        position: relative;
        left: -1.5rem;    
        padding: .5rem 1.3rem;
        width: max-content;

        &:hover {
            background-color: $accent-blue;

            & .svg-conv {
                & path{
                    fill: $white;
                }
            }
        }

        @include media-breakpoint-down(xs){
            position: static;
        }
    }

    &-column{
        width: 24rem;

        @include media-breakpoint-down(md){
            width: 19rem;
            &:nth-child(2){
                width: 17rem;
            }
        }

        @include media-breakpoint-down(sm){
            width: 14rem;
            &:nth-child(2){
                width: 12rem;
            }
        }

        @include media-breakpoint-down(xs){
            &, &:nth-child(2){
                width: 50%;
                align-items: center;
                text-align: center;
                margin-bottom: 2rem;
            }

            & > *{
                width: 90%;
            }
        }
    }

    &-column > div:not(:last-child):not(.footer-column-head) {        
        margin-bottom: .6rem;
    }
    
    &-column-head{
        line-height: 190%;
        text-transform: uppercase;    
        margin-bottom: 1.2rem;
    }

    &-copyright{
        position: absolute;
        bottom: .5rem;
        left: 50%;
        color: $grey-bright;
        transform: translateX(-50%);

        @include media-breakpoint-down(xs){
            width: 95%;
            font-size: 1.2rem;
            text-align: center;
        }
    }

    & .logo-container{
        @include media-breakpoint-down(md){
            width: 16rem;
            & img{
                width: 16rem;
            }
        }

        @include media-breakpoint-down(sm){
            display: none;
        }
    }

    & > .logo-container{
        display: none;

        @include media-breakpoint-down(sm){
            display: block;
        }
    }

    &__besite{
        margin-top: 20px;
        & img{
            width: 100px;
        }
    }
}

.cc-link {
    margin-left: -2px;
}

.grecaptcha-badge{
	visibility: collapse !important;  
}