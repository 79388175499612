.brands-row{
    position: relative;
    height: 10.7rem;
    margin-top: 5rem;

    @include media-breakpoint-down(md){
        overflow-x: auto;
    }
    @include media-breakpoint-down(sm){
        justify-content: flex-start !important;
    }

    @include media-breakpoint-down(xs){
        margin-top: 2rem;
    }

    &-clear{
        &::after{
            display: none;
        }
    }

    & img{
        width: 16rem;
        height: 100%;
        
        @include media-breakpoint-down(lg){
            width: 13rem;
        }
    }

    & > *{    
        position: relative; 
        &::after{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            background: #ffffff55;
            pointer-events: none;
        }
    }

    & a:not(:last-child){
        margin-right: 1rem;
    }

    & a:first-child{
        @include media-breakpoint-down(md){
            margin-left: 12rem;
        }

        @include media-breakpoint-down(sm){
            margin-left: 0;
        }
    }

    & > :last-child{
        margin-left: -3rem;
    }
}