.button{

    &-rect{
        border-radius: 0;
        padding: 1rem 2rem;
    }

    &-blue{
        background-color: $accent-blue;
        color: $white;
        border: 2px solid $accent-blue;
        font-weight: bold;

        &:hover{
            background-color: $white;
            color: $accent-blue;
        }
    }

    &-wide{
        padding: 1rem 9.5rem;
    }
}