.wynajem{
    &-desc{
        @include media-breakpoint-down(md){
            flex-direction: column-reverse;

            & > :first-child{
                margin-right: 0 !important;
            }
        } 
        & > :nth-child(2){  
            min-width: 37%;
            height: 100%;
            
            & img{
                width: 100%;
                object-fit: cover;
                object-position: 37%;
                height: 50rem;
            }
            @include media-breakpoint-down(lg){
                min-width: 33rem; 
                max-width: 33rem; 
                & img{
                    width: 100%;
                }
            }
            @include media-breakpoint-down(md){
                min-width: 100%;
                height: 40rem !important;
                display: flex;
                justify-content: center;
                margin-bottom: 2rem;
                & img{
                    height: 40rem !important;
                }
            }
            @include media-breakpoint-down(xs){
                margin-top: 5rem;
            }
        }

        &-sprzedaj{
            & > :nth-child(2){  
                height: 36.4rem !important;
                
                & img{
                    height: 36.4rem;
                }
                @include media-breakpoint-down(lg){
                    height: 39rem !important;
                
                    & img{
                        height: 39rem;
                    }
                }
            }
        }
    }
}