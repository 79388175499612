html{
    font-size: 62.5%;
    @include media-breakpoint-down(sm){
        //font-size: 56.25%;
    }
}

body{
    font-size: 1.6rem;
    font-family: $regular-font;
}

a{
    text-decoration: none;
    color: currentColor;

    &:hover{
        text-decoration: none;
        color: currentColor;
    }
}

.nav-top-belt a, .nav-top-belt a:hover{
    text-decoration: none;
    color: $white;
}

.footer a{
    &:hover{
        color: $accent-blue;
        text-decoration: underline;
        font-weight: 600;
    }
}

.font{
    &-ss{
        font-size: 1.1rem;

    }

    &-s{
        font-size: 1.2rem;
        font-weight: 300;
    }

    &-sm{
        font-size: 1.4rem;
        font-weight: 300;

        @include media-breakpoint-down(sm){
            font-size: 1.3rem;    
        }
    }

    &-m{
        font-size: 1.6rem;

        @include media-breakpoint-down(lg){
            font-size: 1.5rem;    
        }

        @include media-breakpoint-down(md){
            font-size: 1.4rem;    
        }
    }

    &-mab{
        font-size: 1.8rem;

        @include media-breakpoint-down(md){
            font-size: 1.6rem;    
        }
    }

    &-mb{
        font-size: 2rem;
    }

    &-ba{
        font-size: 2.3rem;

        @include media-breakpoint-down(md){
            font-size: 2rem;    
        }
    }

    &-b{
        font-size: 2.4rem;
        
        @include media-breakpoint-down(xs){
            font-size: 2rem;    
        }
    }

    &-h{
        font-size: 6.6rem;
    }

    &-darker{
        color: $grey-dark;
        font-weight: 700;
    }

    &-grey{
        color: $grey-bright;
    }

    &-m-grey{
        color: #353B40;
    }

    &-l-grey{
        color: #2a2a2a99;
    }

    &-blue{
        color: $accent-blue;
    }

    &-white{
        color: $white;
    }

    &-red{
        color: $accent-red;
    }

    &-400{
        font-weight: 400;
    }

    &-600{
        font-weight: 600;
    }

    &-800{
        font-weight: 800;
    }

    &-bold{
        font-weight: bold;
    }
}

.placeholder-vis{
    &::placeholder{
        color: rgba(42, 42, 42, 0.25) !important;
    }
}

.heading-1{
    font-size: 4.8rem;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 6rem;
    letter-spacing: .1rem;

    @include media-breakpoint-down(lg){
        font-size: 4.4rem;   
        line-height: 5.5rem; 
    }

    @include media-breakpoint-down(xs){
        font-size: 3.6rem;
        line-height: 125%; 
        max-width: 97%;
    }
}

.title-s-left{
    width: 100%;
    padding: 2rem 1rem;
    text-transform: uppercase;
}

.link-underline:hover{
    text-decoration: underline;
}