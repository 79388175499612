.stuffed-flex-bar{
    display: flex;
    box-shadow: 0 0 1.5rem rgba(0,0,0,0.25);
    width: 110rem;
    font-weight: 400;

    @include media-breakpoint-down(lg){
        &:not(.sidenav-flex-bar){
            width: 96rem;

            & > div{
                & > *{
                    justify-content: flex-end;
                }
            }
        }
    }
    & img{
        max-height: 6.2rem;
        max-width: 100%;
    }

    & span{
        display: flex;
        height: 2rem;
        line-height: 110%;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    @include media-breakpoint-down(md){
        &:not(.sidenav-flex-bar){
            width: 72rem;

            & img{
                max-height: 5rem;
                max-width: 90%;
            }
        }
    }

    @include media-breakpoint-down(sm){
        &:not(.sidenav-flex-bar){
            display: none;
        }
    }

    &-8 > div{
        display: flex;
        flex: 1 0 12.5%;
        cursor: pointer;
        transition: all .25s;

        & > *{
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 2rem .1rem 1.3rem .1rem;
            align-items: center;
            justify-content: flex-end;
            text-align: center;

            @include media-breakpoint-down(md){
                padding: 1.5rem .4rem 1rem .4rem;
            }
        }        

        &:hover, &.active{
            background-color: $accent-blue;
            color: $white;

            & img{
                filter: brightness(0) invert(1);
            }
        }

        & img{
            margin-bottom: 1rem;
        }

        &:not(:last-child){
            border-right: 1px solid rgba(0,0,0,0.25);
        }
    }

    &.sidenav-flex-bar{
        & a{
            padding-top: 0;
        }
    }
}