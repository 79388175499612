.view-and-sort-bar{
    width: 110rem;
    margin-bottom: 5rem;
    padding: 1rem;

    @include media-breakpoint-down(md){
        width: 100%;
        max-width: 96rem;
    }

    @include media-breakpoint-down(xs){
        flex-direction: column;
        align-items: center;
    }

    &-title{
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.6rem;
        padding-left: 2rem;

        @include media-breakpoint-down(xs){
            font-size: 2rem;
            padding: 0;
            margin-bottom: 2rem;
        }
    }

    &-view{
        margin-right: 7rem;

        & .svg-conv{
            width: 2.5rem;
            height: 2.5rem;
            margin-left: 2rem;
            cursor: pointer;
        }

        @include media-breakpoint-down(md){
            margin-right: 5rem;
        }

        @include hide-on-sm;
    }

    &-sort{

        & .simple-select{
            width: 14.5rem;
            margin-left: 1rem;
        }
    }
}